import { OptionType } from '../../constants/types/inputs/multiselect/types';

export enum RevenueActionTypes {
   GET_REVENUES_BY_TIME_START = '@@revenue/GET_REVENUES_BY_TIME_START',
   GET_REVENUES_BY_TIME_SUCCESS = '@@revenue/GET_REVENUES_BY_TIME_SUCCESS',
   GET_REVENUES_BY_TIME_ERROR = '@@revenue/GET_REVENUES_BY_TIME_ERROR',

   GET_REVENUES_BY_COUNTRY_START = '@@revenue/GET_REVENUES_BY_COUNTRY_START',
   GET_REVENUES_BY_COUNTRY_SUCCESS = '@@revenue/GET_REVENUES_BY_COUNTRY_SUCCESS',
   GET_REVENUES_BY_COUNTRY_ERROR = '@@revenue/GET_REVENUES_BY_COUNTRY_ERROR',

   GET_STOREFRONTS_START = '@@revenue/GET_STOREFRONTS_START',
   GET_STOREFRONTS_SUCCESS = '@@revenue/GET_STOREFRONTS_SUCCESS',
   GET_STOREFRONTS_ERROR = '@@revenue/GET_STOREFRONTS_ERROR',
}

type GetRevenuesByTimeStart = {
   type: RevenueActionTypes.GET_REVENUES_BY_TIME_START;
};

type GetRevenuesByTimeSuccess = {
   type: RevenueActionTypes.GET_REVENUES_BY_TIME_SUCCESS;
   payload: RevenueByTimeResponse[];
};

type GetRevenuesByTimeError = {
   type: RevenueActionTypes.GET_REVENUES_BY_TIME_ERROR;
};

type GetRevenuesByCountryStart = {
   type: RevenueActionTypes.GET_REVENUES_BY_COUNTRY_START;
};

type GetRevenuesByCountrySuccess = {
   type: RevenueActionTypes.GET_REVENUES_BY_COUNTRY_SUCCESS;
   payload: RevenueByCountryResponse[];
};

type GetRevenuesByCountryError = {
   type: RevenueActionTypes.GET_REVENUES_BY_COUNTRY_ERROR;
};

type GetStorefrontsStart = {
   type: RevenueActionTypes.GET_STOREFRONTS_START;
};

type GetStorefrontsSuccess = {
   type: RevenueActionTypes.GET_STOREFRONTS_SUCCESS;
   payload: SearchFiltersStorefrontResponse[];
};

type GetStorefrontsError = {
   type: RevenueActionTypes.GET_STOREFRONTS_ERROR;
};

export type RevenueDispatchTypes =
   | GetRevenuesByTimeStart
   | GetRevenuesByTimeSuccess
   | GetRevenuesByTimeError
   | GetRevenuesByCountryStart
   | GetRevenuesByCountrySuccess
   | GetRevenuesByCountryError
   | GetStorefrontsStart
   | GetStorefrontsSuccess
   | GetStorefrontsError;

export interface RevenueState {
   byTime: {
      data: RevenueByTimeResponse[];
      isLoading: boolean;
   };
   byCountry: {
      data: any[];
      isLoading: boolean;
   };
   filters: {
      isLoading: boolean;
      storefronts: OptionType[];
   };
}

export interface RevenueByTimeResponse {
   billedTransactions: number;
   failedTransactions: number;
   totalRevenue: number;
   providerRevenue: number;
   periodStart: string;
   periodEnd: string;
   currency: string;
}

export interface RevenueByCountryResponse {
   billedTransactions: number;
   failedTransactions: number;
   totalRevenue: number;
   providerRevenue: number;
   countryCode: string;
   periodStart: string;
   periodEnd: string;
   currency: string;
}

export interface SearchFiltersStorefrontResponse {
   storefront: string;
}
