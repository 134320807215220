import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import KeycloakService from './auth/KeycloakService';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import store from './store/rootStore';
import history from './utils/history';

import './assets/styles/main.scss';

const renderApp = () =>
   ReactDOM.render(
      <React.StrictMode>
         <Provider store={store}>
            <Router history={history}>
               <App />
            </Router>
         </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
   );

// If user is verified, renders App
KeycloakService.initKeycloak(renderApp);
serviceWorker.unregister();
