import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SERVICES_HEADERS } from '../../../data/tableHeaders';
import { RootStore } from '../../../store/rootStore';
import Button from '../../shared/button/Button';
import Input from '../../shared/input/Input';
import HeaderCell from '../../table/headerCell/HeaderCell';
import Table from '../../table/Table';
import TableData from '../../table/tableData/TableData';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import classes from './Services.module.scss';
import { ReactComponent as IconEye } from '../../../assets/images/svg/icon-eye.svg';
import TableCell from '../../table/tableCell/TableCell';
import cx from 'classnames';
import MultiSelect from 'react-multi-select-component';
import { cancelDropdown } from '../../../utils/cancelDropdown';
import { Routes } from '../../../constants/routes/routes';
import history from '../../../utils/history';
import {
   changeExchangeRate,
   getExchangeRate,
   getServiceData,
   servicesPaginationUpdate,
} from '../../../store/services/servicesActions';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import Pagination from '../../pagination/Pagination';
import { getMerchants, getServices } from '../../../store/transactions/transactionsActions';
import queryString from 'query-string';
import NotificationBadge from '../../shared/notificationBadge/NotificationBadge';
import { SERVICE_STATUS_OPTIONS, SERVICE_TYPE_OPTIONS } from '../../../data/dropdownValues';
import { CSVLink } from 'react-csv';
import { ServiceType } from '../../../store/services/servicesTypes';
import moment from 'moment';
import { ReactComponent as EditIcon } from '../../../assets/images/svg/edit.svg';
import Loader from 'react-loader-spinner';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';
import KeycloakService from '../../../auth/KeycloakService';
import API from '../../../api/CentiliService';
import { toast } from 'react-toastify';

const Services = () => {
   const dispatch = useDispatch();
   const services = useSelector((store: RootStore) => store.services.services.data);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);
   const { data: exchangeRate, isLoading: isLoadingRate } = useSelector(
      (store: RootStore) => store.services.exchangeRate,
   );
   const serviceKeys = useSelector((store: RootStore) => store.transactions.filters.services);
   const merchants = useSelector((store: RootStore) => store.transactions.filters.merchants);
   const { isLoading, pagination } = useSelector(
      (store: RootStore) => store.services.services,
   );
   const [inputState, setInputState] = useState<any>({
      name: '',
      serviceKeys: [],
      merchants: [],
      status: [],
      type: [],
      rate: '',
   });
   const [isEditingRate, setIsEditingRate] = useState(false);
   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

   const [exportData, setExportData] = useState<any[]>([]);
   const [isExportDataLoading, setIsExportDataLoading] = useState(false);

   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });

   const parseInputStateHandler = () => {
      const params = {
         name: inputState.name.trim() || undefined,
         serviceKeys: inputState.serviceKeys.map((service: any) => service.value),
         accountIds: inputState.merchants.map((merchant: any) => merchant.value),
         type: inputState.type[0]?.value,
         active: inputState.status[0]?.value,
      };

      return queryString.stringify(params);
   };

   useEffect(() => {
      dispatch(getServiceData(page.first, page.max, ''));

      dispatch(getServices());
      dispatch(getMerchants());
      actionsUser.includes(UserRoleActions.EXCHANGE_RATE_EDIT_ACTION) &&
         dispatch(getExchangeRate());
   }, []);

   useEffect(() => {
      setInputState({ ...inputState, rate: exchangeRate?.rate || '' });
   }, [exchangeRate]);

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   useEffect(() => {
      const filtersQuery = parseInputStateHandler();
      dispatch(getServiceData(pagination.first, selectedRows[0].value, filtersQuery));
   }, [page, selectedRows]);

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length === 0) {
         setInputState({
            ...inputState,
            [e.target.name]: '',
         });
         return;
      }
      setInputState({
         ...inputState,
         [e.target.name]: e.target.value,
      });
   };

   const searchServices = () => {
      const filtersQuery = parseInputStateHandler();
      dispatch(getServiceData(0, page.max, filtersQuery));
      setExportData([]);
   };

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         searchServices();
      }
   };

   const formatServicesForCSV = (services: ServiceType[]) => {
      return services.map((service) => ({
         Name: service.name,
         'Service Key': service.serviceKey,
         Type: service.type,
         Merchant: service.merchant,
         Status: service.active ? 'Activated' : 'Not activated',
      }));
   };

   const onCancel = () => {
      setIsEditingRate(false);
      setInputState({ ...inputState, rate: exchangeRate?.rate || '' });
   };

   const onConfirm = () => {
      dispatch(
         changeExchangeRate({
            rate: parseFloat(parseFloat(inputState.rate).toFixed(7)),
            startTime: moment.utc().format(),
         }),
      );
      setIsEditingRate(false);
   };

   const getExportData = () => {
      const getData = async () => {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         setIsExportDataLoading(true);
         await API.services
            .getAllServicesForExport(
               0,
               pagination.totalCount < 10000 ? pagination.totalCount : 10000,
               parseInputStateHandler(),
               token || '',
            )
            .then((response) => {
               setExportData(formatServicesForCSV(response.data));
               setIsExportDataLoading(false);
               return csvRef?.current?.link.click();
            })
            .catch(() => {
               setIsExportDataLoading(false);
               toast.error('Error getting services.');
            });
      };

      getData();
   };
   return (
      <>
         <div className={classes.titleContainer}>
            <h2>All Services</h2>
         </div>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               <div className={classes.filtersMenu}>
                  <div className={classes.filtersLeft}>
                     <Input
                        className={classes.filtersInput}
                        value={inputState.name}
                        type={'text'}
                        name={'name'}
                        placeholder={'Enter name'}
                        onChange={textInputHandler}
                        onKeyUp={keyUpHandler}
                     />

                     <NotificationBadge content={inputState.serviceKeys}>
                        <MultiSelect
                           options={serviceKeys}
                           labelledBy="Select"
                           value={inputState.serviceKeys}
                           overrideStrings={{ selectSomeItems: 'Service key' }}
                           onChange={(e: any) => {
                              setInputState({
                                 ...inputState,
                                 serviceKeys: e,
                              });
                           }}
                        />
                     </NotificationBadge>
                     <MultiSelect
                        overrideStrings={{ selectSomeItems: 'Type' }}
                        className={cx(classes.filtersInput, 'singleInput type serviceType')}
                        options={SERVICE_TYPE_OPTIONS}
                        value={inputState.type}
                        labelledBy={'Select'}
                        onChange={(e: any) => {
                           if (e.length === 0) {
                              setInputState({
                                 ...inputState,
                                 type: [],
                              });
                              return;
                           }
                           const newValue = e[e.length - 1] || e;
                           setInputState({
                              ...inputState,
                              type: [newValue],
                           });
                           cancelDropdown('serviceType');
                        }}
                        hasSelectAll={false}
                        disableSearch={true}
                     />
                     <NotificationBadge content={inputState.merchants}>
                        <MultiSelect
                           options={merchants}
                           labelledBy="Select"
                           value={inputState.merchants}
                           overrideStrings={{ selectSomeItems: 'Merchant' }}
                           onChange={(e: any) => {
                              setInputState({
                                 ...inputState,
                                 merchants: e,
                              });
                           }}
                        />
                     </NotificationBadge>
                     <MultiSelect
                        overrideStrings={{ selectSomeItems: 'Status' }}
                        className={cx(classes.filtersInput, 'singleInput type status')}
                        options={SERVICE_STATUS_OPTIONS}
                        value={inputState.status}
                        labelledBy={'Select'}
                        onChange={(e: any) => {
                           if (e.length === 0) {
                              setInputState({
                                 ...inputState,
                                 status: [],
                              });
                              return;
                           }
                           const newValue = e[e.length - 1] || e;
                           setInputState({
                              ...inputState,
                              status: [newValue],
                           });
                           cancelDropdown('status');
                        }}
                        hasSelectAll={false}
                        disableSearch={true}
                     />
                     <Button
                        onClick={() => {
                           setInputState({
                              name: '',
                              serviceKeys: [],
                              merchants: [],
                              status: [],
                              type: [],
                           });
                        }}
                        className={classes.filterButtons}
                        text={'Clear All'}
                        type={'clear'}
                     />
                     <Button
                        onClick={() => {
                           searchServices();
                        }}
                        className={classes.filterButtons}
                        disabled={false}
                        type={'search'}
                        text={'Search'}
                     />
                  </div>
                  <div className={classes.filtersRight}>
                     {actionsUser.includes(UserRoleActions.EXCHANGE_RATE_EDIT_ACTION) &&
                        exchangeRate && (
                           <>
                              {isLoadingRate ? (
                                 <Loader
                                    visible={true}
                                    type="Oval"
                                    color="#25336b"
                                    height={20}
                                    width={20}
                                 />
                              ) : (
                                 <div className={classes.inputContainer}>
                                    {isEditingRate ? (
                                       <>
                                          <div className={classes.inputWrap}>
                                             <label>Exchange rate</label>
                                             <Input
                                                className={classes.filtersInput}
                                                value={inputState.rate}
                                                type="number"
                                                name={'rate'}
                                                placeholder={'Enter exchange rate'}
                                                onChange={textInputHandler}
                                             />
                                             <div className={classes.rateLabel}>
                                                <span>
                                                   1 {exchangeRate?.currency} ={' '}
                                                   <span className={classes.rate}>
                                                      {parseFloat(
                                                         parseFloat(inputState.rate).toFixed(
                                                            7,
                                                         ),
                                                      ) || 0}
                                                   </span>{' '}
                                                   EUR
                                                </span>
                                             </div>
                                          </div>
                                          <div className={classes.buttonWrap}>
                                             <Button
                                                text={'Cancel'}
                                                type={'clear'}
                                                onClick={onCancel}
                                             />
                                             <Button
                                                text={'Confirm'}
                                                type={'primary'}
                                                onClick={onConfirm}
                                                disabled={
                                                   !inputState.rate ||
                                                   parseFloat(inputState.rate) === 0
                                                }
                                             />
                                          </div>
                                       </>
                                    ) : (
                                       <>
                                          <div className={classes.rateLabel}>
                                             Current exchange rate for{' '}
                                             <span>
                                                {exchangeRate?.storefrontCode}: 1{' '}
                                                {exchangeRate?.currency} ={' '}
                                                <span className={classes.rate}>
                                                   {parseFloat(exchangeRate?.rate.toFixed(7))}
                                                </span>{' '}
                                                EUR
                                             </span>
                                          </div>
                                          <EditIcon
                                             className={classes.editIcon}
                                             onClick={() => setIsEditingRate(true)}
                                          />
                                       </>
                                    )}
                                 </div>
                              )}
                           </>
                        )}
                  </div>
               </div>

               {services.length === 0 ? (
                  <div className={classes.messageWrap}>
                     <p>There are no results for this search.</p>
                  </div>
               ) : (
                  <div className={classes.servicesTable}>
                     <Table>
                        <TableHeader>
                           <TableRow>
                              <>
                                 {SERVICES_HEADERS.map((header, i) => (
                                    <HeaderCell key={i}>{header.text}</HeaderCell>
                                 ))}
                                 <HeaderCell> </HeaderCell>
                              </>
                           </TableRow>
                        </TableHeader>
                        <TableData>
                           {services?.map((service: ServiceType, i: number) => (
                              <TableRow key={i}>
                                 <TableCell>{service.name}</TableCell>
                                 <TableCell>{service.serviceKey}</TableCell>
                                 <TableCell>{service.type}</TableCell>
                                 <TableCell>{service.merchant}</TableCell>
                                 <TableCell
                                    className={cx(classes.status, {
                                       [classes.activated]: service?.active,
                                       [classes.notActivated]: !service?.active,
                                    })}
                                 >
                                    <span>
                                       {service?.active ? 'Activated' : 'Not activated'}
                                    </span>
                                 </TableCell>
                                 <TableCell>
                                    <div className={classes.iconsContainer}>
                                       <IconEye
                                          className={classes.eyeIcon}
                                          onClick={() =>
                                             history.push({
                                                pathname: Routes.SERVICE_DETAILS_ROUTE,
                                                state: service,
                                             })
                                          }
                                       />
                                    </div>
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableData>
                     </Table>

                     <Pagination
                        first={pagination.first}
                        max={pagination.max}
                        curMax={selectedRows[0].value}
                        isSearching={isLoading}
                        rowValue={selectedRows}
                        setPage={setPage}
                        setSelectedRows={setSelectedRows}
                        totalCount={pagination.totalCount}
                        totalPages={totalPageState}
                        dispatchAction={servicesPaginationUpdate}
                        isServerSide={true}
                        onRowChange={(rowsPerPage: number) => {
                           dispatch(servicesPaginationUpdate({ first: 0, max: rowsPerPage }));
                        }}
                     />
                     {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                        <>
                           {exportData.length === 0 ? (
                              <a download>
                                 <Button
                                    className="export-csv-btn"
                                    type={'template'}
                                    text={'Export as CSV'}
                                    onClick={getExportData}
                                    showLoader={isExportDataLoading}
                                 />
                              </a>
                           ) : (
                              <CSVLink
                                 filename={'services.csv'}
                                 data={exportData}
                                 ref={csvRef}
                                 onClick={() => {
                                    if (csvRef?.current) {
                                       csvRef.current.link.download = `services-${Date.now()}`;
                                    }
                                 }}
                              >
                                 <Button
                                    className="export-csv-btn"
                                    type={'blue'}
                                    text="Export as CSV"
                                    hideTooltip={true}
                                 />
                              </CSVLink>
                           )}
                        </>
                     )}
                  </div>
               )}
            </>
         )}
      </>
   );
};

export default Services;
