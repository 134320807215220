import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import classes from './Revenue.module.scss';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import Table from '../../table/Table';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import { REVENUES_BY_TIME_HEADER } from '../../../data/tableHeaders';
import HeaderCell from '../../table/headerCell/HeaderCell';
import TableData from '../../table/tableData/TableData';
import TableCell from '../../table/tableCell/TableCell';
import Pagination from '../../pagination/Pagination';
import { RevenueByTimeResponse } from '../../../store/revenue/revenueTypes';
import { CSVLink } from 'react-csv';
import Button from '../../shared/button/Button';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/icons-dark-info.svg';
import { calculateDaysDifference, calculateMonthDifference } from '../../../utils/helpers';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';

interface Props {
   state: any;
}

const RevenueTableByTime = ({ state }: Props) => {
   const formatPercentage = (value: number, total: number) => {
      const percentage = (value / total) * 100;
      return `${percentage.toFixed(2)}%`;
   };

   const [dataForCsv, setDataForCsv] = useState<any>([]);
   const [showExport, setShowExport] = useState(true);

   const formatRevenueRow = (row: RevenueByTimeResponse) => {
      const totalTransactions = row.billedTransactions + row.failedTransactions;

      const formattedRevenue = {
         periodStart: row.periodStart,
         periodEnd: row.periodEnd,
         completed: `${row.billedTransactions} (${formatPercentage(
            row.billedTransactions,
            totalTransactions,
         )})`,
         failed: `${row.failedTransactions} (${formatPercentage(
            row.failedTransactions,
            totalTransactions,
         )})`,
         providerRevenue: row.providerRevenue.toFixed(2),
         totalRevenue: row.totalRevenue.toFixed(2),
         currency: row.currency,
      };

      return formattedRevenue;
   };

   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const { isLoading, data } = useSelector((store: RootStore) => store.revenue.byTime);
   const [selectedRows, setSelectedRows] = useState([{ label: '8', value: 8 }]);
   const [showExportWarning, setShowExportWarning] = useState(false);
   const [exportStartDate, setExportStartDate] = useState<any>(null);
   const [totals, setTotals] = useState<any>({
      periodStart: '',
      periodEnd: '',
      completed: '0 (0%)',
      failed: '0 (0%)',
      providerRevenue: '0',
      totalRevenue: '0',
      currency: '',
   });
   const [page, setPage] = useState({
      first: 0,
      max: selectedRows[0].value,
   });
   const [totalPageState, setTotalPageState] = useState(data.length / selectedRows[0].value);
   useEffect(() => {
      setTotalPageState(Math.ceil(data.length / selectedRows[0].value));
   }, [data, selectedRows]);
   useEffect(() => {
      setPage({
         first: 0,
         max: selectedRows[0].value,
      });
   }, [selectedRows, isLoading, data]);

   const formatRevenueForCSV = (revenueData: RevenueByTimeResponse[]) => {
      const range = calculateMonthDifference(new Date(state.time[0]), new Date(state.time[1]));
      let periodStart = totals.periodStart;

      let formattedData = revenueData.map((row) => {
         return {
            'Period Start': row.periodStart,
            'Period End': row.periodEnd,
            Completed: `${row.billedTransactions} (${formatPercentage(
               row.billedTransactions,
               row.billedTransactions + row.failedTransactions,
            )})`,
            Failed: `${row.failedTransactions} (${formatPercentage(
               row.failedTransactions,
               row.billedTransactions + row.failedTransactions,
            )})`,
            'Provider Share': row.providerRevenue.toFixed(2),
            'Total Revenue': row.totalRevenue.toFixed(2),
            Currency: row.currency,
         };
      });

      if (range > 1) {
         formattedData = formattedData.filter((row) => {
            const startDate = new Date(row['Period Start']);
            if (calculateDaysDifference(startDate, exportStartDate) < 1) {
               return true;
            }
            return false;
         });
         periodStart = formattedData[0]?.['Period Start'];
      }

      formattedData = [
         ...formattedData,
         {
            'Period Start': '',
            'Period End': '',
            Completed: '',
            Failed: '',
            'Provider Share': '',
            'Total Revenue': '',
            Currency: '',
         },
         {
            'Period Start': periodStart,
            'Period End': totals.periodEnd,
            Completed: totals.completed,
            Failed: totals.failed,
            'Provider Share': totals.providerRevenue,
            'Total Revenue': totals.totalRevenue,
            Currency: totals.currency,
         },
      ];

      return formattedData;
   };

   useEffect(() => {
      if (data.length) {
         setTotals(
            formatRevenueRow(
               data.reduce((v1, v2) => {
                  return {
                     periodStart: v1.periodStart,
                     periodEnd: v2.periodEnd,
                     billedTransactions: v1.billedTransactions + v2.billedTransactions,
                     failedTransactions: v1.failedTransactions + v2.failedTransactions,
                     providerRevenue: v1.providerRevenue + v2.providerRevenue,
                     totalRevenue: v1.totalRevenue + v2.totalRevenue,
                     currency: v1.currency,
                  };
               }),
            ),
         );

         if (state.groupBy.length) {
            if (state.groupBy[0].value === 'DAY') {
               setShowExport(true);
            } else {
               setShowExport(false);
            }
         }
      }
   }, [data]);

   useEffect(() => {
      const range = calculateMonthDifference(new Date(state.time[0]), new Date(state.time[1]));

      if (range > 1) {
         setShowExportWarning(true);

         const startDate = new Date(state.time[0]);
         const endDate = new Date(state.time[1]);

         const date = endDate.getDate();
         const month = endDate.getMonth();
         const year = endDate.getFullYear();

         let exportStartDate;
         endDate.setUTCHours(0, 0, 0, 0);

         if (month == 2) {
            const numOfDaysInFebr = new Date(year, month, 0).getDate();
            if (date > numOfDaysInFebr) {
               exportStartDate = new Date(endDate.setDate(date - 31));
               exportStartDate = exportStartDate < startDate ? startDate : exportStartDate;
            } else {
               exportStartDate = new Date(endDate.setMonth(month - 1));
            }
         } else {
            exportStartDate = new Date(endDate.setMonth(month - 1));
         }

         setExportStartDate(exportStartDate);
      } else {
         setShowExportWarning(false);
         setExportStartDate(new Date(totals.periodStart));
      }
   }, [totals]);

   useEffect(() => {
      exportStartDate && setDataForCsv(formatRevenueForCSV(data));
   }, [exportStartDate]);

   return (
      <div className={classes.revenueTable}>
         {isLoading ? (
            <TableLoader />
         ) : data.length === 0 ? (
            <div className={classes.messageWrap}>There are no revenues for this period.</div>
         ) : (
            <>
               <div className={classes.tablesWrapper}>
                  <Table className={classes.revenueDataTable} key={'data'}>
                     <TableHeader>
                        <TableRow>
                           <>
                              {REVENUES_BY_TIME_HEADER.map((header, i) => (
                                 <HeaderCell key={i}>{header.text}</HeaderCell>
                              ))}
                           </>
                        </TableRow>
                     </TableHeader>
                     <TableData>
                        {data
                           ?.slice(page.first, page.max)
                           .map(formatRevenueRow)
                           .map((revenueRow: any, i: number) => (
                              <TableRow key={i}>
                                 <TableCell>{revenueRow.periodStart}</TableCell>
                                 <TableCell>{revenueRow.periodEnd}</TableCell>
                                 <TableCell>{revenueRow.completed}</TableCell>
                                 <TableCell>{revenueRow.failed}</TableCell>
                                 <TableCell>{revenueRow.providerRevenue}</TableCell>
                                 <TableCell>{revenueRow.totalRevenue}</TableCell>
                              </TableRow>
                           ))}
                     </TableData>
                  </Table>
                  <Table className={classes.totalsTable} key={'totals'}>
                     <TableHeader>
                        <TableRow>
                           <>
                              {REVENUES_BY_TIME_HEADER.map((header, i) => (
                                 <HeaderCell key={i}>{`${header.text} Total`}</HeaderCell>
                              ))}
                           </>
                        </TableRow>
                     </TableHeader>
                     <TableData>
                        <TableRow>
                           <TableCell>{totals.periodStart}</TableCell>
                           <TableCell>{totals.periodEnd}</TableCell>
                           <TableCell>{totals.completed}</TableCell>
                           <TableCell>{totals.failed}</TableCell>
                           <TableCell>{totals.providerRevenue}</TableCell>
                           <TableCell>{totals.totalRevenue}</TableCell>
                        </TableRow>
                     </TableData>
                  </Table>

                  <Pagination
                     isServerSide={false}
                     isSearching={isLoading}
                     first={page.first}
                     max={page.max}
                     totalCount={data.length}
                     totalPages={totalPageState}
                     rowValue={selectedRows}
                     setSelectedRows={setSelectedRows}
                     curMax={selectedRows[0].value}
                     setPage={setPage}
                  />
                  {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && showExport && (
                     <div className={classes.exportContainer}>
                        <CSVLink
                           filename={'revenue.csv'}
                           data={dataForCsv}
                           ref={csvRef}
                           onClick={() => {
                              if (csvRef?.current) {
                                 csvRef.current.link.download = `revenue-${Date.now()}`;
                              }
                           }}
                        >
                           <Button type={'blue'} text="Export as CSV" hideTooltip={true} />
                        </CSVLink>
                        {showExportWarning && (
                           <div className={classes.exportWarningContainer}>
                              <InfoIcon />
                              <div className={classes.exportWarningMessage}>
                                 <p>Selected time range is longer than 1 month</p>
                                 <p>
                                    Export will only cover the last 1 month from the selected
                                    range.
                                 </p>
                              </div>
                           </div>
                        )}
                     </div>
                  )}
               </div>
            </>
         )}
      </div>
   );
};

export default RevenueTableByTime;
