import React from 'react';
import classes from './EmptyStatePage.module.scss';

interface EmptyStateProps {
   image?: string;
   headlineText: string;
   message: string;
}

/* Empty state page - Add you options to add data when no data is available */
const EmptyStatePage = ({ image, headlineText, message }: EmptyStateProps) => {
   return (
      <>
         <div className={classes.mainContainer}>
            {image && (
               <div className={classes.imageContainer}>
                  <img src={image}></img>
               </div>
            )}
            <h3 className={classes.headlineText}>{headlineText}</h3>
            <p className={classes.message}>{message}</p>
         </div>
      </>
   );
};

export default EmptyStatePage;
