import Tippy from '@tippyjs/react';
import cx from 'classnames';
import React from 'react';
import Loader from 'react-loader-spinner';
import 'tippy.js/dist/tippy.css';
import { ReactComponent as SearchIcon } from '../../../assets/images/svg/search.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/svg/show-more.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/svg/plus2.svg';
import { ReactComponent as TemplateIcon } from '../../../assets/images/svg/template-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/svg/delete.svg';
import classes from './Button.module.scss';
import { ReactComponent as AddToBatchIcon } from '../../../assets/images/svg/icon-add-to-batch.svg';
interface ButtonProp extends React.HTMLProps<HTMLButtonElement> {
   text: string | Element | JSX.Element;
   type:
      | 'primary'
      | 'secondary'
      | 'clear'
      | 'search'
      | 'filter'
      | 'blue'
      | 'white'
      | 'template'
      | 'delete';
   onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
   onKeyDown?: (e: React.KeyboardEvent) => void;
   hideTooltip?: boolean;
   tooltip?: string;
   arrowUp?: boolean;
   disabled?: boolean;
   showLoader?: boolean;
   className?: string;
   showIcon?: boolean;
}

/* Main Button component. */

const Button = ({
   text,
   type,
   onClick,
   onKeyDown,
   hideTooltip = true,
   tooltip,
   arrowUp,
   disabled,
   className,
   showLoader = false,
   showIcon = true,
   ...rest
}: ButtonProp) => {
   let icon, style;

   const checkButtonName = (text: string) => {
      const lowerText = text.toLowerCase();
      if (lowerText.includes('add') || lowerText.includes('new')) {
         return true;
      }

      return false;
   };

   switch (type) {
      case 'search':
         icon = <SearchIcon className={classes.searchIcon} fill="#ff6600" />;
         style = classes.search;

         break;

      case 'clear':
         style = classes.clear;
         break;

      case 'filter':
         icon = <FilterIcon transform-origin="center" />;
         style = `${classes.filter} ${arrowUp && classes.filterOpen}`;
         break;

      case 'delete':
         icon = showIcon ? <DeleteIcon fill="rgba(232, 63, 63, 1)" /> : null;
         style = classes.delete;
         break;

      case 'primary':
         icon = checkButtonName(text.toString()) ? (
            <PlusIcon className={classes.searchIcon} fill="#ff6600" />
         ) : null;

         style = classes.primary;
         text = showLoader ? (
            <Loader visible={true} type="Oval" color="#fff" height={22} width={22} />
         ) : (
            text
         );
         break;

      case 'secondary':
         style = classes.secondary;
         break;

      case 'white':
         icon = <AddToBatchIcon className={classes.searchIcon} fill="#ff6600" />;
         style = classes.white;
         break;

      case 'blue':
         icon = checkButtonName(text.toString()) ? (
            <PlusIcon className={classes.searchIcon} fill="#0532f0" />
         ) : null;

         style = classes.blue;
         text = showLoader ? (
            <Loader visible={true} type="Oval" color="#fff" height={22} width={22} />
         ) : (
            text
         );
         break;

      case 'template':
         style = classes.template;
         text = showLoader ? (
            <Loader visible={true} type="Oval" color="#fff" height={22} width={22} />
         ) : (
            text
         );
         break;

      default:
         break;
   }

   //Logic
   const onKeyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === '13') {
      }
   };

   return (
      <Tippy content={tooltip} disabled={hideTooltip}>
         <button
            {...rest}
            disabled={disabled}
            onClick={onClick}
            onKeyDown={onKeyDownHandler}
            className={cx(classes.button, style, className)}
         >
            {text}
            {icon}
         </button>
      </Tippy>
   );
};

export default Button;
