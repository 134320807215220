import Tippy from '@tippyjs/react';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CentiliLogo from '../../assets/images/logo-big-light.png';
import { ReactComponent as Arrow } from '../../assets/images/svg/arrow-openMenu.svg';
// import { ReactComponent as Logo } from '../../assets/images/svg/centiliLogo.svg';
import { ReactComponent as Logo } from '../../assets/images/svg/centiliLogoNew.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/images/svg/transactions-icon.svg';
import { ReactComponent as SubscriptionsIcon } from '../../assets/images/svg/subs-icon.svg';
import { ReactComponent as ServicesIcon } from '../../assets/images/svg/icon-services.svg';
import { ReactComponent as BalanceIcon } from '../../assets/images/svg/icon-balance.svg';
import { ReactComponent as RevenueIcon } from '../../assets/images/svg/icon-revenue.svg';
import { ReactComponent as SubscribersIcon } from '../../assets/images/svg/icon-subscribers.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/images/svg/icon-analytics-white.svg';
import { ReactComponent as UserManagementIcon } from '../../assets/images/svg/user-management-icon.svg';

import { Routes } from '../../constants/routes/routes';
import { RootStore } from '../../store/rootStore';
import classes from './Sidebar.module.scss';
import SidebarItem from './sidebarItem/SidebarItem';

/* Main Menu - Sidebar */
const Sidebar = () => {
   const location = useLocation();
   const localizationLocation = location.pathname.includes('localizations');

   //App state
   const actions = useSelector((state: RootStore) => state.auth.user?.actions);
   const edActions = actions?.filter((action) => action.startsWith('view.ed'));
   const actionRoutes = {
      'view.ed.transactions': Routes.TRANSACTIONS_ROUTE,
      'view.ed.subscriptions': Routes.SUBSCRIPTIONS_ROUTE,
      'view.ed.services': Routes.SERVICES_ROUTE,
      'view.ed.balance': Routes.BALANCE_ROUTE,
      'view.ed.revenue': Routes.REVENUE_ROUTE,
      'view.ed.sodexo.analytics': Routes.ANALYTICS,
      'view.ed.subscribers': Routes.SUBSCRIBERS_ROUTE,
      'view.ed.user.management': Routes.USERS_ROUTE,
   };

   const findDefaultRoute = () => {
      const defaultAction = Object.keys(actionRoutes).find((key) => edActions?.includes(key));
      return defaultAction ? actionRoutes[defaultAction] : Routes.FORBIDDEN_ROUTE;
   };

   //Local state
   // const [isSubmenuOpen, setIsSubmenuOpen] = useState({
   //    localization:false,
   //    adm:false
   // });
   const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   //Logic
   const toggleSidebarHandler = () => {
      //Open localization accordion when active
      if (localizationLocation) {
         setIsSidebarOpen(!isSidebarOpen);

         // Skipping sub menu toggle open when sub menu is closed
         if (isSidebarOpen && !isSubmenuOpen) return;

         setIsSubmenuOpen(!isSubmenuOpen);
         return;
      }

      setIsSidebarOpen(!isSidebarOpen);
      setIsSubmenuOpen(false);
   };

   /* const openSidebarAccordionHandler = () => {
      //Close accordion only when sidebar is open
      if (isSidebarOpen) {
         setIsSubmenuOpen(!isSubmenuOpen);
         return;
      }

      //Open sidebar and accordion
      setIsSubmenuOpen(!isSubmenuOpen);
      setIsSidebarOpen(!isSidebarOpen);
   }; */

   const singleMenuItemHandler = () => {
      if (isSidebarOpen) {
         setIsSubmenuOpen(false);
         setIsSidebarOpen(false);
      }
   };

   return (
      <div className={cx(classes.sidebar, { [classes.open]: isSidebarOpen })}>
         <div className={classes.logo}>
            <Link to={findDefaultRoute()}>
               <Logo fill="white" />
            </Link>
            <h3>Partner Panel</h3>
         </div>

         {/* Navigation icon */}
         <div className={classes.toggleSidebar} onClick={toggleSidebarHandler}>
            {actions?.find((action) => action.startsWith('view.ed')) && (
               <Arrow
                  onClick={toggleSidebarHandler}
                  className={cx({ [classes.arrowOpen]: !isSidebarOpen })}
               />
            )}

            <span onClick={toggleSidebarHandler}>Hide Menu</span>
         </div>

         {/* NAV MENU*/}
         <ul>
            {actions?.includes('view.ed.transactions') && (
               <SidebarItem
                  path={Routes.TRANSACTIONS_ROUTE}
                  text={'Transactions'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Transactions'} disabled={isSidebarOpen}>
                     <TransactionsIcon fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.subscriptions') && (
               <SidebarItem
                  path={Routes.SUBSCRIPTIONS_ROUTE}
                  text={'Subscriptions'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Subscriptions'} disabled={isSidebarOpen}>
                     <SubscriptionsIcon fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.services') && (
               <SidebarItem
                  path={Routes.SERVICES_ROUTE}
                  text={'Services'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Services'} disabled={isSidebarOpen}>
                     <ServicesIcon fill="#fff" className={classes.servicesIcon} />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.balance') && (
               <SidebarItem
                  path={Routes.BALANCE_ROUTE}
                  text={'Balance'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Balance'} disabled={isSidebarOpen}>
                     <BalanceIcon fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.revenue') && (
               <SidebarItem
                  path={Routes.REVENUE_ROUTE}
                  text={'Revenue'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Revenue'} disabled={isSidebarOpen}>
                     <RevenueIcon fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.sodexo.analytics') && (
               <SidebarItem
                  path={Routes.ANALYTICS}
                  text={'Analytics'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Analytics'} disabled={isSidebarOpen}>
                     <AnalyticsIcon fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.subscribers') && (
               <SidebarItem
                  path={Routes.SUBSCRIBERS_ROUTE}
                  text={'Subscribers'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Subscribers'} disabled={isSidebarOpen}>
                     <SubscribersIcon fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
            {actions?.includes('view.ed.user.management') && (
               <SidebarItem
                  path={Routes.USERS_ROUTE}
                  text={'Users'}
                  onClick={singleMenuItemHandler}
               >
                  <Tippy content={'Users'} disabled={isSidebarOpen}>
                     <UserManagementIcon className={classes.usersIcon} fill="#fff" />
                  </Tippy>
               </SidebarItem>
            )}
         </ul>
         <div
            className={cx(classes.disableFooterLogo, {
               [classes.sidebarFooterLogo]: isSidebarOpen,
            })}
         >
            <img src={CentiliLogo} alt={'Centili Logo Big'} />
         </div>
      </div>
   );
};

export default Sidebar;
