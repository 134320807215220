import React, { ReactChild, ReactChildren } from 'react';

interface TableRowProp {
   children: ReactChild | ReactChildren | JSX.Element[] | JSX.Element;
   className?: string;
}
const TableRow = ({ children, className }: TableRowProp) => {
   return (
      <tr className={className}>
         <>{children}</>
      </tr>
   );
};

export default React.memo(TableRow);
