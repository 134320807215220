export enum BalanceActionTypes {
   GET_USERS_PER_REALM_START = '@@balance/GET_USERS_PER_REALM_START',
   GET_USERS_PER_REALM_SUCCESS = '@@balance/GET_USERS_PER_REALM_SUCCESS',
   GET_USERS_PER_REALM_ERROR = '@@balance/GET_USERS_PER_REALM_ERROR',

   GET_BALANCE_CHART_DATA_START = '@@balance/GET_BALANCE_CHART_DATA_START',
   GET_BALANCE_CHART_DATA_SUCCESS = '@@balance/GET_BALANCE_CHART_DATA_SUCCESS',
   GET_BALANCE_CHART_DATA_ERROR = '@@balance/GET_BALANCE_CHART_DATA_ERROR',

   GET_ALL_TENANTS_START = '@@balance/GET_ALL_TENANTS_START',
   GET_ALL_TENANTS_SUCCESS = '@@balance/GET_ALL_TENANTS_SUCCESS',
   GET_ALL_TENANTS_ERROR = '@@balance/GET_ALL_TENANTS_ERROR',

   BALANCE_PAGINATION_TOTAL_COUNT = '@@balance/BALANCE_PAGINATION_TOTAL_COUNT',
   BALANCE_PAGINATION_UPDATE = '@@balance/BALANCE_PAGINATION_UPDATE',
}

type GetUsersPerRealmStart = {
   type: BalanceActionTypes.GET_USERS_PER_REALM_START;
};

type GetUsersPerRealmSuccess = {
   type: BalanceActionTypes.GET_USERS_PER_REALM_SUCCESS;
   payload: UsersBalanceResponse[];
};

type GetUsersPerRealmError = {
   type: BalanceActionTypes.GET_USERS_PER_REALM_ERROR;
};

type GetAllTenantsStart = {
   type: BalanceActionTypes.GET_ALL_TENANTS_START;
};

type GetAllTenantsSuccess = {
   type: BalanceActionTypes.GET_ALL_TENANTS_SUCCESS;
   payload: TenantResponse[];
};

type GetAllTenantsError = {
   type: BalanceActionTypes.GET_ALL_TENANTS_ERROR;
};

type GetBalanceChartDataStart = {
   type: BalanceActionTypes.GET_BALANCE_CHART_DATA_START;
};

type GetBalanceChartDataSuccess = {
   type: BalanceActionTypes.GET_BALANCE_CHART_DATA_SUCCESS;
   payload: BalanceChartResponseData;
};

type GetBalanceChartDataError = {
   type: BalanceActionTypes.GET_BALANCE_CHART_DATA_ERROR;
};

type BalancePaginationUpdate = {
   type: BalanceActionTypes.BALANCE_PAGINATION_UPDATE;
   payload: PaginationType;
};

type BalancePaginationTotalCount = {
   type: BalanceActionTypes.BALANCE_PAGINATION_TOTAL_COUNT;
   payload: number;
};

export type BalanceDispatchTypes =
   | GetUsersPerRealmStart
   | GetUsersPerRealmSuccess
   | GetUsersPerRealmError
   | BalancePaginationUpdate
   | BalancePaginationTotalCount
   | GetAllTenantsStart
   | GetAllTenantsSuccess
   | GetAllTenantsError
   | GetBalanceChartDataStart
   | GetBalanceChartDataSuccess
   | GetBalanceChartDataError;

export interface BalanceState {
   users: {
      data: UsersBalanceResponse[];
      chartData: {
         isLoading: boolean;
         usedBalance: DailyBalancePerStorefront[];
         expiredBalance: MonthlyBalancePerStorefront[];
      };
      isLoading: boolean;
      pagination: BalancePagination;
   };
   tenants: {
      data: { label: string; value: string }[];
      isLoading: boolean;
   };
}

export interface UsersBalanceResponse {
   storefrontUsers: UserType[];
   totalCurrentBalance: number;
   totalUsedBalance: number;
   totalExpiredBalance: number;
   realm: string;
}

export interface BalanceChartResponseData {
   usedDailyBalance: DailyBalancePerStorefront[];
   expiredMonthlyBalance: MonthlyBalancePerStorefront[];
}

export interface DailyBalancePerStorefront {
   date: string;
   usedPerDay: string;
   currency: string;
}

export interface MonthlyBalancePerStorefront {
   month: number;
   year: number;
   expiredPerMonth: number;
   monthName: string;
   currency: string;
}

export interface TenantResponse {
   name: string;
   code: string;
   defaultCountry: string;
   domain: string;
}

export interface UserType {
   id: string;
   email: string;
   username: string;
   firstName: string;
   lastName: string;
   companyName: string;
   companyOrgUnit: string;
   realm: string;
   active: boolean;
   verifiedEmail: boolean;
   passwordUpdate: boolean;
   roles: string[];
   currentBalance: number;
   usedBalance: number;
   expiredBalance: number;
   currency: string;
   reference: string;
}

export type PaginationType = {
   first: number;
   max: number;
};

export type BalancePagination = {
   first: number;
   max: number;
   totalCount: number;
};
