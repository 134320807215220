import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import Pagination from '../../pagination/Pagination';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import HeaderCell from '../../table/headerCell/HeaderCell';
import Table from '../../table/Table';
import TableCell from '../../table/tableCell/TableCell';
import TableData from '../../table/tableData/TableData';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import classes from './Balance.module.scss';

const UsedBalanceTable = () => {
   const { isLoading, usedBalance } = useSelector(
      (store: RootStore) => store.balance.users.chartData,
   );

   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(
      usedBalance.length / selectedRows[0].value,
   );
   const [page, setPage] = useState({
      first: 0,
      max: selectedRows[0].value,
   });

   useEffect(() => {
      setTotalPageState(Math.ceil(usedBalance.length / selectedRows[0].value));
   }, [usedBalance, selectedRows]);

   useEffect(() => {
      setPage({
         first: 0,
         max: selectedRows[0].value,
      });
   }, [selectedRows, isLoading, usedBalance]);
   return (
      <>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               <Table className={classes.balanceTable}>
                  <TableHeader>
                     <TableRow>
                        <HeaderCell className={classes.iconCell}>
                           <span>Date</span>
                        </HeaderCell>
                        <HeaderCell className={classes.iconCell}>
                           <span>Used Points</span>
                        </HeaderCell>
                     </TableRow>
                  </TableHeader>
                  <TableData>
                     {usedBalance.slice(page.first, page.max).map((balance, i) => (
                        <TableRow key={i}>
                           <TableCell>{balance.date}</TableCell>
                           <TableCell>{balance.usedPerDay}</TableCell>
                        </TableRow>
                     ))}
                  </TableData>
               </Table>
               <Pagination
                  isServerSide={false}
                  isSearching={isLoading}
                  first={page.first}
                  max={page.max}
                  totalCount={usedBalance.length}
                  totalPages={totalPageState}
                  rowValue={selectedRows}
                  setSelectedRows={setSelectedRows}
                  curMax={selectedRows[0].value}
                  setPage={setPage}
               />
            </>
         )}
      </>
   );
};

export default UsedBalanceTable;
