import classes from './Revenue.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import Table from '../../table/Table';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import HeaderCell from '../../table/headerCell/HeaderCell';
import TableData from '../../table/tableData/TableData';
import { REVENUES_BY_COUNTRY_HEADERS } from '../../../data/tableHeaders';
import TableCell from '../../table/tableCell/TableCell';
import { generateFlagsForDropdowns } from '../../../utils/helpers';
import Pagination from '../../pagination/Pagination';

const RevenueTableByCountry = () => {
   const DECIMAL_PLACES = 4;
   const PERCENT_DECIMAL_PLACES = 2;

   const { data, isLoading } = useSelector((store: RootStore) => store.revenue.byCountry);
   const revenues = data;

   const [selectedRows, setSelectedRows] = useState([{ label: '8', value: 8 }]);
   const [page, setPage] = useState({
      first: 0,
      max: selectedRows[0].value,
   });
   const [totalPageState, setTotalPageState] = useState(
      revenues.length / selectedRows[0].value,
   );

   useEffect(() => {
      setTotalPageState(Math.ceil(revenues.length / selectedRows[0].value));
   }, [revenues, selectedRows]);

   useEffect(() => {
      setPage({
         first: 0,
         max: selectedRows[0].value,
      });
   }, [selectedRows, isLoading, revenues]);

   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);

   const findCountryName = (countryCode: string) => {
      return countries.find((country) => country.value === countryCode)?.label;
   };

   const totals = useMemo(() => {
      if (revenues.length > 0) {
         return revenues.reduce((v1, v2) => ({
            billedTransactions: v1.billedTransactions + v2.billedTransactions,
            failedTransactions: v1.failedTransactions + v2.failedTransactions,
            providerRevenue: v1.providerRevenue + v2.providerRevenue,
            totalRevenue: v1.totalRevenue + v2.totalRevenue,
         }));
      }
      return null;
   }, [revenues]);

   return (
      <>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               {revenues.length === 0 ? (
                  <div className={classes.messageWrap}>
                     There are no results for this search.
                  </div>
               ) : (
                  <>
                     <div className={classes.tablesWrapper}>
                        <Table className={classes.revenueDataTable}>
                           <TableHeader>
                              <TableRow>
                                 {REVENUES_BY_COUNTRY_HEADERS.map((header, i) => (
                                    <HeaderCell key={i}>{header.text}</HeaderCell>
                                 ))}
                              </TableRow>
                           </TableHeader>
                           <TableData>
                              {revenues
                                 ?.slice(page.first, page.max)
                                 .map((revenue: any, i: number) => (
                                    <TableRow key={i}>
                                       <TableCell className={classes.flagCell}>
                                          {generateFlagsForDropdowns(
                                             findCountryName(revenue.countryCode) ||
                                                revenue.countryCode,
                                             revenue.countryCode,
                                          )}
                                       </TableCell>
                                       <TableCell>
                                          {revenue.billedTransactions +
                                             ' (' +
                                             (
                                                (revenue.billedTransactions * 100) /
                                                (revenue.billedTransactions +
                                                   revenue.failedTransactions)
                                             ).toFixed(PERCENT_DECIMAL_PLACES) +
                                             '%)'}
                                       </TableCell>
                                       <TableCell>
                                          {revenue.failedTransactions +
                                             ' (' +
                                             (
                                                (revenue.failedTransactions * 100) /
                                                (revenue.billedTransactions +
                                                   revenue.failedTransactions)
                                             ).toFixed(PERCENT_DECIMAL_PLACES) +
                                             '%)'}
                                       </TableCell>
                                       <TableCell>
                                          {revenue.providerRevenue.toFixed(DECIMAL_PLACES)}
                                       </TableCell>
                                       <TableCell>
                                          {revenue.totalRevenue.toFixed(DECIMAL_PLACES)}
                                       </TableCell>
                                    </TableRow>
                                 ))}
                           </TableData>
                        </Table>
                        <Table className={classes.totalsTable} key={'totals'}>
                           <TableHeader>
                              <TableRow>
                                 <>
                                    <HeaderCell> </HeaderCell>
                                    {REVENUES_BY_COUNTRY_HEADERS.slice(
                                       1,
                                       REVENUES_BY_COUNTRY_HEADERS.length,
                                    ).map((header, i) => (
                                       <HeaderCell
                                          key={i}
                                       >{`${header.text} Total`}</HeaderCell>
                                    ))}
                                 </>
                              </TableRow>
                           </TableHeader>
                           <TableData>
                              <TableRow>
                                 <TableCell> </TableCell>
                                 <TableCell>
                                    {totals.billedTransactions +
                                       ' (' +
                                       (
                                          (totals.billedTransactions * 100) /
                                          (totals.billedTransactions +
                                             totals.failedTransactions)
                                       ).toFixed(PERCENT_DECIMAL_PLACES) +
                                       '%)'}
                                 </TableCell>
                                 <TableCell>
                                    {totals.failedTransactions +
                                       ' (' +
                                       (
                                          (totals.failedTransactions * 100) /
                                          (totals.billedTransactions +
                                             totals.failedTransactions)
                                       ).toFixed(PERCENT_DECIMAL_PLACES) +
                                       '%)'}
                                 </TableCell>
                                 <TableCell>
                                    {totals.providerRevenue.toFixed(DECIMAL_PLACES)}
                                 </TableCell>
                                 <TableCell>
                                    {totals.totalRevenue.toFixed(DECIMAL_PLACES)}
                                 </TableCell>
                              </TableRow>
                           </TableData>
                        </Table>

                        <Pagination
                           isServerSide={false}
                           isSearching={isLoading}
                           first={page.first}
                           max={page.max}
                           totalCount={revenues.length}
                           totalPages={totalPageState}
                           rowValue={selectedRows}
                           setSelectedRows={setSelectedRows}
                           curMax={selectedRows[0].value}
                           setPage={setPage}
                        />
                     </div>
                  </>
               )}
            </>
         )}
      </>
   );
};

export default RevenueTableByCountry;
