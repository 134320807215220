import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RootStore } from '../../store/rootStore';
import Preloader from '../shared/preloader/Preloader';
import LandingRoutes from './appRoutes/landingRoutes/LandingRoutes';

const AppRoutes = () => {
   //If API fetching is too fast, use delayed parameter to prolong page loader, for better UX
   const [delayed, setDelayed] = useState(true);

   //App state
   const isAuthenticatedKeycloak = useSelector(
      (state: RootStore) => state.auth.keycloak.isAuthenticated,
   );

   useEffect(() => {
      const timeout = setTimeout(() => setDelayed(false), 0);
      return () => clearTimeout(timeout);
   }, []);

   return !isAuthenticatedKeycloak || delayed ? (
      <Preloader />
   ) : (
      <Switch>
         <Route component={LandingRoutes} />
      </Switch>
   );
};

export default AppRoutes;
