import { Dispatch } from 'redux';
import API from '../../api/CentiliService';
import { Routes } from '../../constants/routes/routes';
import history from '../../utils/history';
import { AuthActionTypes, AuthDispatchTypes } from './authTypes';

export const authenticateAction =
   (keycloakResource: any) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
      const logged = localStorage.getItem('logged') ?? false;
      dispatch({
         type: AuthActionTypes.AUTH_START,
      });

      try {
         const response = await API.auth.getUserRoles(keycloakResource.token);
         dispatch({
            type: AuthActionTypes.AUTH_SUCCESS,
            payload: {
               keycloak: keycloakResource,
               user: response.data,
            },
         });

         // Redirect to home only after login
         if (!logged && keycloakResource.authenticated) {
            history.push(Routes.TRANSACTIONS_ROUTE);
         }
         localStorage.setItem('logged', 'true');
      } catch (error) {
         dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: error as any,
         });
         history.push(Routes.ERROR_ROUTE);
      }
   };
