import React from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Routes } from '../../constants/routes/routes';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import AppRoutes from '../routes/AppRoutes';
import Sidebar from '../sidebar/Sidebar';
import classes from './App.module.scss';

function App() {
   const location = useLocation();
   return (
      <main className={classes.main}>
         {location.pathname !== Routes.ERROR_ROUTE && (
            <>
               <Header />
               <Sidebar />
            </>
         )}
         <AppRoutes />
         {location.pathname !== Routes.ERROR_ROUTE && <Footer />}

         {/* Toast Notification */}
         <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />
      </main>
   );
}

export default App;
