import React, { ReactChild, ReactChildren } from 'react';
interface HeaderCellProp {
   children: ReactChild | ReactChildren;
   className?: string;
}
const HeaderCell = ({ children, className }: HeaderCellProp) => {
   return <th className={className}>{children}</th>;
};

const HeaderCellMemo = React.memo(HeaderCell);
export default HeaderCellMemo;
