import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import {
   SubscribersActionTypes,
   SubscribersDispatchTypes,
   SubscribersState,
} from './subscribersTypes';

const subscribersState: SubscribersState = {
   subscribers: {
      data: [],
      isLoading: false,
      pagination: {
         first: 0,
         max: 8,
         isNextDisabled: false,
      },
   },
};

const subscribersReducer: Reducer<SubscribersState> = produce(
   (draft: Draft<SubscribersState>, action: SubscribersDispatchTypes) => {
      switch (action.type) {
         case SubscribersActionTypes.GET_SUBSCRIBERS_START:
            draft.subscribers.isLoading = true;
            return;
         case SubscribersActionTypes.GET_SUBSCRIBERS_SUCCESS:
            draft.subscribers.isLoading = false;
            draft.subscribers.data = action.payload;
            return;
         case SubscribersActionTypes.GET_SUBSCRIBERS_ERROR:
            draft.subscribers.isLoading = false;
            return;
         case SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_START:
            draft.subscribers.isLoading = true;
            return;
         case SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_SUCCESS:
            draft.subscribers.isLoading = false;
            return;
         case SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_ERROR:
            draft.subscribers.isLoading = false;
            return;
         case SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_START:
            draft.subscribers.isLoading = true;
            return;
         case SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_SUCCESS:
            draft.subscribers.isLoading = false;
            return;
         case SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_ERROR:
            draft.subscribers.isLoading = false;
            return;
         case SubscribersActionTypes.SUBSCRIBERS_DISABLED_NEXT_PAGE:
            draft.subscribers.pagination.isNextDisabled = action.payload;
            return;
         case SubscribersActionTypes.SUBSCRIBERS_PAGINATION_UPDATE:
            draft.subscribers.pagination.first = action.payload.first;
            draft.subscribers.pagination.max = action.payload.max;
            return;
      }
   },
   subscribersState,
);

export default subscribersReducer;
