import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { BalanceActionTypes, BalanceDispatchTypes, BalanceState } from './BalanceTypes';
const balanceState: BalanceState = {
   users: {
      data: [],
      chartData: {
         isLoading: false,
         usedBalance: [],
         expiredBalance: [],
      },
      pagination: { first: 0, max: 8, totalCount: 0 },
      isLoading: false,
   },
   tenants: {
      data: [],
      isLoading: false,
   },
};

const balanceReducer: Reducer<BalanceState> = produce(
   (draft: Draft<BalanceState>, action: BalanceDispatchTypes) => {
      switch (action.type) {
         case BalanceActionTypes.GET_USERS_PER_REALM_START:
            draft.users.isLoading = true;
            return;
         case BalanceActionTypes.GET_USERS_PER_REALM_SUCCESS:
            draft.users.isLoading = false;
            draft.users.data = action.payload;
            return;
         case BalanceActionTypes.GET_USERS_PER_REALM_ERROR:
            draft.users.isLoading = false;
            return;
         case BalanceActionTypes.GET_BALANCE_CHART_DATA_START:
            draft.users.chartData.isLoading = true;
            return;
         case BalanceActionTypes.GET_BALANCE_CHART_DATA_SUCCESS:
            draft.users.chartData.isLoading = false;
            draft.users.chartData.expiredBalance = action.payload.expiredMonthlyBalance;
            draft.users.chartData.usedBalance = action.payload.usedDailyBalance;
            return;
         case BalanceActionTypes.GET_BALANCE_CHART_DATA_ERROR:
            draft.users.chartData.isLoading = false;
            return;
         case BalanceActionTypes.GET_ALL_TENANTS_START:
            draft.tenants.isLoading = true;
            return;
         case BalanceActionTypes.GET_ALL_TENANTS_SUCCESS:
            draft.tenants.isLoading = false;
            draft.tenants.data = action.payload.map((tenant: any) => ({
               label: tenant.name,
               value: tenant.code,
            }));
            return;
         case BalanceActionTypes.GET_ALL_TENANTS_ERROR:
            draft.tenants.isLoading = false;
            return;
         case BalanceActionTypes.BALANCE_PAGINATION_TOTAL_COUNT:
            draft.users.pagination.totalCount = action.payload;
            return;
         case BalanceActionTypes.BALANCE_PAGINATION_UPDATE:
            draft.users.pagination.first = action.payload.first;
            draft.users.pagination.max = action.payload.max;
            return;
         default:
            return;
      }
   },
   balanceState,
);

export default balanceReducer;
