import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/close-icon.svg';
import classes from './ModalHeader.module.scss';

interface ModalHeaderProp {
   text: string;
   onRequestClose?: () => void;
   className?: string;
}

const ModalHeader = ({ onRequestClose, text, className }: ModalHeaderProp) => {
   return (
      <div className={`${classes.modalHeader} ${className} `}>
         <h2>{text}</h2>
         <CloseIcon className={classes.closeIcon} onClick={onRequestClose} />
      </div>
   );
};

export default ModalHeader;
