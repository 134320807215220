export enum UserRoleActions {
   TRANSACTIONS_VIEW_ACTION = 'view.ed.transactions',
   SUBSCRIPTIONS_VIEW_ACTION = 'view.ed.subscriptions',
   SERVICES_VIEW_ACTION = 'view.ed.services',
   SODEXO_ANALYTICS_VIEW_ACTION = 'view.ed.sodexo.analytics',
   BALANCE_VIEW_ACTION = 'view.ed.balance',
   REVENUE_VIEW_ACTION = 'view.ed.revenue',
   SUBSCRIBERS_VIEW_ACTION = 'view.ed.subscribers',
   RESEND_CONTENT_VIEW_ACTION = 'view.ed.transactions.resend.content',
   UNSUBSCRIBE_VIEW_ACTION = 'view.ed.subscriptions.unsubscribe',
   REFUND_VIEW_ACTION = 'view.ed.transactions.refund',
   USERS_VIEW_ACTION = 'view.ed.user.management',
   USER_DELETE_ACTION = 'delete.ed.storefront.user',
   USER_UPDATE_ACTION = 'edit.ed.storefront.user',
   EXCHANGE_RATE_EDIT_ACTION = 'edit.ed.storefront.exchange.rate',
   EDIT_PACKAGES = 'edit.ed.packages',
   EXPORT_VIEW_ACTION = 'view.ed.export.button',
   PRICE_EUR_VIEW_ACTION = 'view.ed.transactions.price.in.eur',
   NOTIFICATION_VIEW_ACTION = 'view.ed.transactions.notification',
   NET_PRICE_VIEW_ACTION = 'view.ed.net.price',
   MERCHANT_VIEW_ACTION = 'view.ed.merchant.transaction.column',
   CLIENT_ID_VIEW_ACTION = 'view.ed.client.id.transaction.column',
}
