import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUNDLE_TRANSACTIONS_HEADERS, TRANSACTIONS_HEADERS } from '../../../data/tableHeaders';
import { RootStore } from '../../../store/rootStore';
import {
   GetAllTransactions,
   GetBundleParts,
   PaginationUpdate,
   refundTransaction,
   resendContentSMS,
} from '../../../store/transactions/transactionsActions';
import {
   checkValue,
   FormatDate,
   FormatTime,
   generateFlagsForDropdowns,
} from '../../../utils/helpers';
import Pagination from '../../pagination/Pagination';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import HeaderCell from '../../table/headerCell/HeaderCell';
import Table from '../../table/Table';
import TableCell from '../../table/tableCell/TableCell';
import TableData from '../../table/tableData/TableData';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import classes from './Transactions.module.scss';
import queryString from 'query-string';
import cx from 'classnames';
import moment from 'moment';
import Button from '../../shared/button/Button';
import TextboxModal from '../../modal/textboxModal/TextboxModal';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';
import { CSVLink } from 'react-csv';
import { TransactionType } from '../../../store/transactions/transactionsTypes';
import { ReactComponent as ArrowIcon } from '../../../assets/images/svg/icon-arrow.svg';
import Loader from 'react-loader-spinner';
import API from '../../../api/CentiliService';
import KeycloakService from '../../../auth/KeycloakService';
import { toast } from 'react-toastify';

type Props = {
   state: any;
   exportData: any;
   setExportData: (e: any) => void;
};

const TransactionsTable = ({ state, exportData, setExportData }: Props) => {
   const dispatch = useDispatch();
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);
   const showNotificationColumn = actionsUser.includes(
      UserRoleActions.NOTIFICATION_VIEW_ACTION,
   );
   const showPriceInEurColumn = actionsUser.includes(UserRoleActions.PRICE_EUR_VIEW_ACTION);
   const showNetPriceColumn = actionsUser.includes(UserRoleActions.NET_PRICE_VIEW_ACTION);
   const showMerchantColumn = actionsUser.includes(UserRoleActions.MERCHANT_VIEW_ACTION);
   const showClientIdColumn = actionsUser.includes(UserRoleActions.CLIENT_ID_VIEW_ACTION);

   const transactions: TransactionType[] = useSelector(
      (store: RootStore) => store.transactions.transactions.data,
   );
   const { bundleParts, isBundlePartsLoading } = useSelector(
      (store: RootStore) => store.transactions.transactions,
   );
   const { isLoading, pagination } = useSelector(
      (store: RootStore) => store.transactions.transactions,
   );
   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);

   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });
   const [hasPriceInEur, setHasPriceInEur] = useState(false);
   const [refundModalOpen, setRefundModalOpen] = useState(false);
   const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
   const [showBundleTransactions, setShowBundleTransactions] = useState<any>();
   const [loadingBundleTransactionId, setLoadingBundleTransactionId] = useState('');
   const [isExportDataLoading, setIsExportDataLoading] = useState(false);

   const parseInputStateHandler = () => {
      const params = {
         statuses: state.status.map((status: any) => status.value),
         serviceKeys: state.service.map((service: any) => service.value),
         accountIds: state.merchant.map((merchant: any) => merchant.value),
         notification: state.notification.map((notification: any) => notification.value),
         countryCodes: state.country.map((country: any) => country.value),
         transactionTypes: state.type.map((type: any) => type.value),
         transactionIds: state.transactionId.trim() || undefined,
         subscribers: state.subscriber.trim() || undefined,
         clientId: state.clientId.trim() || undefined,
         timeChargedLocalFrom: state.timeChargedLocal
            ? moment(state.timeChargedLocal[0]).utc(true).format()
            : undefined,
         timeChargedLocalTo: state.timeChargedLocal
            ? moment(state.timeChargedLocal[1]).utc(true).format()
            : undefined,
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   useEffect(() => {
      dispatch(
         GetAllTransactions(pagination.first, selectedRows[0].value, parseInputStateHandler()),
      );
   }, [page, selectedRows]);

   useEffect(() => {
      dispatch(GetAllTransactions(page.first, page.max, parseInputStateHandler()));
   }, []);

   useEffect(() => {
      if (!isBundlePartsLoading) {
         setLoadingBundleTransactionId('');
      }
   }, [isBundlePartsLoading]);

   useEffect(() => {
      const bundleTransactions = transactions
         ?.filter((transaction) => transaction.actionCode === 'BUNDLE')
         ?.map((transaction) => transaction.transactionId)
         .reduce((prev, curr) => ({ ...prev, [curr]: false }), {});
      setShowBundleTransactions(bundleTransactions);

      if (pagination.first === 0) {
         setHasPriceInEur(
            transactions?.map((transaction) => transaction.priceInEur).some((value) => value),
         );
      }
   }, [transactions]);

   const errorStatusMsg = (errorStatus: string) => {
      const capitalizedMsg =
         errorStatus.charAt(0).toUpperCase() + errorStatus.toLowerCase().slice(1);
      return <span className={cx(classes.failedMsg)}> {capitalizedMsg} </span>;
   };
   const successStatusMsg = (successStatus: string) => {
      const capitalizedMsg =
         successStatus.charAt(0).toUpperCase() + successStatus.toLowerCase().slice(1);
      return <span className={cx(classes.successMsg)}> {capitalizedMsg} </span>;
   };

   const checkStatus = (status: string, statusMsg: string) => {
      console.log({ status });
      console.log({ statusMsg });
      switch (status) {
         case 'COMPLETED':
            return successStatusMsg(statusMsg);
         case 'FAILED':
            return errorStatusMsg(statusMsg);
         case 'CANCELED':
            return <span>Canceled</span>;
      }
   };

   const findCountryName = (countryCode: string) => {
      return countries.find((country) => country.value === countryCode)?.label;
   };

   const resendContent = (transactionId: string) => {
      dispatch(resendContentSMS(transactionId));
   };

   const refund = (reason: string) => {
      dispatch(refundTransaction(selectedTransaction, reason));
      setRefundModalOpen(false);
   };

   const notificationStatuses = {
      DELIVERED: 'Delivered',
      FAILED: 'Failed',
      REJECTED: 'Rejected',
      EXCLUDED: 'Excluded',
      PENDING: 'Pending',
   };

   const formatTransactionsForCSV = (transactions: TransactionType[]) => {
      return transactions.map((transaction) => ({
         'Transaction ID': transaction.transactionId,
         Service: transaction.serviceName,
         Package: `${
            transaction?.actionCode !== 'BUNDLE' && transaction.packageQuantity
               ? transaction.packageQuantity
               : ''
         } ${transaction.packageUnitName}`,
         Subscriber: transaction.subscriber,
         Country: findCountryName(transaction.countryCode),
         Price: `${transaction.price} ${transaction.currencyCode}`,
         ...(showPriceInEurColumn &&
            hasPriceInEur && { 'Price in EUR': transaction.priceInEur }),
         ...(showNetPriceColumn && {
            'NET price':
               transaction.purchasePrice &&
               `${transaction.purchasePrice} ${transaction.purchaseCurrency}`,
         }),
         ...(showMerchantColumn && { Merchant: transaction.companyName }),
         ...(showClientIdColumn && { 'Client Id': transaction.clientId }),
         Status: transaction.statusCode,
         ...(showNotificationColumn && {
            Notification: transaction.notificationStatusCode,
         }),
         Time: `${FormatDate(transaction.timeChargedLocal)} ${FormatTime(
            transaction.timeChargedLocal,
         )}`,
         Type: transaction.action,
      }));
   };

   const getExportData = () => {
      const getData = async () => {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         setIsExportDataLoading(true);
         await API.transactions
            .getAllTransactionsForExport(
               0,
               pagination.totalCount < 10000 ? pagination.totalCount : 10000,
               parseInputStateHandler(),
               token || '',
            )
            .then((response) => {
               setExportData(formatTransactionsForCSV(response.data));
               setIsExportDataLoading(false);
               return csvRef?.current?.link.click();
            })
            .catch(() => {
               setIsExportDataLoading(false);
               toast.error('Error getting transactions.');
            });
      };

      getData();
   };

   return (
      <>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               {transactions.length === 0 ? (
                  <div className={classes.messageWrap}>
                     There are no results for this search.
                  </div>
               ) : (
                  <>
                     <Table className={classes.transactionsTable}>
                        <TableHeader>
                           <TableRow>
                              <>
                                 <HeaderCell> </HeaderCell>
                                 {TRANSACTIONS_HEADERS.slice(0, 6).map((header, i) => (
                                    <>
                                       {actionsUser.includes(header.action) && (
                                          <HeaderCell key={i}>{header.text}</HeaderCell>
                                       )}
                                    </>
                                 ))}
                                 {hasPriceInEur && (
                                    <>
                                       {TRANSACTIONS_HEADERS.slice(6, 7).map((header, i) => (
                                          <>
                                             {actionsUser.includes(header.action) && (
                                                <HeaderCell key={i}>{header.text}</HeaderCell>
                                             )}
                                          </>
                                       ))}
                                    </>
                                 )}
                                 {TRANSACTIONS_HEADERS.slice(7).map((header, i) => (
                                    <>
                                       {actionsUser.includes(header.action) && (
                                          <HeaderCell key={i}>{header.text}</HeaderCell>
                                       )}
                                    </>
                                 ))}
                              </>
                           </TableRow>
                        </TableHeader>
                        <TableData>
                           {transactions.map((transaction: any, i: number) => (
                              <>
                                 <TableRow key={i}>
                                    <TableCell
                                       className={
                                          showBundleTransactions?.[
                                             transaction?.transactionId
                                          ] && classes.active
                                       }
                                    >
                                       {transaction?.actionCode === 'BUNDLE' ? (
                                          <>
                                             {isBundlePartsLoading &&
                                             loadingBundleTransactionId ===
                                                transaction?.transactionId ? (
                                                <Loader
                                                   visible={true}
                                                   type="Oval"
                                                   color="blue"
                                                   height={16}
                                                   width={16}
                                                />
                                             ) : (
                                                <ArrowIcon
                                                   className={cx(classes.arrowIcon)}
                                                   onClick={() => {
                                                      setShowBundleTransactions({
                                                         ...showBundleTransactions,
                                                         [transaction?.transactionId]:
                                                            !showBundleTransactions?.[
                                                               transaction?.transactionId
                                                            ],
                                                      });
                                                      if (
                                                         !bundleParts?.[
                                                            transaction?.transactionId
                                                         ]
                                                      ) {
                                                         dispatch(
                                                            GetBundleParts(
                                                               transaction?.transactionId,
                                                            ),
                                                         );
                                                         setLoadingBundleTransactionId(
                                                            transaction?.transactionId,
                                                         );
                                                      }
                                                   }}
                                                />
                                             )}
                                          </>
                                       ) : (
                                          <div></div>
                                       )}
                                    </TableCell>
                                    <TableCell>{transaction?.transactionId}</TableCell>
                                    <TableCell>{transaction?.serviceName}</TableCell>
                                    <TableCell>
                                       <>
                                          {transaction?.actionCode !== 'BUNDLE' &&
                                             transaction?.packageQuantity}{' '}
                                          {transaction?.packageUnitName}
                                       </>
                                    </TableCell>
                                    <TableCell>{transaction?.subscriber}</TableCell>
                                    <TableCell className={classes.flagCell}>
                                       <div>
                                          {generateFlagsForDropdowns(
                                             findCountryName(transaction?.countryCode) ||
                                                transaction?.countryCode,
                                             transaction?.countryCode,
                                          )}
                                       </div>
                                    </TableCell>
                                    <TableCell>
                                       <>
                                          {transaction?.price} {transaction?.currencyCode}
                                       </>
                                    </TableCell>
                                    <>
                                       {showPriceInEurColumn && hasPriceInEur && (
                                          <TableCell>
                                             {checkValue(transaction?.priceInEur)}
                                          </TableCell>
                                       )}
                                    </>
                                    <>
                                       {showNetPriceColumn && (
                                          <TableCell>
                                             <>
                                                {transaction?.purchasePrice ? (
                                                   <>
                                                      {transaction?.purchasePrice}{' '}
                                                      {transaction?.purchaseCurrency}
                                                   </>
                                                ) : (
                                                   '-'
                                                )}
                                             </>
                                          </TableCell>
                                       )}
                                    </>
                                    <>
                                       {showMerchantColumn && (
                                          <TableCell>{transaction?.companyName}</TableCell>
                                       )}
                                    </>
                                    <>
                                       {showClientIdColumn && (
                                          <TableCell>
                                             {checkValue(transaction?.clientId)}
                                          </TableCell>
                                       )}
                                    </>
                                    <TableCell
                                       className={cx(classes.status, {
                                          [classes.canceled]:
                                             transaction?.statusCode === 'CANCELED',
                                       })}
                                    >
                                       {checkStatus(
                                          transaction?.statusCode,
                                          transaction?.statusCode === 'COMPLETED'
                                             ? transaction?.statusCode
                                             : transaction?.errorTypeCode ??
                                                  transaction?.statusCode,
                                       )}
                                    </TableCell>
                                    <>
                                       {showNotificationColumn && (
                                          <TableCell
                                             className={cx(classes.status, {
                                                [classes.failed]:
                                                   transaction?.notificationStatusCode ===
                                                      'FAILED' ||
                                                   transaction?.notificationStatusCode ===
                                                      'REJECTED',
                                                [classes.delivered]:
                                                   transaction?.notificationStatusCode ===
                                                   'DELIVERED',
                                                [classes.excluded]:
                                                   transaction?.notificationStatusCode ===
                                                   'EXCLUDED',
                                                [classes.pending]:
                                                   transaction?.notificationStatusCode ===
                                                   'PENDING',
                                             })}
                                          >
                                             <span>
                                                {
                                                   notificationStatuses[
                                                      transaction?.notificationStatusCode
                                                   ]
                                                }
                                             </span>
                                          </TableCell>
                                       )}
                                    </>
                                    <TableCell>
                                       <div>
                                          <div>
                                             {FormatDate(transaction?.timeChargedLocal)}
                                          </div>
                                          <div className={classes.lowOpacityTimeDate}>
                                             {FormatTime(transaction?.timeChargedLocal)}
                                          </div>
                                       </div>
                                    </TableCell>
                                    <TableCell>{transaction?.action}</TableCell>
                                    <TableCell>
                                       <>
                                          {actionsUser.includes(
                                             UserRoleActions.RESEND_CONTENT_VIEW_ACTION,
                                          ) && (
                                             <>
                                                {transaction?.statusCode === 'COMPLETED' && (
                                                   <div>
                                                      <Button
                                                         type="blue"
                                                         text="Resend content"
                                                         onClick={() => {
                                                            resendContent(
                                                               transaction?.transactionId,
                                                            );
                                                         }}
                                                      />
                                                   </div>
                                                )}
                                             </>
                                          )}
                                       </>
                                    </TableCell>
                                    <TableCell>
                                       <>
                                          {actionsUser.includes(
                                             UserRoleActions.REFUND_VIEW_ACTION,
                                          ) && (
                                             <>
                                                {transaction?.actionCode !== 'REFUND' &&
                                                   transaction?.actionCode !==
                                                      'WALLET_CHARGE' &&
                                                   transaction?.price > 0 &&
                                                   transaction?.statusCode === 'COMPLETED' && (
                                                      <div>
                                                         <Button
                                                            type="blue"
                                                            text="Refund"
                                                            onClick={() => {
                                                               setSelectedTransaction(
                                                                  transaction?.transactionId,
                                                               );
                                                               setRefundModalOpen(true);
                                                            }}
                                                         />
                                                      </div>
                                                   )}
                                             </>
                                          )}
                                       </>
                                    </TableCell>
                                 </TableRow>

                                 {showBundleTransactions?.[transaction?.transactionId] &&
                                    loadingBundleTransactionId !==
                                       transaction?.transactionId && (
                                       <>
                                          <TableRow
                                             className={cx(
                                                classes.bundleTransactionTable,
                                                classes.bundleHeader,
                                             )}
                                          >
                                             <>
                                                <TableCell> </TableCell>
                                                {BUNDLE_TRANSACTIONS_HEADERS.map(
                                                   (header, j) => (
                                                      <>
                                                         {actionsUser.includes(
                                                            header.action,
                                                         ) && (
                                                            <TableCell key={j}>
                                                               {header.text}
                                                            </TableCell>
                                                         )}
                                                      </>
                                                   ),
                                                )}
                                                <TableCell colspan={4}> </TableCell>
                                             </>
                                          </TableRow>
                                          {bundleParts?.[transaction?.transactionId]?.map(
                                             (bundlePart: any, j: number) => (
                                                <TableRow
                                                   key={j}
                                                   className={classes.bundleTransactionTable}
                                                >
                                                   <TableCell> </TableCell>
                                                   <TableCell>
                                                      {bundlePart.transactionId}
                                                   </TableCell>
                                                   <TableCell>
                                                      {bundlePart.serviceName}
                                                   </TableCell>
                                                   <TableCell>
                                                      {bundlePart.subscriber}
                                                   </TableCell>
                                                   <TableCell className={classes.flagCell}>
                                                      <div>
                                                         {generateFlagsForDropdowns(
                                                            findCountryName(
                                                               bundlePart.countryCode,
                                                            ) || bundlePart.countryCode,
                                                            bundlePart.countryCode,
                                                         )}
                                                      </div>
                                                   </TableCell>
                                                   <TableCell>
                                                      <>
                                                         {bundlePart.price}{' '}
                                                         {bundlePart.currencyCode}
                                                      </>
                                                   </TableCell>
                                                   <>
                                                      {showMerchantColumn && (
                                                         <TableCell>
                                                            {bundlePart.companyName}
                                                         </TableCell>
                                                      )}
                                                   </>
                                                   <>
                                                      {showClientIdColumn && (
                                                         <TableCell>
                                                            {checkValue(bundlePart.clientId)}
                                                         </TableCell>
                                                      )}
                                                   </>
                                                   <TableCell
                                                      className={cx(classes.status, {
                                                         [classes.canceled]:
                                                            bundlePart.statusCode ===
                                                            'CANCELED',
                                                      })}
                                                   >
                                                      {checkStatus(
                                                         bundlePart.statusCode,
                                                         bundlePart?.statusCode === 'COMPLETED'
                                                            ? bundlePart?.statusCode
                                                            : bundlePart?.errorTypeCode ??
                                                                 bundlePart?.statusCode,
                                                      )}
                                                   </TableCell>
                                                   <TableCell
                                                      className={cx(classes.status, {
                                                         [classes.failed]:
                                                            bundlePart.notificationStatusCode ===
                                                               'FAILED' ||
                                                            bundlePart.notificationStatusCode ===
                                                               'REJECTED',
                                                         [classes.delivered]:
                                                            bundlePart.notificationStatusCode ===
                                                            'DELIVERED',
                                                         [classes.excluded]:
                                                            bundlePart.notificationStatusCode ===
                                                            'EXCLUDED',
                                                         [classes.pending]:
                                                            bundlePart.notificationStatusCode ===
                                                            'PENDING',
                                                      })}
                                                   >
                                                      <span>
                                                         {
                                                            notificationStatuses[
                                                               bundlePart
                                                                  .notificationStatusCode
                                                            ]
                                                         }
                                                      </span>
                                                   </TableCell>
                                                   <TableCell colspan={5}>
                                                      <div>
                                                         <div>
                                                            {FormatDate(
                                                               bundlePart.timeChargedLocal,
                                                            )}
                                                         </div>
                                                         <div
                                                            className={
                                                               classes.lowOpacityTimeDate
                                                            }
                                                         >
                                                            {FormatTime(
                                                               bundlePart.timeChargedLocal,
                                                            )}
                                                         </div>
                                                      </div>
                                                   </TableCell>
                                                </TableRow>
                                             ),
                                          )}
                                       </>
                                    )}
                              </>
                           ))}
                        </TableData>
                     </Table>

                     <Pagination
                        first={pagination.first}
                        max={pagination.max}
                        curMax={selectedRows[0].value}
                        isSearching={isLoading}
                        rowValue={selectedRows}
                        setPage={setPage}
                        setSelectedRows={setSelectedRows}
                        totalCount={pagination.totalCount}
                        totalPages={totalPageState}
                        dispatchAction={PaginationUpdate}
                        isServerSide={true}
                        onRowChange={(rowsPerPage: number) => {
                           dispatch(PaginationUpdate({ first: 0, max: rowsPerPage }));
                        }}
                     />
                     {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                        <>
                           {exportData.length === 0 ? (
                              <a download>
                                 <Button
                                    className="export-csv-btn"
                                    type={'template'}
                                    text={'Export as CSV'}
                                    onClick={getExportData}
                                    showLoader={isExportDataLoading}
                                 />
                              </a>
                           ) : (
                              <CSVLink
                                 filename={'transactions.csv'}
                                 ref={csvRef}
                                 data={exportData}
                                 onClick={() => {
                                    if (csvRef?.current) {
                                       csvRef.current.link.download = `transactions-${Date.now()}`;
                                    }
                                 }}
                              >
                                 <Button
                                    className="export-csv-btn"
                                    type={'blue'}
                                    text="Export as CSV"
                                    hideTooltip={true}
                                 />
                              </CSVLink>
                           )}
                        </>
                     )}
                     <TextboxModal
                        headerText="Refund"
                        onCancel={() => {
                           setRefundModalOpen(false);
                           setSelectedTransaction(null);
                        }}
                        onConfirm={refund}
                        isOpen={refundModalOpen}
                        onRequestClose={() => {
                           setRefundModalOpen(false);
                           setSelectedTransaction(null);
                        }}
                        required={true}
                        text="Enter refund reason"
                     />
                  </>
               )}
            </>
         )}
      </>
   );
};

export default TransactionsTable;
