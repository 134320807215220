import moment from 'moment';
import React from 'react';
import Checked from '../assets/images/icons/check-crossed.svg';
import Crossed from '../assets/images/icons/check-true.svg';

const noValue = '-';

export const checkState = (value: any) => {
   if (value === null) {
      return '-';
   }
   if (value) {
      return <img src={Checked} alt={'Checked'} />;
   } else {
      return <img src={Crossed} alt={'Crossed'} />;
   }
};

export const checkValue = (value: any) => {
   if (value) {
      if (Array.isArray(value)) {
         return value.join(', ');
      } else {
         return value;
      }
   } else {
      return noValue;
   }
};

export const checkString = (value: string) => {
   return value.trim() === '' ? null : value;
};

export const generateFlagsForDropdowns = (countryName: string, code: string) => {
   return (
      <>
         <span style={{ marginRight: '20px' }}>
            <img src={`https://stage.centili.com/res/img/flags/24/${code}.png`} />
         </span>
         {countryName}
      </>
   );
};

export const executeScroll = (sectionRef: any) => {
   return sectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const FormatDate = (date: any) => {
   const fullDate = new Date(date?.split('T')[0]);
   return fullDate.toDateString().slice(4);
};

export const FormatTime = (time: string) => {
   const formatTime = new Date(time).toLocaleTimeString();
   const lastIndex = formatTime.lastIndexOf(':');
   const fullTime = formatTime.slice(0, lastIndex) + formatTime.slice(lastIndex + 3);

   return fullTime;
};

export const FormatPrice = (price: number, currency: string) => {
   const formattedPrice = price.toLocaleString('sr-RS', {
      style: 'currency',
      currency: currency,
   });
   return formattedPrice;
};

export const FormatPercentage = (percentage: number) => {
   const formattedPercentage = percentage.toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2,
   });
   return formattedPercentage;
};

export const calculateDateNDaysAgo = (daysAgo: number) => {
   const date = new Date(Date.now());
   const days = date.getDate() - daysAgo;
   date.setDate(days);
   return date.toISOString();
};

export const formatDateString = (date: Date) => {
   return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const calculateDaysDifference = (startDate: Date, endDate: Date) => {
   return moment(endDate).diff(startDate, 'days', false);
};

export const calculateMonthDifference = (startDate: Date, endDate: Date) => {
   return moment(endDate).diff(startDate, 'months', true);
};

export const capitalizeFirstLetter = (word: string) => {
   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
