import React from 'react';
import ForbiddenImg from '../assets/images/forbidden-img.png';
import ButtonLink from '../components/shared/button/buttonLink/ButtonLink';
import { Routes } from '../constants/routes/routes';

const ForbiddenPage = () => {
   return (
      <div className="error-state-container">
         <div className="error-state-image">
            <img alt={'Error image'} src={ForbiddenImg} />
         </div>
         <div className="error-state-content">
            <h2>Forbidden</h2>
            <h4>Access to this resource on the server is denied!</h4>
            <ButtonLink text={'Back to home'} to={Routes.TRANSACTIONS_ROUTE} />
         </div>
      </div>
   );
};

export default ForbiddenPage;
