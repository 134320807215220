import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import {
   GetAllTransactions,
   getCountries,
   getMerchants,
   getOperators,
   getServices,
   PaginationUpdate,
} from '../../../store/transactions/transactionsActions';
import Button from '../../shared/button/Button';
import Input from '../../shared/input/Input';
import classes from './Transactions.module.scss';
import queryString from 'query-string';
import NotificationBadge from '../../shared/notificationBadge/NotificationBadge';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DateRangePicker from '../../shared/dateRangePicker/DateRangePickerComponent';
import {
   TRANSACTIONS_STATUS_OPTIONS,
   TRANSACTIONS_TYPE_OPTIONS,
} from '../../../data/dropdownValues';
import { calculateDateNDaysAgo } from '../../../utils/helpers';

type Props = {
   state: any;
   setState: (e: any) => void;
   setExportData: (e: any) => void;
};

const TransactionsFilters = ({ state, setState, setExportData }: Props) => {
   const dispatch = useDispatch();
   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);
   const services = useSelector((store: RootStore) => store.transactions.filters.services);
   const merchants = useSelector((store: RootStore) => store.transactions.filters.merchants);
   const pagination = useSelector(
      (store: RootStore) => store.transactions.transactions.pagination,
   );
   const [showFilters, setShowFilters] = useState(false);
   const [arrowUp, setArrowUp] = useState(false);

   const showFiltersHandler = () => {
      setShowFilters(!showFilters);
      setArrowUp(!arrowUp);
   };

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setState({
         ...state,
         [name]: value,
      });
   };

   useEffect(() => {
      dispatch(getCountries());
      dispatch(getOperators());
      dispatch(getMerchants());
      dispatch(getServices());
   }, []);

   const clearState = () => {
      setState({
         transactionId: '',
         subscriber: '',
         country: [],
         status: [],
         timeChargedLocal: [
            new Date(calculateDateNDaysAgo(7)),
            new Date(calculateDateNDaysAgo(0)),
         ],
         type: [],
         service: [],
         merchant: [],
         clientId: '',
      });
   };

   const parseInputStateHandler = () => {
      const params = {
         statuses: state.status.map((status: any) => status.value),
         serviceKeys: state.service.map((service: any) => service.value),
         accountIds: state.merchant.map((merchant: any) => merchant.value),
         /* operatorCodes: state.operator.map((operator: any) => operator.value), */
         countryCodes: state.country.map((country: any) => country.value),
         transactionTypes: state.type.map((type: any) => type.value),
         transactionIds: state.transactionId.trim() || undefined,
         subscribers: state.subscriber.trim() || undefined,
         clientId: state.clientId.trim() || undefined,
         timeChargedLocalFrom: state.timeChargedLocal
            ? moment(state.timeChargedLocal[0]).utc(true).format()
            : undefined,
         timeChargedLocalTo: state.timeChargedLocal
            ? moment(state.timeChargedLocal[1]).utc(true).format()
            : undefined,
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   const searchTransactions = () => {
      dispatch(GetAllTransactions(0, pagination.max, parseInputStateHandler()));
      dispatch(PaginationUpdate({ first: 0, max: 8 }));
      setExportData([]);
   };

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         searchTransactions();
      }
   };

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               <Input
                  type="text"
                  name="transactionId"
                  placeholder={'Transaction ID'}
                  value={state.transactionId}
                  onChange={textInputHandler}
                  className={classes.filterInputs}
                  onKeyUp={keyUpHandler}
               />
               <Input
                  type="text"
                  name="subscriber"
                  placeholder={'Subsciber'}
                  value={state.subscriber}
                  onChange={textInputHandler}
                  className={classes.filterInputs}
                  onKeyUp={keyUpHandler}
               />
               <Input
                  type="text"
                  name="clientId"
                  placeholder={'Client id'}
                  value={state.clientId}
                  onChange={textInputHandler}
                  className={classes.filterInputs}
                  onKeyUp={keyUpHandler}
               />
               <NotificationBadge content={state.country}>
                  <MultiSelect
                     options={countries}
                     labelledBy="Select"
                     value={state.country}
                     overrideStrings={{ selectSomeItems: 'Country' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           country: e,
                        });
                     }}
                  />
               </NotificationBadge>
               {/* <NotificationBadge content={state.operator}>
                  <MultiSelect
                     options={operators}
                     labelledBy="Select"
                     value={state.operator}
                     overrideStrings={{ selectSomeItems: 'Operator' }}
                     hasSelectAll={false}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           operator: e,
                        });
                     }}
                  />
               </NotificationBadge> */}
               <NotificationBadge content={state.status}>
                  <MultiSelect
                     options={TRANSACTIONS_STATUS_OPTIONS}
                     labelledBy="Select"
                     value={state.status}
                     overrideStrings={{ selectSomeItems: 'Status' }}
                     hasSelectAll={false}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           status: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <div className={classes.filtersDate}>
                  <DateRangePicker
                     name="timeChargedLocal"
                     value={state.timeChargedLocal}
                     setState={setState}
                  />
               </div>
               <NotificationBadge content={state.type}>
                  <MultiSelect
                     options={TRANSACTIONS_TYPE_OPTIONS}
                     labelledBy="Select"
                     value={state.type}
                     overrideStrings={{ selectSomeItems: 'Type' }}
                     hasSelectAll={false}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           type: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <Button
                  onClick={showFiltersHandler}
                  type={'filter'}
                  text={showFilters ? 'Show Less' : 'Show More'}
                  hideTooltip={true}
                  arrowUp={arrowUp}
               />
            </div>
            <div className={classes.filtersRight}>
               <Button text={'Apply'} type={'blue'} onClick={searchTransactions} />
               <Button text={'Reset'} type={'clear'} onClick={clearState} />
            </div>
         </div>
         <div className={showFilters ? classes.filtersBottom : classes.filterBottomHidden}>
            <NotificationBadge content={state.service}>
               <MultiSelect
                  options={services}
                  labelledBy="Select"
                  value={state.service}
                  overrideStrings={{ selectSomeItems: 'Service' }}
                  onChange={(e: any) => {
                     setState({
                        ...state,
                        service: e,
                     });
                  }}
               />
            </NotificationBadge>
            <NotificationBadge content={state.merchant}>
               <MultiSelect
                  options={merchants}
                  labelledBy="Select"
                  value={state.merchant}
                  overrideStrings={{ selectSomeItems: 'Merchant' }}
                  onChange={(e: any) => {
                     setState({
                        ...state,
                        merchant: e,
                     });
                  }}
               />
            </NotificationBadge>
         </div>
      </>
   );
};

export default TransactionsFilters;
