import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import subscriptionsReducer from './subscriptions/subscriptionsReducer';
import transactionsReducer from './transactions/transactionsReducer';
import servicesReducer from './services/servicesReducer';
import balanceReducer from './balance/BalanceReducer';
import revenueReducer from './revenue/revenueReducer';
import subscribersReducer from './subscribers/subscribersReducer';
import usersReducer from './users/usersReducer';

const rootReducer = combineReducers({
   auth: authReducer,
   transactions: transactionsReducer,
   subscriptions: subscriptionsReducer,
   services: servicesReducer,
   balance: balanceReducer,
   revenue: revenueReducer,
   subscribers: subscribersReducer,
   users: usersReducer,
});

export default rootReducer;
