import React from 'react';
import NotFoundImg from '../assets/images/not-found-img.png';
import ButtonLink from '../components/shared/button/buttonLink/ButtonLink';
import { Routes } from '../constants/routes/routes';

const NotFoundPage = () => {
   return (
      <div className="error-state-container">
         <div className="error-state-image">
            <img alt={'Error image'} src={NotFoundImg} />
         </div>
         <div className="error-state-content">
            <h2>Oops, the page you requested couldn’t be found...</h2>
            <h4>
               Refresh the page after a few minutes and try again. If error persists, contact
               the administrator.
            </h4>
            <ButtonLink text={'Back to home'} to={Routes.TRANSACTIONS_ROUTE} />
         </div>
      </div>
   );
};

export default NotFoundPage;
