import React, { useEffect, useRef, useState } from 'react';
import Button from '../../shared/button/Button';
import classes from './Users.module.scss';
import DateRangePicker from '../../shared/dateRangePicker/DateRangePickerComponent';
import {
   calculateDateNDaysAgo,
   checkValue,
   FormatDate,
   FormatTime,
} from '../../../utils/helpers';
import Input from '../../shared/input/Input';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import { USER_SUBSCRIPTIONS_HEADERS } from '../../../data/tableHeaders';
import Table from '../../table/Table';
import HeaderCell from '../../table/headerCell/HeaderCell';
import { USER_SUBSCRIPTION_FILTER_INPUTS } from '../../../data/userMgtInputs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootStore } from '../../../store/rootStore';
import {
   GetUsersSubscriptions,
   UsersSubscriptionsPaginationUpdate,
} from '../../../store/users/usersActions';
import { UserType } from '../../../store/users/usersTypes';
import moment from 'moment';
import queryString from 'query-string';
import NotificationBadge from '../../shared/notificationBadge/NotificationBadge';
import MultiSelect from 'react-multi-select-component';
import { getMerchants, getServices } from '../../../store/transactions/transactionsActions';
import TableData from '../../table/tableData/TableData';
import TableCell from '../../table/tableCell/TableCell';
import Pagination from '../../pagination/Pagination';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import ConfirmModal from '../../modal/confirmModal/ConfirmModal';
import { Unsubscribe } from '../../../store/subscriptions/subscriptionsActions';
import { CSVLink } from 'react-csv';
import { SubscriptionsType } from '../../../store/subscriptions/subscriptionsTypes';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';

const UserSubscriptions = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const user: UserType = location.state as UserType;
   const storefronts = useSelector((state: RootStore) => state.revenue.filters.storefronts);
   const services = useSelector((store: RootStore) => store.transactions.filters.services);
   const merchants = useSelector((store: RootStore) => store.transactions.filters.merchants);
   const {
      data: subscriptions,
      isLoading,
      pagination,
   } = useSelector((store: RootStore) => store.users.userSubscriptions);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);
   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

   const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<any>('');
   const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

   const [state, setState] = useState<any>({
      subscriptionId: '',
      dateSubscribedLocal: [
         new Date(new Date(calculateDateNDaysAgo(0)).setHours(0, 0, 0, 0)),
         new Date(calculateDateNDaysAgo(0)),
      ],
      dateUnsubscribedLocal: null,
      service: [],
      merchant: [],
   });
   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });

   const parseInputStateHandler = () => {
      const params = {
         storefrontCodes: storefronts[0]?.value,
         emails: user?.email,
         subscriptionIds: state.subscriptionId.trim() || undefined,
         serviceKeys: state.service.map((service: any) => service.value),
         accountIds: state.merchant.map((merchant: any) => merchant.value),
         dateSubscribedLocalFrom: state.dateSubscribedLocal
            ? moment(state.dateSubscribedLocal[0]).utc(true).format()
            : undefined,
         dateSubscribedLocalTo: state.dateSubscribedLocal
            ? moment(state.dateSubscribedLocal[1]).utc(true).format()
            : undefined,
         dateUnsubscribedLocalFrom: state.dateUnsubscribedLocal
            ? moment(state.dateUnsubscribedLocal[0]).utc(true).format()
            : undefined,
         dateUnsubscribedLocalTo: state.dateUnsubscribedLocal
            ? moment(state.dateUnsubscribedLocal[1]).utc(true).format()
            : undefined,
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   useEffect(() => {
      dispatch(getMerchants());
      dispatch(getServices());
   }, []);

   useEffect(() => {
      dispatch(GetUsersSubscriptions(page.first, page.max, parseInputStateHandler()));
   }, [storefronts]);

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   useEffect(() => {
      dispatch(
         GetUsersSubscriptions(
            pagination.first,
            selectedRows[0].value,
            parseInputStateHandler(),
         ),
      );
   }, [page, selectedRows]);
   const clearState = () => {
      setState({
         subscriptionId: '',
         dateSubscribedLocal: [
            new Date(new Date(calculateDateNDaysAgo(0)).setHours(0, 0, 0, 0)),
            new Date(calculateDateNDaysAgo(0)),
         ],
         dateUnsubscribedLocal: null,
         service: [],
         merchant: [],
      });
   };

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setState({
         ...state,
         [name]: value,
      });
   };

   const unsubscribeHandler = (id: string) => {
      dispatch(Unsubscribe(id));
   };

   const searchSubscriptions = () => {
      dispatch(GetUsersSubscriptions(page.first, page.max, parseInputStateHandler()));
   };

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         searchSubscriptions();
      }
   };

   const formatSubscriptionsForCSV = (subscriptions: SubscriptionsType[]) => {
      return subscriptions.map((subscription) => ({
         'Subscription ID': subscription.subscriptionId,
         Merchant: subscription.companyName,
         Service: subscription.serviceName,
         Price: `${subscription.price} ${subscription.currency}`,
         'Date Subscribed': `${FormatDate(subscription.dateSubscribeLocal)} ${FormatTime(
            subscription.dateSubscribeLocal,
         )}`,
         'Date Unsubscribed': subscription.dateUnsubscribeLocal
            ? `${FormatDate(subscription.dateUnsubscribeLocal)} ${FormatTime(
                 subscription.dateUnsubscribeLocal,
              )}`
            : '-',
      }));
   };

   const formattedSubscriptionsData = formatSubscriptionsForCSV(subscriptions);

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               {USER_SUBSCRIPTION_FILTER_INPUTS.map((input, i) => (
                  <Input
                     key={i}
                     type={input.type}
                     name={input.name}
                     placeholder={input.placeholder}
                     value={state[input.name]}
                     onChange={textInputHandler}
                     onKeyUp={keyUpHandler}
                  />
               ))}
               <NotificationBadge content={state.service}>
                  <MultiSelect
                     options={services}
                     labelledBy="Select"
                     value={state.service}
                     hasSelectAll={false}
                     overrideStrings={{ selectSomeItems: 'Service' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           service: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <NotificationBadge content={state.merchant}>
                  <MultiSelect
                     options={merchants}
                     labelledBy="Select"
                     value={state.merchant}
                     hasSelectAll={false}
                     overrideStrings={{ selectSomeItems: 'Merchant' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           merchant: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <div className={classes.filtersDate}>
                  <label>Subscribed: </label>
                  <DateRangePicker
                     name="dateSubscribedLocal"
                     value={state.dateSubscribedLocal}
                     setState={setState}
                  />
               </div>
               <div className={classes.filtersDate}>
                  <label>Unsubscribed: </label>
                  <DateRangePicker
                     name="dateUnsubscribedLocal"
                     value={state.dateUnsubscribedLocal}
                     setState={setState}
                  />
               </div>
            </div>
            <div className={classes.filtersRight}>
               <Button text={'Apply'} type={'blue'} onClick={searchSubscriptions} />
               <Button text={'Reset'} type={'clear'} onClick={clearState} />
            </div>
         </div>
         <div>
            {isLoading ? (
               <TableLoader />
            ) : (
               <>
                  {subscriptions?.length !== 0 ? (
                     <>
                        <Table className={classes.subscriptionsTable}>
                           <TableHeader>
                              <TableRow>
                                 {USER_SUBSCRIPTIONS_HEADERS.map((header, i) => (
                                    <HeaderCell key={i}>{header.text}</HeaderCell>
                                 ))}
                              </TableRow>
                           </TableHeader>
                           <TableData>
                              {subscriptions.map((subscription, i) => (
                                 <TableRow key={i}>
                                    <TableCell>{subscription.subscriptionId}</TableCell>
                                    <TableCell>
                                       {checkValue(subscription.companyName)}
                                    </TableCell>
                                    <TableCell>
                                       {checkValue(subscription.serviceName)}
                                    </TableCell>
                                    <TableCell>
                                       <>
                                          {subscription.price} {subscription.currency}
                                       </>
                                    </TableCell>
                                    <TableCell>
                                       <div>
                                          <div>
                                             {FormatDate(subscription.dateSubscribeLocal)}
                                          </div>
                                          <div className={classes.lowOpacityTimeDate}>
                                             {FormatTime(subscription.dateSubscribeLocal)}
                                          </div>
                                       </div>
                                    </TableCell>
                                    <TableCell>
                                       {subscription.dateUnsubscribeLocal ? (
                                          <div>
                                             <div>
                                                {FormatDate(subscription.dateUnsubscribeLocal)}
                                             </div>
                                             <div className={classes.lowOpacityTimeDate}>
                                                {FormatTime(subscription.dateUnsubscribeLocal)}
                                             </div>
                                          </div>
                                       ) : (
                                          '-'
                                       )}
                                    </TableCell>
                                    <TableCell>
                                       {actionsUser.includes(
                                          UserRoleActions.UNSUBSCRIBE_VIEW_ACTION,
                                       ) ? (
                                          <>
                                             {subscription?.dateUnsubscribeLocal ? (
                                                <Button
                                                   type="blue"
                                                   disabled
                                                   text="Deactivated"
                                                />
                                             ) : (
                                                <Button
                                                   type="delete"
                                                   showIcon={false}
                                                   text="Deactivate"
                                                   onClick={() => {
                                                      setSelectedSubscriptionId(
                                                         subscription.subscriptionId,
                                                      );
                                                      setIsConfirmModalOpen(true);
                                                   }}
                                                />
                                             )}
                                          </>
                                       ) : (
                                          <></>
                                       )}
                                    </TableCell>
                                 </TableRow>
                              ))}
                           </TableData>
                        </Table>
                        <Pagination
                           first={pagination.first}
                           max={pagination.max}
                           curMax={selectedRows[0].value}
                           isSearching={isLoading}
                           rowValue={selectedRows}
                           setPage={setPage}
                           setSelectedRows={setSelectedRows}
                           totalCount={pagination.totalCount}
                           totalPages={totalPageState}
                           dispatchAction={UsersSubscriptionsPaginationUpdate}
                           isServerSide={true}
                           onRowChange={(rowsPerPage: number) => {
                              dispatch(
                                 UsersSubscriptionsPaginationUpdate({
                                    first: 0,
                                    max: rowsPerPage,
                                 }),
                              );
                           }}
                        />
                        {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                           <CSVLink
                              filename={'users-subscriptions.csv'}
                              data={formattedSubscriptionsData}
                              ref={csvRef}
                              onClick={() => {
                                 if (csvRef?.current) {
                                    csvRef.current.link.download = `users-subscriptions-${Date.now()}`;
                                 }
                              }}
                           >
                              <Button
                                 className="export-csv-btn"
                                 type={'blue'}
                                 text="Export as CSV"
                                 hideTooltip={true}
                              />
                           </CSVLink>
                        )}
                     </>
                  ) : (
                     <div className={classes.messageWrap}>
                        There are no results for this search.
                     </div>
                  )}
               </>
            )}

            <ConfirmModal
               headerText="Deactivate subscription"
               isOpen={isConfirmModalOpen}
               onRequestClose={() => setIsConfirmModalOpen(false)}
               onCancel={() => {
                  setIsConfirmModalOpen(false);
                  setSelectedSubscriptionId('');
               }}
               onConfirm={() => {
                  unsubscribeHandler(selectedSubscriptionId);
                  setSelectedSubscriptionId('');
                  setIsConfirmModalOpen(false);
               }}
               text={`Are you sure you want to deactivate subscription?`}
            />
         </div>
      </>
   );
};

export default UserSubscriptions;
