import React from 'react';
import InputLabel from '../inputLabel/InputLabel';
import classes from './Input.module.scss';

interface InputProp extends React.InputHTMLAttributes<HTMLInputElement> {
   type: string;
   label?: string;
   inputState?: string;
   disabledLabel?: boolean;
   className?: string;
   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/* Main Input component. */
const Input = ({
   onChange,
   type,
   disabledLabel = true,
   label,
   inputState,
   className,
   ...rest
}: InputProp) => {
   return (
      <div style={{ position: 'relative', height: 'fit-content' }}>
         {!disabledLabel && <InputLabel label={label!} inputState={inputState!} />}
         <input
            type={type}
            onChange={onChange}
            {...rest}
            className={`${classes.input} ${className} `}
         ></input>
      </div>
   );
};

export default Input;
