import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import KeycloakService from '../../auth/KeycloakService';
import API from '../../api/CentiliService';
import { RevenueActionTypes, RevenueDispatchTypes } from './revenueTypes';

export const getRevenuesByTime =
   (groupBy: string, filters: string) => async (dispatch: Dispatch<RevenueDispatchTypes>) => {
      dispatch({
         type: RevenueActionTypes.GET_REVENUES_BY_TIME_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.revenue.getRevenuesByTime(groupBy, filters, token || '');

         dispatch({
            type: RevenueActionTypes.GET_REVENUES_BY_TIME_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting revenues. ${error}`);
         dispatch({
            type: RevenueActionTypes.GET_REVENUES_BY_TIME_ERROR,
         });
      }
   };

export const getRevenuesByCountry =
   (filters: string) => async (dispatch: Dispatch<RevenueDispatchTypes>) => {
      dispatch({
         type: RevenueActionTypes.GET_REVENUES_BY_COUNTRY_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.revenue.getRevenuesByCountry(filters, token || '');

         dispatch({
            type: RevenueActionTypes.GET_REVENUES_BY_COUNTRY_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting revenues. ${error}`);
         dispatch({
            type: RevenueActionTypes.GET_REVENUES_BY_COUNTRY_ERROR,
         });
      }
   };

export const getStorefronts = () => async (dispatch: Dispatch<RevenueDispatchTypes>) => {
   dispatch({
      type: RevenueActionTypes.GET_STOREFRONTS_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.revenue.getStorefronts(token || '');

      dispatch({
         type: RevenueActionTypes.GET_STOREFRONTS_SUCCESS,
         payload: response.data,
      });
   } catch (error: any) {
      toast.error(`Error getting revenues. ${error}`);
      dispatch({
         type: RevenueActionTypes.GET_STOREFRONTS_ERROR,
      });
   }
};
