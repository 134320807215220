import React, { useState } from 'react';
import { calculateDateNDaysAgo } from '../../../utils/helpers';
import RevenueFilters from './RevenueFilters';
import RevenueTableByTime from './RevenueTableByTime';
import RevenueTableByCountry from './RevenueTableByCountry';
import RevenueChartByTime from './RevenueChartByTime';
import RevenueChartByCountry from './RevenueChartByCountry';

const Revenues = () => {
   const [state, setState] = useState<any>({
      time: [new Date(calculateDateNDaysAgo(7)), new Date(calculateDateNDaysAgo(0))],
      groupBy: [{ label: 'By day', value: 'DAY' }],
      countries: [],
      merchants: [],
      services: [],
      operators: [],
      providers: [],
      serviceTypes: [],
      paymentMethods: [],
      storefronts: [],
   });

   const [resultType, setResultType] = useState('time');

   return (
      <>
         <RevenueFilters state={state} setState={setState} setResultType={setResultType} />
         {resultType == 'time' && (
            <>
               <RevenueChartByTime />
               <RevenueTableByTime state={state} />
            </>
         )}
         {resultType == 'country' && (
            <>
               <RevenueChartByCountry />
               <RevenueTableByCountry />
            </>
         )}
      </>
   );
};

export default Revenues;
