import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { ServicesDispatchTypes, ServicesActionTypes, PaginationType } from './servicesTypes';
import API from '../../api/CentiliService';
import KeycloakService from '../../auth/KeycloakService';

export const getServiceData =
   (first: number, max: number, filtersQuery: any) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_SERVICES_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.services.getAllServices(
            first,
            max,
            filtersQuery,
            token || '',
         );

         dispatch({
            type: ServicesActionTypes.SERVICES_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: ServicesActionTypes.GET_SERVICES_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting all services. ${error}`);
         dispatch({
            type: ServicesActionTypes.GET_SERVICES_ERROR,
         });
      }
   };

export const servicesPaginationUpdate =
   (paginationData: PaginationType) => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.SERVICES_PAGINATION_UPDATE,
         payload: paginationData,
      });
   };

export const getServicePackages =
   (serviceKey: string, serviceType: string) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_SERVICE_PACKAGES_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.services.getServicePackages(
            serviceKey,
            serviceType,
            token || '',
         );
         dispatch({
            type: ServicesActionTypes.GET_SERVICE_PACKAGES_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting service packages. ${error}`);
         dispatch({
            type: ServicesActionTypes.GET_SERVICE_PACKAGES_ERROR,
         });
      }
   };

export const getExchangeRate = () => async (dispatch: Dispatch<ServicesDispatchTypes>) => {
   dispatch({
      type: ServicesActionTypes.GET_EXCHANGE_RATE_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.services.getExchangeRate(token || '');

      dispatch({
         type: ServicesActionTypes.GET_EXCHANGE_RATE_SUCCESS,
         payload: response.data,
      });
   } catch (error: any) {
      toast.error(`Error getting exchange rate. ${error}`);
      dispatch({
         type: ServicesActionTypes.GET_EXCHANGE_RATE_ERROR,
      });
   }
};

export const changeExchangeRate =
   (data: any) => async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.CHANGE_EXCHANGE_RATE_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         await API.services.changeExchangeRate(data, token || '');

         dispatch({
            type: ServicesActionTypes.CHANGE_EXCHANGE_RATE_SUCCESS,
         });
         toast.dark('Exchange rate successfully changed!');
         dispatch(getExchangeRate() as any);
      } catch (error: any) {
         toast.error(`Error changing exchange rate. ${error}`);
         dispatch({
            type: ServicesActionTypes.CHANGE_EXCHANGE_RATE_ERROR,
         });
      }
   };

export const getServiceCountries =
   (serviceKey: string) => async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_SERVICE_COUNTRIES_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');
         const response = await API.services.getServiceCountries(serviceKey, token || '');

         dispatch({
            type: ServicesActionTypes.GET_SERVICE_COUNTRIES_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting service countries. ${error}`);
         dispatch({
            type: ServicesActionTypes.GET_SERVICE_COUNTRIES_ERROR,
         });
      }
   };

export const getOperators =
   (paymentMethod: string, countryCode: string) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_OPERATORS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');
         const response = await API.services.getOperators(
            paymentMethod,
            countryCode,
            token || '',
         );

         dispatch({
            type: ServicesActionTypes.GET_OPERATORS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting operators. ${error}`);
         dispatch({
            type: ServicesActionTypes.GET_OPERATORS_ERROR,
         });
      }
   };
//* change any
export const addServicePackage =
   (data: any, serviceType: string) => async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.ADD_PACKAGE_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');
         await API.services.addEditServicePackage(data, token || '');

         dispatch({
            type: ServicesActionTypes.ADD_PACKAGE_SUCCESS,
         });
         toast.dark('Package added.');
         dispatch(getServicePackages(data.serviceKey, serviceType) as any);
      } catch (error: any) {
         if (error.response.data.message) {
            toast.error(error.response.data.message);
         } else {
            toast.error(`Error adding package. ${error}`);
         }
         dispatch({
            type: ServicesActionTypes.ADD_PACKAGE_ERROR,
         });
      }
   };

//* change any
export const editServicePackage =
   (data: any, serviceType: string) => async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.EDIT_PACKAGE_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');
         await API.services.addEditServicePackage(data, token || '');

         dispatch({
            type: ServicesActionTypes.EDIT_PACKAGE_SUCCESS,
         });

         toast.dark('Package edited.');
         dispatch(getServicePackages(data.serviceKey, serviceType) as any);
      } catch (error: any) {
         if (error.response.data.message) {
            toast.error(error.response.data.message);
         } else {
            toast.error(`Error editing package. ${error}`);
         }
         dispatch({
            type: ServicesActionTypes.EDIT_PACKAGE_ERROR,
         });
      }
   };

export const deleteServicePackage =
   (serviceKey: string, packageId: string, serviceType: string) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.DELETE_PACKAGE_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');
         await API.services.deleteServicePackage(serviceKey, packageId, token || '');

         dispatch({
            type: ServicesActionTypes.DELETE_PACKAGE_SUCCESS,
         });

         toast.dark('Package deleted.');
         dispatch(getServicePackages(serviceKey, serviceType) as any);
      } catch (error: any) {
         if (error.response.data.message) {
            toast.error(error.response.data.message);
         } else {
            toast.error(`Error deleting package. ${error}`);
         }
         dispatch({
            type: ServicesActionTypes.DELETE_PACKAGE_ERROR,
         });
      }
   };
