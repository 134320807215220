import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import {
   getCountries,
   getMerchants,
   getOperators,
   getServices,
} from '../../../store/transactions/transactionsActions';
import classes from './Revenue.module.scss';
import queryString from 'query-string';
import NotificationBadge from '../../shared/notificationBadge/NotificationBadge';
import MultiSelect from 'react-multi-select-component';
import Button from '../../shared/button/Button';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
   getRevenuesByCountry,
   getRevenuesByTime,
   getStorefronts,
} from '../../../store/revenue/revenueActions';
import cx from 'classnames';
import { cancelDropdown } from '../../../utils/cancelDropdown';
import DateRangePicker from '../../shared/dateRangePicker/DateRangePickerComponent';
import {
   PAYMENT_METHOD_OPTIONS,
   REVENUE_GROUP_BY_OPTIONS,
   SERVICE_TYPE_OPTIONS,
} from '../../../data/dropdownValues';
import { calculateDateNDaysAgo } from '../../../utils/helpers';

interface Props {
   state: any;
   setState: (e: any) => void;
   setResultType: (type: string) => void;
}

const RevenueFilters = ({ state, setState, setResultType }: Props) => {
   const dispatch = useDispatch();
   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);
   const operators = useSelector((store: RootStore) => store.transactions.filters.operators);
   const services = useSelector((store: RootStore) => store.transactions.filters.services);
   const merchants = useSelector((store: RootStore) => store.transactions.filters.merchants);
   const storefronts = useSelector((store: RootStore) => store.revenue.filters.storefronts);

   const [showFilters, setShowFilters] = useState(false);
   const [arrowUp, setArrowUp] = useState(false);

   const showFiltersHandler = () => {
      setShowFilters(!showFilters);
      setArrowUp(!arrowUp);
   };

   useEffect(() => {
      dispatch(getCountries());
      dispatch(getOperators());
      dispatch(getMerchants());
      dispatch(getServices());
      dispatch(getStorefronts());
   }, []);

   const mapValues = (arr = []) => arr.map((item: any) => item.value);

   const clearState = () => {
      setState({
         time: [new Date(calculateDateNDaysAgo(7)), new Date(calculateDateNDaysAgo(0))],
         groupBy: [{ label: 'By day', value: 'DAY' }],
         countries: [],
         merchants: [],
         services: [],
         operators: [],
         providers: [],
         serviceTypes: [],
         paymentMethods: [],
         storefronts: [],
      });
   };

   const parseInputStateHandler = () => {
      const params = {
         startTime: state.time
            ? moment(state.time[0]).utc(true).format()
            : moment().subtract(7, 'days').utc(true).format(),
         endTime: state.time
            ? moment(state.time[1]).utc(true).format()
            : moment().utc(true).format(),
         countries: mapValues(state.countries),
         accountIds: mapValues(state.merchants),
         serviceKeys: mapValues(state.services),
         operatorCodes: mapValues(state.operators),
         providerCodes: mapValues(state.providers),
         serviceTypes: mapValues(state.serviceTypes),
         paymentMethods: mapValues(state.paymentMethods),
         storefrontCodes: mapValues(state.storefronts),
      };

      return queryString.stringify(params);
   };

   const searchRevenue = () => {
      if (state.groupBy[0].value == 'COUNTRY') {
         dispatch(getRevenuesByCountry(parseInputStateHandler()));
         setResultType('country');
      } else {
         dispatch(getRevenuesByTime(state.groupBy[0].value, parseInputStateHandler()));
         setResultType('time');
      }
   };

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               <DateRangePicker
                  name="time"
                  value={state.time}
                  setState={setState}
                  cleanable={false}
                  format="yyyy-MM-dd"
               />
               <MultiSelect
                  overrideStrings={{ selectSomeItems: 'Group by' }}
                  className={cx(classes.filtersInput, 'singleInput type groupBy')}
                  options={REVENUE_GROUP_BY_OPTIONS}
                  value={state.groupBy}
                  labelledBy={'Select'}
                  onChange={(e: any) => {
                     if (e.length === 0) {
                        setState({
                           ...state,
                           groupBy: [{ label: 'By day', value: 'DAY' }],
                        });
                        return;
                     }
                     const newValue = e[e.length - 1] || e;
                     setState({
                        ...state,
                        groupBy: [newValue],
                     });
                     cancelDropdown('groupBy');
                  }}
                  hasSelectAll={false}
                  disableSearch={true}
               />
               <NotificationBadge content={state.countries}>
                  <MultiSelect
                     options={countries}
                     labelledBy="Select"
                     value={state.countries}
                     overrideStrings={{ selectSomeItems: 'Country' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           countries: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <NotificationBadge content={state.merchants}>
                  <MultiSelect
                     options={merchants}
                     labelledBy="Select"
                     value={state.merchants}
                     overrideStrings={{ selectSomeItems: 'Merchant' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           merchants: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <NotificationBadge content={state.services}>
                  <MultiSelect
                     options={services}
                     labelledBy="Select"
                     value={state.services}
                     overrideStrings={{ selectSomeItems: 'Service' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           services: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <Button
                  onClick={showFiltersHandler}
                  type={'filter'}
                  text={showFilters ? 'Show Less' : 'Show More'}
                  hideTooltip={true}
                  arrowUp={arrowUp}
               />
            </div>
            <div className={classes.filtersRight}>
               <Button text={'Apply'} type={'blue'} onClick={searchRevenue} />
               <Button text={'Reset'} type={'clear'} onClick={clearState} />
            </div>
         </div>
         <div className={showFilters ? classes.filtersBottom : classes.filterBottomHidden}>
            <NotificationBadge content={state.serviceTypes}>
               <MultiSelect
                  options={SERVICE_TYPE_OPTIONS}
                  labelledBy="Select"
                  value={state.serviceTypes}
                  overrideStrings={{ selectSomeItems: 'Service type' }}
                  onChange={(e: any) => {
                     setState({
                        ...state,
                        serviceTypes: e,
                     });
                  }}
               />
            </NotificationBadge>
            <NotificationBadge content={state.paymentMethods}>
               <MultiSelect
                  options={PAYMENT_METHOD_OPTIONS}
                  labelledBy="Select"
                  value={state.paymentMethods}
                  overrideStrings={{ selectSomeItems: 'Payment method' }}
                  onChange={(e: any) => {
                     setState({
                        ...state,
                        paymentMethods: e,
                     });
                  }}
               />
            </NotificationBadge>
            <NotificationBadge content={state.operators}>
               <MultiSelect
                  options={operators}
                  labelledBy="Select"
                  value={state.operators}
                  overrideStrings={{ selectSomeItems: 'Operator' }}
                  onChange={(e: any) => {
                     setState({
                        ...state,
                        operators: e,
                     });
                  }}
               />
            </NotificationBadge>
            {storefronts && storefronts.length > 0 && (
               <NotificationBadge content={state.storefronts}>
                  <MultiSelect
                     options={storefronts}
                     labelledBy="Select"
                     value={state.storefronts}
                     overrideStrings={{ selectSomeItems: 'Storefront' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           storefronts: e,
                        });
                     }}
                  />
               </NotificationBadge>
            )}
            {/* TODO providers */}
         </div>
      </>
   );
};

export default RevenueFilters;
