import axios from 'axios';
import { API } from '../constants/api/api';

const axiosBaseURL =
   process.env.REACT_APP_ENV === 'production' ? API.BASE_URL_PRODUCTION : API.BASE_URL_DEV;

export const exportBaseUrl =
   process.env.REACT_APP_ENV === 'production'
      ? 'https://api.centili.com/'
      : 'https://stage.centili.com/';

export default axios.create({
   baseURL: axiosBaseURL,
});

axios.interceptors.request.use((req) => {
   // Important: request interceptors **must** return the request.
   return req;
});
