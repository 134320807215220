import React, { useState } from 'react';
import SubscribersFilters from './SubscribersFilters';
import SubscribersTable from './SubscribersTable';
import moment from 'moment';
import queryString from 'query-string';
import { SUBSCRIBER_BLACKLIST_STATUS_OPTIONS } from '../../../data/dropdownValues';

const Subscribers = () => {
   const [state, setState] = useState<any>({
      identifier: '',
      dateFirstActivity: [],
      dateLastActivity: [],
      blacklistStatus: [SUBSCRIBER_BLACKLIST_STATUS_OPTIONS[0]],
      dateBlacklisted: [],
   });

   const [exportData, setExportData] = useState([]);

   const parseInputStateHandler = () => {
      const params = {
         msisdns: state.identifier.trim() || undefined,
         blacklisted: state.blacklistStatus[0].value == 'YES' ? true : undefined,
         dateFirstActivityFrom:
            state.dateFirstActivity && state.dateFirstActivity[0]
               ? moment(state.dateFirstActivity[0]).utc(true).format()
               : undefined,
         dateFirstActivityTo:
            state.dateFirstActivity && state.dateFirstActivity[1]
               ? moment(state.dateFirstActivity[1]).utc(true).format()
               : undefined,
         dateLastActivityFrom:
            state.dateLastActivity && state.dateLastActivity[0]
               ? moment(state.dateLastActivity[0]).utc(true).format()
               : undefined,
         dateLastActivityTo:
            state.dateLastActivity && state.dateLastActivity[1]
               ? moment(state.dateLastActivity[1]).utc(true).format()
               : undefined,
         dateBlacklistStartFrom:
            state.dateBlacklisted && state.dateBlacklisted[0]
               ? moment(state.dateBlacklisted[0]).utc(true).format()
               : undefined,
         dateBlacklistStartTo:
            state.dateBlacklisted && state.dateBlacklisted[1]
               ? moment(state.dateBlacklisted[1]).utc(true).format()
               : undefined,
      };

      return queryString.stringify(params);
   };

   return (
      <>
         <SubscribersFilters
            state={state}
            setState={setState}
            getFilterQueryString={parseInputStateHandler}
            setExportData={setExportData}
         />
         <SubscribersTable
            exportData={exportData}
            setExportData={setExportData}
            getFilterQueryString={parseInputStateHandler}
         />
      </>
   );
};

export default Subscribers;
