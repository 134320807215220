import React, { useState } from 'react';
import { calculateDateNDaysAgo } from '../../../utils/helpers';
import TransactionsFilters from './TransactionsFilters';
import TransactionsTable from './TransactionsTable';

const Transactions = () => {
   const [state, setState] = useState<any>({
      transactionId: '',
      subscriber: '',
      country: [],
      status: [],
      timeChargedLocal: [
         new Date(new Date(calculateDateNDaysAgo(0)).setHours(0, 0, 0, 0)),
         new Date(calculateDateNDaysAgo(0)),
      ],
      type: [],
      notification: [],
      service: [],
      merchant: [],
      clientId: '',
   });

   const [exportData, setExportData] = useState([]);

   return (
      <>
         <TransactionsFilters
            state={state}
            setState={setState}
            setExportData={setExportData}
         />
         <TransactionsTable
            state={state}
            exportData={exportData}
            setExportData={setExportData}
         />
      </>
   );
};

export default Transactions;
