import React from 'react';
import classes from './Checkbox.module.scss';
import cx from 'classnames';
import Tippy from '@tippyjs/react';

interface CheckboxProp extends React.InputHTMLAttributes<HTMLInputElement> {
   checked: boolean;
   onClick: (userType: any, id?: any) => void;
   className?: string;
   text: string;
   disabled?: boolean;
   tooltip?: string;
}

const Checkbox = ({
   checked,
   onClick,
   className,
   text,
   disabled,
   tooltip,
   ...rest
}: CheckboxProp) => {
   return (
      <>
         <input
            id="checkbox"
            className={cx(classes.checkbox, { [classes.disabled]: disabled })}
            {...rest}
            type="checkbox"
            checked={checked}
         />
         {tooltip ? (
            <Tippy content={tooltip}>
               <span
                  onClick={onClick}
                  className={cx(
                     classes.checkboxSpan,
                     { [classes.disabled]: disabled },
                     className,
                  )}
               >
                  {text}
               </span>
            </Tippy>
         ) : (
            <span
               onClick={onClick}
               className={cx(
                  classes.checkboxSpan,
                  { [classes.disabled]: disabled },
                  className,
               )}
            >
               {text}
            </span>
         )}
      </>
   );
};

export default Checkbox;
