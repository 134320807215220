import React, { ReactChild, ReactChildren } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './SidebarItem.module.scss';

interface SidebarItemProp {
   children?: ReactChild | ReactChildren;
   text: string;
   path: string;
   onClick?: () => void;
}

/* Main Menu Item - Router Nav link */
const SidebarItem = ({ children, text, path, onClick }: SidebarItemProp) => {
   return (
      <li className={classes.sidebarLink}>
         <NavLink
            className={classes.navLink}
            activeClassName={classes.active}
            onClick={onClick}
            to={path}
         >
            {children}
            {text}
         </NavLink>
      </li>
   );
};

export default SidebarItem;
