import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import React, { useEffect, useRef, useState } from 'react';
import {
   BlacklistSubscriber,
   GetAllSubscribers,
   PaginationUpdate,
   UnblacklistSubscriber,
} from '../../../store/subscribers/subscribersActions';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import classes from './Subscribers.module.scss';
import Table from '../../table/Table';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import { SUBSCRIBERS_HEADERS } from '../../../data/tableHeaders';
import HeaderCell from '../../table/headerCell/HeaderCell';
import TableData from '../../table/tableData/TableData';
import TableCell from '../../table/tableCell/TableCell';
import { checkValue, FormatDate, FormatTime } from '../../../utils/helpers';
import Pagination from '../../pagination/Pagination';
import Button from '../../shared/button/Button';
import Checkbox from '../../shared/checkbox/Checkbox';
import { SubscribersType } from '../../../store/subscribers/subscribersTypes';
import ConfirmModal from '../../modal/confirmModal/ConfirmModal';
import TextareaModal from '../../modal/textboxModal/TextboxModal';
import { CSVLink } from 'react-csv';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';
import KeycloakService from '../../../auth/KeycloakService';
import API from '../../../api/CentiliService';
import { toast } from 'react-toastify';

interface Props {
   exportData: any;
   setExportData: (e: any) => void;
   getFilterQueryString: () => string;
}

const SubscribersTable = ({ exportData, setExportData, getFilterQueryString }: Props) => {
   const dispatch = useDispatch();

   const subscribers: any = useSelector(
      (store: RootStore) => store.subscribers.subscribers.data,
   );
   const { isLoading, pagination } = useSelector(
      (store: RootStore) => store.subscribers.subscribers,
   );
   const { isNextDisabled } = useSelector(
      (store: RootStore) => store.subscribers.subscribers.pagination,
   );

   const [selectedRows, setSelectedRows] = useState<any>([
      { label: pagination.max.toString(), value: pagination.max },
   ]);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });
   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const [selectedAllMsisdns, setSelectedAllMsisdns] = useState<boolean>(false);
   const [selectedMsisdns, setSelectedMsisdns] = useState<boolean[]>(Array(8).fill(false, 0));

   const [isExportDataLoading, setIsExportDataLoading] = useState(false);

   const [showUnblacklistModal, setShowUnblacklistModal] = useState<boolean>(false);
   const [showBlacklistSelectedModal, setShowBlacklistSelectedModal] =
      useState<boolean>(false);
   const [blacklistSingleModelIndex, setBlacklistSingleModelIndex] = useState<number>(-1);

   const getSubscriberIdentifier = (s: SubscribersType) =>
      s.identifier ? s.identifier : s.email ? s.email : s.msisdn;
   const getSelectedMsisdns = () => {
      if (selectedAllMsisdns) {
         return subscribers.map((s: SubscribersType) => getSubscriberIdentifier(s));
      } else {
         const subIndices = selectedMsisdns.flatMap((checked: boolean, index: number) =>
            checked ? index : [],
         );
         return subIndices
            .map((i: number) => subscribers[i])
            .map((s: SubscribersType) => getSubscriberIdentifier(s));
      }
   };
   const selectSubscriber = (i: number) => {
      const newArray = [...selectedMsisdns];
      if (selectedMsisdns[i]) {
         newArray[i] = false;
         setSelectedMsisdns(newArray);
         setSelectedAllMsisdns(false);
      } else {
         newArray[i] = true;
         setSelectedMsisdns(newArray);
         if (!newArray.some((v) => !v)) {
            // if no values are false (<=> all are true)
            setSelectedAllMsisdns(true);
         }
      }
   };
   const toggleSelectAllSubscribers = () => {
      if (selectedAllMsisdns) {
         setSelectedAllMsisdns(false);
         setSelectedMsisdns(Array(selectedRows[0].value).fill(false, 0));
      } else {
         setSelectedAllMsisdns(true);
         setSelectedMsisdns(Array(selectedRows[0].value).fill(true, 0));
      }
   };
   const clearSelection = () => {
      setSelectedMsisdns(Array(selectedRows[0].value).fill(false));
      setSelectedAllMsisdns(false);
   };
   const blacklistSelected = (reason: string) => {
      setShowBlacklistSelectedModal(false);
      dispatch(
         BlacklistSubscriber(
            getSelectedMsisdns(),
            reason,
            pagination.first,
            pagination.max,
            getFilterQueryString(),
         ),
      );
      clearSelection();
   };
   const unblacklistSelected = () => {
      setShowUnblacklistModal(false);
      dispatch(
         UnblacklistSubscriber(
            getSelectedMsisdns(),
            pagination.first,
            pagination.max,
            getFilterQueryString(),
         ),
      );
      clearSelection();
   };
   const blacklistSpecific = (reason: string) => {
      dispatch(
         BlacklistSubscriber(
            [getSubscriberIdentifier(subscribers[blacklistSingleModelIndex])],
            reason,
            pagination.first,
            pagination.max,
            getFilterQueryString(),
         ),
      );
      setBlacklistSingleModelIndex(-1);
      clearSelection();
   };

   useEffect(() => {
      dispatch(
         GetAllSubscribers(pagination.first, selectedRows[0].value, getFilterQueryString()),
      );
      const diff = selectedRows[0].value - selectedMsisdns.length;
      if (diff > 0) {
         setSelectedMsisdns(selectedMsisdns.concat(Array(diff).fill(false)));
         setSelectedAllMsisdns(false);
      } else if (diff < 0) {
         const newSelections = selectedMsisdns.slice(0, selectedRows[0].value);
         setSelectedMsisdns(newSelections);
         if (!newSelections.some((v) => !v)) {
            // if no values are false (<=> all are true)
            setSelectedAllMsisdns(true);
         }
      } else {
         // we haven't changed page size, so we must have changed pages
         clearSelection();
      }
   }, [page]);

   useEffect(() => {
      dispatch(GetAllSubscribers(page.first, page.max, getFilterQueryString()));
   }, []);

   const formatDateTime = (datetime: string) => {
      return (
         <div>
            <div>{FormatDate(datetime)}</div>
            <div className={classes.timeField}>{FormatTime(datetime)}</div>
         </div>
      );
   };

   const formatSubscribersForCSV = (subscribers: SubscribersType[]) => {
      return subscribers.map((subscriber) => ({
         Subscriber: subscriber.identifier || subscriber.email || subscriber.msisdn,
         Operator: subscriber.operator,
         'First Activity':
            FormatDate(subscriber.firstActivity) + ' ' + FormatTime(subscriber.firstActivity),
         'Last Activity':
            FormatDate(subscriber.lastActivity) + ' ' + FormatTime(subscriber.lastActivity),
         Blacklisted: subscriber.blacklisted ? 'Blacklisted' : 'Not Blacklisted',
         'Blacklisted By': checkValue(subscriber.blacklistedBy),
         'Blacklist Reason': checkValue(subscriber.blacklistReason),
         'Blacklist Origin': checkValue(subscriber.blacklistOrigin),
         'Date Blacklisted': subscriber.blacklistTime
            ? FormatDate(subscriber.blacklistTime) + ' ' + FormatTime(subscriber.blacklistTime)
            : '-',
      }));
   };

   const getExportData = () => {
      const getData = async () => {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         setIsExportDataLoading(true);
         await API.subscribers
            .getSubscribersForExport(0, 10000, getFilterQueryString(), token || '')
            .then((response) => {
               setExportData(formatSubscribersForCSV(response.data));
               setIsExportDataLoading(false);
               return csvRef?.current?.link.click();
            })
            .catch(() => {
               setIsExportDataLoading(false);
               toast.error('Error getting subscribers.');
            });
      };

      getData();
   };
   return (
      <>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               {subscribers.length === 0 ? (
                  <div className={classes.messageWrap}>
                     There are no results for this search.
                  </div>
               ) : (
                  <>
                     <Button
                        className={classes.buttonBlacklist}
                        text={'Blacklist'}
                        type={'primary'}
                        onClick={() => setShowBlacklistSelectedModal(true)}
                     />
                     <Button
                        className={classes.buttonUnblacklist}
                        text={'Unblacklist'}
                        type={'blue'}
                        onClick={() => setShowUnblacklistModal(true)}
                     />

                     <Table className={classes.subscribersTable}>
                        <TableHeader>
                           <TableRow>
                              <>
                                 <HeaderCell key={-1}>
                                    <Checkbox
                                       checked={selectedAllMsisdns}
                                       onClick={() => toggleSelectAllSubscribers()}
                                       text={''}
                                    />
                                 </HeaderCell>
                                 {SUBSCRIBERS_HEADERS.map((header, i) => (
                                    <HeaderCell key={i}>{header.text}</HeaderCell>
                                 ))}
                              </>
                           </TableRow>
                        </TableHeader>
                        <TableData>
                           {subscribers.map((subscriber: SubscribersType, i: number) => (
                              <TableRow key={i}>
                                 <TableCell>
                                    <Checkbox
                                       checked={selectedMsisdns[i] || false}
                                       onClick={() => selectSubscriber(i)}
                                       text={''}
                                    />
                                 </TableCell>
                                 <TableCell>{getSubscriberIdentifier(subscriber)}</TableCell>
                                 <TableCell>{subscriber.operator}</TableCell>
                                 <TableCell>
                                    {formatDateTime(subscriber.firstActivity)}
                                 </TableCell>
                                 <TableCell>
                                    {formatDateTime(subscriber.lastActivity)}
                                 </TableCell>
                                 <TableCell>
                                    {subscriber.blacklisted ? (
                                       <Button text={'Blacklisted'} type={'blue'} />
                                    ) : (
                                       <Button
                                          text={'Blacklist'}
                                          type={'primary'}
                                          onClick={() => setBlacklistSingleModelIndex(i)}
                                       />
                                    )}
                                 </TableCell>
                                 <TableCell>{checkValue(subscriber.blacklistedBy)}</TableCell>
                                 <TableCell>
                                    {checkValue(subscriber.blacklistReason)}
                                 </TableCell>
                                 <TableCell>
                                    {checkValue(subscriber.blacklistOrigin)}
                                 </TableCell>
                                 <TableCell>
                                    {subscriber.blacklistTime
                                       ? formatDateTime(subscriber.blacklistTime)
                                       : '-'}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableData>
                     </Table>

                     <Pagination
                        first={pagination.first}
                        max={pagination.max}
                        curMax={selectedRows[0].value}
                        isSearching={isLoading}
                        rowValue={selectedRows}
                        setPage={setPage}
                        setSelectedRows={setSelectedRows}
                        totalCount={-1}
                        numOfRows={subscribers.length}
                        isNextDisabled={isNextDisabled}
                        totalPages={-1}
                        dispatchAction={PaginationUpdate}
                        isServerSide={true}
                        onRowChange={(rowsPerPage: number) => {
                           dispatch(PaginationUpdate({ first: 0, max: rowsPerPage }));
                        }}
                     />
                     {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                        <>
                           {exportData.length === 0 ? (
                              <a download>
                                 <Button
                                    className="export-csv-btn"
                                    type={'template'}
                                    text={'Export as CSV'}
                                    onClick={getExportData}
                                    showLoader={isExportDataLoading}
                                 />
                              </a>
                           ) : (
                              <CSVLink
                                 filename={'subscribers.csv'}
                                 data={exportData}
                                 ref={csvRef}
                                 onClick={() => {
                                    if (csvRef?.current) {
                                       csvRef.current.link.download = `subscribers-${Date.now()}`;
                                    }
                                 }}
                              >
                                 <Button
                                    className="export-csv-btn"
                                    type={'blue'}
                                    text="Export as CSV"
                                    hideTooltip={true}
                                 />
                              </CSVLink>
                           )}
                        </>
                     )}
                  </>
               )}
            </>
         )}

         <ConfirmModal
            text={'Are you sure you want to unblacklist subscribers?'}
            onConfirm={unblacklistSelected}
            onCancel={() => setShowUnblacklistModal(false)}
            headerText={'Unblacklist'}
            isOpen={showUnblacklistModal}
            onRequestClose={() => setShowUnblacklistModal(false)}
         />
         <TextareaModal
            text={'Reason'}
            onConfirm={blacklistSelected}
            onCancel={() => setShowBlacklistSelectedModal(false)}
            headerText={'Blacklist'}
            confirmText={'Blacklist'}
            isOpen={showBlacklistSelectedModal}
            onRequestClose={() => setShowUnblacklistModal(false)}
         />
         <TextareaModal
            text={'Reason'}
            onConfirm={blacklistSpecific}
            onCancel={() => setBlacklistSingleModelIndex(-1)}
            headerText={'Blacklist'}
            confirmText={'Blacklist'}
            isOpen={blacklistSingleModelIndex >= 0}
            onRequestClose={() => setBlacklistSingleModelIndex(-1)}
         />
      </>
   );
};

export default SubscribersTable;
