import React from 'react';

interface IframeProps {
   src: string;
}

const IframeComponent: React.FC<IframeProps> = ({ src }) => {
   return (
      <div>
         <iframe src={src} title="Embedded Looker Studio" width="100%" height="2000px" />
      </div>
   );
};

const Analytics: React.FC = () => {
   const embedUrl =
      'https://lookerstudio.google.com/embed/u/0/reporting/d36ef453-a448-4931-a67f-4a5c3e73e5ff/page/HOJAC';

   return (
      <div>
         <IframeComponent src={embedUrl} />
      </div>
   );
};

export default Analytics;
