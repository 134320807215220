import {
   TransactionsDispatchTypes,
   TransactionsActionTypes,
   PaginationType,
} from './transactionsTypes';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import KeycloakService from '../../auth/KeycloakService';
import API from '../../api/CentiliService';

export const GetAllTransactions =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
      dispatch({
         type: TransactionsActionTypes.GET_TRANSACTIONS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.transactions.getAllTransactions(
            first,
            max,
            filters,
            token || '',
         );
         dispatch({
            type: TransactionsActionTypes.TRANSACTIONS_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting all transactions. ${error}`);
         dispatch({
            type: TransactionsActionTypes.GET_TRANSACTIONS_ERROR,
         });
      }
   };

export const GetBundleParts =
   (transactionId: string) => async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
      dispatch({
         type: TransactionsActionTypes.GET_BUNDLE_PARTS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.transactions.getBundleParts(transactionId, token || '');

         dispatch({
            type: TransactionsActionTypes.GET_BUNDLE_PARTS_SUCCESS,
            payload: { transactionId, bundleParts: response.data },
         });
      } catch (error: any) {
         toast.error(`Error getting bundle parts. ${error}`);
         dispatch({
            type: TransactionsActionTypes.GET_BUNDLE_PARTS_ERROR,
         });
      }
   };

export const getCountries = () => async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
   dispatch({
      type: TransactionsActionTypes.GET_COUNTRIES_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.transactions.getCountries(token || '');
      dispatch({
         type: TransactionsActionTypes.GET_COUNTRIES_SUCCESS,
         payload: response.data,
      });
   } catch (error) {
      toast.error(`Error getting countries. ${error}`);
      dispatch({
         type: TransactionsActionTypes.GET_COUNTRIES_ERROR,
      });
   }
};

export const getServices = () => async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
   dispatch({
      type: TransactionsActionTypes.GET_SERVICES_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.transactions.getServices(token || '');
      dispatch({
         type: TransactionsActionTypes.GET_SERVICES_SUCCESS,
         payload: response.data,
      });
   } catch (error) {
      toast.error(`Error getting services. ${error}`);
      dispatch({
         type: TransactionsActionTypes.GET_SERVICES_ERROR,
      });
   }
};

export const getMerchants = () => async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
   dispatch({
      type: TransactionsActionTypes.GET_MERCHANTS_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.transactions.getMerchants(token || '');
      dispatch({
         type: TransactionsActionTypes.GET_MERCHANTS_SUCCESS,
         payload: response.data,
      });
   } catch (error) {
      toast.error(`Error getting merchants. ${error}`);
      dispatch({
         type: TransactionsActionTypes.GET_MERCHANTS_ERROR,
      });
   }
};

export const getOperators = () => async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
   dispatch({
      type: TransactionsActionTypes.GET_OPERATORS_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.transactions.getOperators(token || '');
      dispatch({
         type: TransactionsActionTypes.GET_OPERATORS_SUCCESS,
         payload: response.data,
      });
   } catch (error) {
      toast.error(`Error getting operators. ${error}`);
      dispatch({
         type: TransactionsActionTypes.GET_OPERATORS_ERROR,
      });
   }
};

export const resendContentSMS =
   (transactionId: string) => async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
      dispatch({
         type: TransactionsActionTypes.RESEND_CONTENT_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         await API.transactions.resendContent(transactionId, token || '');
         dispatch({
            type: TransactionsActionTypes.RESEND_CONTENT_SUCCESS,
         });

         toast.dark('Successfully resent content SMS.');
      } catch (error: any) {
         if (error.response.data.message) {
            toast.error(error.response.data.message);
         } else {
            toast.error(`Error resending content sms. ${error}`);
         }
         dispatch({
            type: TransactionsActionTypes.RESEND_CONTENT_ERROR,
         });
      }
   };

export const refundTransaction =
   (transactionId: string, reason: string) =>
   async (dispatch: Dispatch<TransactionsDispatchTypes>) => {
      dispatch({
         type: TransactionsActionTypes.REFUND_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         await API.transactions.refund(transactionId, reason, token || '');
         dispatch({
            type: TransactionsActionTypes.REFUND_SUCCESS,
         });

         toast.dark('Successfully refunded transaction.');
      } catch (error: any) {
         toast.error(`Error refunding transaction.`);
         dispatch({
            type: TransactionsActionTypes.REFUND_ERROR,
         });
      }
   };

export const PaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<TransactionsDispatchTypes>) => {
      dispatch({
         type: TransactionsActionTypes.TRANSACTIONS_PAGINATION_UPDATE,
         payload: pagination,
      });
   };
