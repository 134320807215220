import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import {
   TransactionsActionTypes,
   TransactionsDispatchTypes,
   TransactionsState,
} from './transactionsTypes';
const transactionsState: TransactionsState = {
   transactions: {
      data: [],
      isLoading: false,
      bundleParts: null,
      isBundlePartsLoading: false,
      pagination: {
         first: 0,
         max: 8,
         totalCount: 8,
      },
   },
   filters: {
      countries: [],
      operators: [],
      merchants: [],
      services: [],
      isLoading: false,
   },
};

const transactionsReducer: Reducer<TransactionsState> = produce(
   (draft: Draft<TransactionsState>, action: TransactionsDispatchTypes) => {
      switch (action.type) {
         case TransactionsActionTypes.GET_TRANSACTIONS_START:
            draft.transactions.isLoading = true;
            return;
         case TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS:
            draft.transactions.isLoading = false;
            draft.transactions.data = action.payload;
            return;
         case TransactionsActionTypes.GET_TRANSACTIONS_ERROR:
            draft.transactions.isLoading = false;
            return;
         case TransactionsActionTypes.GET_BUNDLE_PARTS_START:
            draft.transactions.isBundlePartsLoading = true;
            return;
         case TransactionsActionTypes.GET_BUNDLE_PARTS_SUCCESS:
            draft.transactions.isBundlePartsLoading = false;
            draft.transactions.bundleParts = {
               ...draft.transactions.bundleParts,

               [action.payload.transactionId]: action.payload.bundleParts,
            };
            return;
         case TransactionsActionTypes.GET_BUNDLE_PARTS_ERROR:
            draft.transactions.isBundlePartsLoading = false;
            return;
         case TransactionsActionTypes.GET_COUNTRIES_START:
            draft.filters.isLoading = true;
            return;
         case TransactionsActionTypes.GET_COUNTRIES_SUCCESS:
            draft.filters.isLoading = false;
            draft.filters.countries = action.payload.map((country) => ({
               label: country.name,
               value: country.code,
            }));
            return;
         case TransactionsActionTypes.GET_COUNTRIES_ERROR:
            draft.filters.isLoading = false;
            return;
         case TransactionsActionTypes.GET_OPERATORS_START:
            draft.filters.isLoading = true;
            return;
         case TransactionsActionTypes.GET_OPERATORS_SUCCESS:
            draft.filters.isLoading = false;
            draft.filters.operators = action.payload.map((operator) => ({
               label: operator.name,
               value: operator.code,
            }));
            return;
         case TransactionsActionTypes.GET_OPERATORS_ERROR:
            draft.filters.isLoading = false;
            return;
         case TransactionsActionTypes.GET_MERCHANTS_START:
            draft.filters.isLoading = true;
            return;
         case TransactionsActionTypes.GET_MERCHANTS_SUCCESS:
            draft.filters.isLoading = false;
            draft.filters.merchants = action.payload.map((merchant) => ({
               label: merchant.name,
               value: merchant.id,
            }));
            return;
         case TransactionsActionTypes.GET_MERCHANTS_ERROR:
            draft.filters.isLoading = false;
            return;
         case TransactionsActionTypes.GET_SERVICES_START:
            draft.filters.isLoading = true;
            return;
         case TransactionsActionTypes.GET_SERVICES_SUCCESS:
            draft.filters.isLoading = false;
            draft.filters.services = action.payload.map((service) => ({
               label: `${service.serviceName} - ${service.serviceKey}`,
               value: service.serviceKey,
            }));
            return;
         case TransactionsActionTypes.GET_SERVICES_ERROR:
            draft.filters.isLoading = false;
            return;

         case TransactionsActionTypes.TRANSACTIONS_PAGINATION_TOTAL_COUNT:
            draft.transactions.pagination.totalCount = action.payload;
            return;
         case TransactionsActionTypes.TRANSACTIONS_PAGINATION_UPDATE:
            draft.transactions.pagination.first = action.payload.first;
            draft.transactions.pagination.max = action.payload.max;
            return;
      }
   },
   transactionsState,
);

export default transactionsReducer;
