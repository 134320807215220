export enum SubscriptionsActionTypes {
   GET_SUBSCRIPTIONS_START = '@@subscriptions/GET_SUBSCRIPTIONS_START',
   GET_SUBSCRIPTIONS_SUCCESS = '@@subscriptions/GET_SUBSCRIPTIONS_SUCCESS',
   GET_SUBSCRIPTIONS_ERROR = '@@subscriptions/GET_SUBSCRIPTIONS_ERROR',

   UNSUBSCRIBE_START = '@@subscriptions/UNSUBSCRIBE_START',
   UNSUBSCRIBE_SUCCESS = '@@subscriptions/UNSUBSCRIBE_SUCCESS',
   UNSUBSCRIBE_ERROR = '@@subscriptions/UNSUBSCRIBE_ERROR',

   SUBSCRIPTIONS_PAGINATION_UPDATE = '@@subscriptions/SUBSCRIPTIONS_PAGINATION_UPDATE',
   SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT = '@@subscriptions/SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT',
}

type GetAllSubscriptionsStart = {
   type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_START;
};

type GetAllSubscriptionsSuccess = {
   type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS;
   payload: SubscriptionsType[];
};

type GetAllSubscriptionsError = {
   type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_ERROR;
};

type UnsubscribeStart = {
   type: SubscriptionsActionTypes.UNSUBSCRIBE_START;
};

type UnsubscribeSuccess = {
   type: SubscriptionsActionTypes.UNSUBSCRIBE_SUCCESS;
};

type UnsubscribeError = {
   type: SubscriptionsActionTypes.UNSUBSCRIBE_ERROR;
};

type SubscriptionsPaginationUpdate = {
   type: SubscriptionsActionTypes.SUBSCRIPTIONS_PAGINATION_UPDATE;
   payload: PaginationType;
};

type SubscriptionsPaginationTotalCount = {
   type: SubscriptionsActionTypes.SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT;
   payload: number;
};

export type SubscriptionsDispatchTypes =
   | GetAllSubscriptionsStart
   | GetAllSubscriptionsSuccess
   | GetAllSubscriptionsError
   | UnsubscribeStart
   | UnsubscribeSuccess
   | UnsubscribeError
   | SubscriptionsPaginationUpdate
   | SubscriptionsPaginationTotalCount;

export interface SubscriptionsState {
   subscriptions: {
      data: SubscriptionsType[];
      isLoading: boolean;
      pagination: SubscriptionsPagination;
   };
}

export interface SubscriptionsType {
   period: string;
   subscriber: string;
   companyName: string;
   dateSubscribeLocal: string;
   serviceName: string;
   operatorCode: string;
   countryImage: string;
   countryCode: string;
   price: number;
   currency: string;
   serviceKey: string;
   subscriptionId: number;
   dateUnsubscribeLocal: string;
   clientId: string;
}

export type PaginationType = {
   first: number;
   max: number;
};

export type SubscriptionsPagination = {
   totalCount: number;
   first: number;
   max: number;
};
