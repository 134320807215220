import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SUBSCRIPTIONS_HEADERS } from '../../../data/tableHeaders';
import { RootStore } from '../../../store/rootStore';
import {
   checkValue,
   FormatDate,
   FormatTime,
   generateFlagsForDropdowns,
} from '../../../utils/helpers';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import HeaderCell from '../../table/headerCell/HeaderCell';
import Table from '../../table/Table';
import TableCell from '../../table/tableCell/TableCell';
import TableData from '../../table/tableData/TableData';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import classes from './Subscriptions.module.scss';
import queryString from 'query-string';
import {
   GetAllSubscriptions,
   PaginationUpdate,
   Unsubscribe,
} from '../../../store/subscriptions/subscriptionsActions';
import Pagination from '../../pagination/Pagination';
import moment from 'moment';
import Button from '../../shared/button/Button';
import ConfirmModal from '../../modal/confirmModal/ConfirmModal';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';
import { CSVLink } from 'react-csv';
import { SubscriptionsType } from '../../../store/subscriptions/subscriptionsTypes';
import KeycloakService from '../../../auth/KeycloakService';
import API from '../../../api/CentiliService';
import { toast } from 'react-toastify';

interface Props {
   state: any;
   exportData: any;
   setExportData: (e: any) => void;
}

const SubscriptionsTable = ({ state, exportData, setExportData }: Props) => {
   const dispatch = useDispatch();
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const subscriptions: any = useSelector(
      (store: RootStore) => store.subscriptions.subscriptions.data,
   );
   const { isLoading, pagination } = useSelector(
      (store: RootStore) => store.subscriptions.subscriptions,
   );

   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);

   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

   const [isExportDataLoading, setIsExportDataLoading] = useState(false);
   const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
   const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });

   const parseInputStateHandler = () => {
      const params = {
         subscriptionIds: state.subscriptionId.trim() || undefined,
         serviceKeys: state.service.map((service: any) => service.value),
         accountIds: state.merchant.map((merchant: any) => merchant.value),
         /* operatorCodes: state.operator.map((operator: any) => operator.value), */
         countryCodes: state.country.map((country: any) => country.value),
         subscribers: state.subscriber.trim() || undefined,
         clientId: state.clientId.trim() || undefined,
         dateSubscribedLocalFrom: state.dateSubscribedLocal
            ? moment(state.dateSubscribedLocal[0]).utc(true).format()
            : undefined,
         dateSubscribedLocalTo: state.dateSubscribedLocal
            ? moment(state.dateSubscribedLocal[1]).utc(true).format()
            : undefined,
         dateUnsubscribedLocalFrom: state.dateUnsubscribedLocal
            ? moment(state.dateUnsubscribedLocal[0]).utc(true).format()
            : undefined,
         dateUnsubscribedLocalTo: state.dateUnsubscribedLocal
            ? moment(state.dateUnsubscribedLocal[1]).utc(true).format()
            : undefined,
         price: state.price || undefined,
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   const findCountryName = (countryCode: string) => {
      return countries.find((country) => country.value === countryCode)?.label;
   };

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   useEffect(() => {
      dispatch(
         GetAllSubscriptions(
            pagination.first,
            selectedRows[0].value,
            parseInputStateHandler(),
         ),
      );
   }, [page, selectedRows]);

   useEffect(() => {
      dispatch(GetAllSubscriptions(page.first, page.max, parseInputStateHandler()));
   }, []);

   const unsubscribeHandler = (id: string) => {
      dispatch(Unsubscribe(id));
   };

   const formatSubscriptionsForCSV = (subscriptions: SubscriptionsType[]) => {
      return subscriptions.map((subscription) => ({
         Subscriber: subscription.subscriber,
         'Subscription ID': subscription.subscriptionId,
         Merchant: subscription.companyName,
         'Client Id': subscription.clientId,
         Service: subscription.serviceName,
         Country: findCountryName(subscription.countryCode),
         Interval: subscription.period,
         Price: subscription.price,
         Currency: subscription.currency,
         'Date Subscribed': `${FormatDate(subscription.dateSubscribeLocal)} ${FormatTime(
            subscription.dateSubscribeLocal,
         )}`,
         'Date Unsubscribed': subscription.dateUnsubscribeLocal
            ? `${FormatDate(subscription.dateUnsubscribeLocal)} ${FormatTime(
                 subscription.dateUnsubscribeLocal,
              )}`
            : '-',
      }));
   };

   const getExportData = () => {
      const getData = async () => {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         setIsExportDataLoading(true);
         await API.subscriptions
            .getAllSubscriptionsForExport(
               0,
               pagination.totalCount < 10000 ? pagination.totalCount : 10000,
               parseInputStateHandler(),
               token || '',
            )
            .then((response) => {
               setExportData(formatSubscriptionsForCSV(response.data));
               setIsExportDataLoading(false);
               return csvRef?.current?.link.click();
            })
            .catch(() => {
               setIsExportDataLoading(false);
               toast.error('Error getting subscriptions.');
            });
      };

      getData();
   };
   return (
      <>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               {subscriptions.length === 0 ? (
                  <div className={classes.messageWrap}>
                     There are no results for this search.
                  </div>
               ) : (
                  <>
                     <Table>
                        <TableHeader>
                           <TableRow>
                              {SUBSCRIPTIONS_HEADERS.map((header, i) => (
                                 <HeaderCell key={i}>{header.text}</HeaderCell>
                              ))}
                           </TableRow>
                        </TableHeader>
                        <TableData>
                           {subscriptions.map((subscription: any, i: number) => (
                              <TableRow key={i}>
                                 <TableCell>{subscription.subscriber}</TableCell>
                                 <TableCell>{subscription.subscriptionId}</TableCell>
                                 <TableCell>{checkValue(subscription.companyName)}</TableCell>
                                 <TableCell>{checkValue(subscription.clientId)}</TableCell>
                                 <TableCell>{checkValue(subscription.serviceName)}</TableCell>
                                 <TableCell className={classes.flagCell}>
                                    {generateFlagsForDropdowns(
                                       findCountryName(subscription.countryCode) ||
                                          subscription.countryCode,
                                       subscription.countryCode,
                                    )}
                                 </TableCell>
                                 <TableCell>{subscription.period}</TableCell>
                                 <TableCell>{subscription.price}</TableCell>
                                 <TableCell>{checkValue(subscription.currency)}</TableCell>
                                 <TableCell>
                                    <div>
                                       <div>{FormatDate(subscription.dateSubscribeLocal)}</div>
                                       <div className={classes.lowOpacityTimeDate}>
                                          {FormatTime(subscription.dateSubscribeLocal)}
                                       </div>
                                    </div>
                                 </TableCell>
                                 <TableCell>
                                    <div>
                                       {subscription.dateUnsubscribeLocal ? (
                                          <>
                                             <div>
                                                {FormatDate(subscription.dateUnsubscribeLocal)}
                                             </div>
                                             <div className={classes.lowOpacityTimeDate}>
                                                {FormatTime(subscription.dateUnsubscribeLocal)}
                                             </div>
                                          </>
                                       ) : (
                                          <>
                                             {actionsUser.includes(
                                                UserRoleActions.UNSUBSCRIBE_VIEW_ACTION,
                                             ) ? (
                                                <Button
                                                   type="blue"
                                                   text="Unsubscribe"
                                                   onClick={() => {
                                                      setSelectedSubscriptionId(
                                                         subscription.subscriptionId,
                                                      );
                                                      setIsConfirmModalOpen(true);
                                                   }}
                                                />
                                             ) : (
                                                '-'
                                             )}
                                          </>
                                       )}
                                    </div>
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableData>
                     </Table>

                     <Pagination
                        first={pagination.first}
                        max={pagination.max}
                        curMax={selectedRows[0].value}
                        isSearching={isLoading}
                        rowValue={selectedRows}
                        setPage={setPage}
                        setSelectedRows={setSelectedRows}
                        totalCount={pagination.totalCount}
                        totalPages={totalPageState}
                        dispatchAction={PaginationUpdate}
                        isServerSide={true}
                        onRowChange={(rowsPerPage: number) => {
                           dispatch(PaginationUpdate({ first: 0, max: rowsPerPage }));
                        }}
                     />
                     {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                        <>
                           {exportData.length === 0 ? (
                              <a download>
                                 <Button
                                    className="export-csv-btn"
                                    type={'template'}
                                    text={'Export as CSV'}
                                    onClick={getExportData}
                                    showLoader={isExportDataLoading}
                                 />
                              </a>
                           ) : (
                              <CSVLink
                                 filename={'subscriptions.csv'}
                                 data={exportData}
                                 ref={csvRef}
                                 onClick={() => {
                                    if (csvRef?.current) {
                                       csvRef.current.link.download = `subscriptions-${Date.now()}`;
                                    }
                                 }}
                              >
                                 <Button
                                    className="export-csv-btn"
                                    type={'blue'}
                                    text="Export as CSV"
                                    hideTooltip={true}
                                 />
                              </CSVLink>
                           )}
                        </>
                     )}

                     <ConfirmModal
                        isOpen={isConfirmModalOpen}
                        onRequestClose={() => setIsConfirmModalOpen(false)}
                        onCancel={() => {
                           setIsConfirmModalOpen(false);
                           setSelectedSubscriptionId('');
                        }}
                        onConfirm={() => {
                           unsubscribeHandler(selectedSubscriptionId);
                           setSelectedSubscriptionId('');
                           setIsConfirmModalOpen(false);
                        }}
                        text={`Are you sure that you want to unsubscribe subscription ${selectedSubscriptionId}?`}
                     />
                  </>
               )}
            </>
         )}
      </>
   );
};

export default SubscriptionsTable;
