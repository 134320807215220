import { motion } from 'framer-motion';
import React from 'react';
import classes from './Table.module.scss';
interface TableProp {
   children: React.ReactElement | React.ReactElement[];
   className?: string;

   /* Main Table component. */
}
const Table = ({ children, className }: TableProp) => {
   return (
      <div className={classes.tableWrap}>
         <motion.table className={`${classes.mainTable} ${className}`}>
            {children}
         </motion.table>
      </div>
   );
};

export default Table;
