import React from 'react';
import classes from './Footer.module.scss';

const Footer = () => {
   return (
      <footer id={'footer'} className={classes.footer}>
         <div className={classes.footerLeft}>
            <h4>Partner Panel</h4>
         </div>
         <div className={classes.footerRight}>
            <h4> {`Centili ${new Date().getFullYear()} | All Rights Reserved`}</h4>
         </div>
      </footer>
   );
};

export default Footer;
