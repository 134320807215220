import cx from 'classnames';
import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ReactComponent as NextLastArrow } from '../../assets/images/svg/next-last.svg';
import { ReactComponent as NextArrow } from '../../assets/images/svg/next.svg';
import { ReactComponent as PreviousLastArrow } from '../../assets/images/svg/previous-last.svg';
import { ReactComponent as PreviousArrow } from '../../assets/images/svg/previous.svg';
import { cancelDropdown } from '../../utils/cancelDropdown';
import classes from './Pagination.module.scss';

interface RowsProp {
   label: string;
   value: number;
}
type PaginationData = {
   first: number;
   max: number;
};

interface PaginationProp {
   ycoin?: boolean;
   isServerSide?: boolean;
   isSearching: boolean;
   first: number;
   max: number;
   curMax: number;
   totalCount: number;
   totalCountYcoinResult?: number;
   totalPages: number;
   rowValue: RowsProp[];
   numOfRows?: number;
   isNextDisabled?: boolean;
   setSelectedRows: (row: RowsProp[]) => void;
   onRowChange?: (max: number) => void;
   dispatchAction?: (page: PaginationData) => (dispatch: Dispatch<any>) => void;
   setPage: (page: PaginationData) => void;
}

/* 
Main Pagination component.
Includes option for server-side and client-side rendering.
*/

const PaginationComponent = ({
   ycoin = false,
   isServerSide = true,
   first,
   max,
   curMax,
   totalCount,
   totalCountYcoinResult,
   totalPages,
   rowValue,
   setSelectedRows,
   isSearching,
   onRowChange,
   dispatchAction,
   setPage,
}: PaginationProp) => {
   const dispatch = useDispatch();

   const rowsPerPage = [
      { label: '8', value: 8 },
      { label: '15', value: 15 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '200', value: 200 },
      { label: '500', value: 500 },
      { label: '1000', value: 1000 },
   ];
   const rowsPerPageYcoin = [
      { label: '5', value: 5 },
      { label: '20', value: 20 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
   ];

   const nextPageHandler = () => {
      // window.scroll(0, 0);
      if (isServerSide) {
         //Set local state(causing re-render of component and calling the main search with useEffect)
         setPage({
            first: first + max,
            max: curMax,
         });
         //Update state with first max
         dispatch(
            dispatchAction!({
               first: first + max,
               max: curMax,
            }),
         );
      } else {
         setPage({
            first: first + curMax,
            max: max + curMax,
         });
      }
   };

   const lastPageHandler = () => {
      if (isServerSide) {
         const newFirst = (totalPages - 1) * max;
         dispatch(
            dispatchAction!({
               first: newFirst,
               max: curMax,
            }),
         );
         setPage({
            first: newFirst,
            max: curMax,
         });
      }
      //Client side pagination
      else {
         const newFirst = (totalPages - 1) * curMax;
         setPage({
            first: newFirst,
            max: newFirst + curMax,
         });
      }
   };

   const previousPageHandler = () => {
      if (!first) return; //if zero, return

      if (isServerSide) {
         dispatch(
            dispatchAction!({
               first: first - max,
               max: curMax,
            }),
         );
         setPage({
            first: first - max,
            max: curMax,
         });
      } else {
         //Client side
         setPage({
            first: first - curMax,
            max: max - curMax,
         });
      }
   };

   const firstPageHandler = () => {
      if (!first) return;

      if (isServerSide) {
         dispatch(
            dispatchAction!({
               first: 0,
               max: curMax,
            }),
         );
         setPage({
            first: 0,
            max: curMax,
         });
      }

      setPage({
         first: 0,
         max: curMax,
      });
   };

   return (
      <>
         {totalCount > rowsPerPage[0].value && (
            <div className={classes.paginationWrap}>
               <div className={cx(classes.pagination, { [classes.searching]: isSearching })}>
                  {/* Rows per page */}
                  <div className={classes.rowsSelect}>
                     <h5>Rows per page</h5>

                     {/* Compare with language  */}
                     <MultiSelect
                        className={`paginationSelect ${ycoin && 'ycoin'} two`}
                        overrideStrings={{
                           selectSomeItems: 'Compare',
                        }}
                        disableSearch={true}
                        hasSelectAll={false}
                        options={ycoin ? rowsPerPageYcoin : rowsPerPage}
                        value={rowValue}
                        labelledBy={'Select'}
                        onChange={(rowsArray: RowsProp[] | []) => {
                           /* 
                           If user click on the already selected element, it will return empty array.
                           If not, it will return object value(e.g {labeL:'2',value:2})
                              */
                           const newValue: RowsProp | RowsProp[] | [] =
                              rowsArray[rowsArray.length - 1] || rowsArray;

                           //If newValue is empty array, exit
                           if (Array.isArray(newValue) && newValue.length === 0) return;

                           //PROP-update Redux store with max param
                           if (isServerSide) {
                              onRowChange!(newValue.value);
                           }

                           //PROP-Setting state with new object (new max value)
                           setSelectedRows([newValue]);

                           //Close dropdown when option is selected(pass className second value)
                           cancelDropdown('paginationSelect');
                        }}
                     />
                  </div>
                  {/* Page count  */}
                  {!ycoin && (
                     <div className={classes.pageCount}>
                        {isServerSide ? (
                           <h5>{`${totalCount !== 0 ? first + 1 : 0} - ${
                              first + max < totalCount ? first + max : totalCount
                           } of ${totalCount}`}</h5>
                        ) : (
                           <h5>{`${first + 1} - ${
                              max > totalCount ? totalCount : max
                           } of ${totalCount}`}</h5>
                        )}
                     </div>
                  )}

                  {/* Controls   */}
                  <div className={classes.controls}>
                     <PreviousLastArrow
                        className={
                           isServerSide
                              ? cx(classes.previousLast, {
                                   [classes.disabled]: first === 0,
                                })
                              : cx(classes.previousLast, {
                                   [classes.disabled]: first === 0,
                                })
                        }
                        onClick={firstPageHandler}
                     />
                     <PreviousArrow
                        className={cx(classes.previous, {
                           [classes.disabled]: first === 0,
                        })}
                        onClick={previousPageHandler}
                     />
                     <NextArrow
                        className={
                           ycoin
                              ? cx(classes.next, {
                                   [classes.disabled]: totalCountYcoinResult! < max,
                                })
                              : isServerSide
                              ? cx(classes.next, {
                                   [classes.disabled]: first + max >= totalCount,
                                })
                              : cx(classes.next, {
                                   [classes.disabled]: first + curMax >= totalCount, //client side pagination
                                })
                        }
                        onClick={nextPageHandler}
                     />
                     {!ycoin && (
                        <NextLastArrow
                           className={
                              isServerSide
                                 ? cx(classes.nextLast, {
                                      [classes.disabled]: first + max >= totalCount,
                                   })
                                 : cx(classes.nextLast, {
                                      [classes.disabled]: first + curMax >= totalCount,
                                   })
                           }
                           onClick={lastPageHandler}
                        />
                     )}
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

const InfinitePaginationComponent = ({
   first,
   max,
   curMax,
   rowValue,
   setSelectedRows,
   isSearching,
   numOfRows = 0,
   onRowChange,
   dispatchAction,
   setPage,
   isNextDisabled,
}: PaginationProp) => {
   const dispatch = useDispatch();

   const rowsPerPage = [
      { label: '8', value: 8 },
      { label: '15', value: 15 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '200', value: 200 },
      { label: '500', value: 500 },
      { label: '1000', value: 1000 },
   ];
   const nextPageHandler = () => {
      dispatch(
         dispatchAction!({
            first: first + curMax,
            max: max,
         }),
      );
      setPage({
         first: first + curMax,
         max: max,
      });
   };

   const previousPageHandler = () => {
      if (!first) return;

      dispatch(
         dispatchAction!({
            first: first - curMax,
            max: max,
         }),
      );
      setPage({
         first: first - curMax,
         max: max,
      });
   };

   return (
      <div className={classes.paginationWrap}>
         <div
            className={cx(classes.pagination, {
               [classes.searching]: isSearching,
            })}
         >
            <div className={classes.rowsSelect}>
               <h5>Rows per page</h5>

               <MultiSelect
                  className={`paginationSelect two`}
                  overrideStrings={{
                     selectSomeItems: 'Compare',
                  }}
                  disableSearch={true}
                  hasSelectAll={false}
                  options={rowsPerPage}
                  value={rowValue}
                  labelledBy={'Select'}
                  onChange={(e: any) => {
                     const newValue = e[e.length - 1] || e;

                     if (Array.isArray(newValue) && newValue.length === 0) return;

                     setPage({ first: 0, max: newValue.value });
                     onRowChange!(newValue.value);
                     setSelectedRows([newValue]);

                     cancelDropdown('paginationSelect');
                  }}
               />
            </div>
            <div className={classes.pageCount}>
               <h5>{`${first + 1} - ${
                  rowValue[0].value > numOfRows ? first + numOfRows : first + rowValue[0].value
               }`}</h5>
            </div>

            <div className={classes.controls}>
               <PreviousArrow
                  className={cx(classes.previous, {
                     [classes.disabled]: first === 0,
                  })}
                  onClick={previousPageHandler}
               />
               <NextArrow
                  className={cx(classes.next, {
                     [classes.disabled]: numOfRows < curMax || isNextDisabled,
                  })}
                  onClick={nextPageHandler}
               />
            </div>
         </div>
      </div>
   );
};

const Pagination = (paginationProps: PaginationProp) => {
   return paginationProps.totalCount === -1 ? (
      <InfinitePaginationComponent {...paginationProps} />
   ) : (
      <PaginationComponent {...paginationProps} />
   );
};

export default Pagination;
