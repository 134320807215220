import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes, PAGE_NAVIGATION_ROUTES } from '../../../../constants/routes/routes';
import ErrorPage from '../../../../pages/ErrorPage';
import ForbiddenPage from '../../../../pages/ForbiddenPage';
import NotFoundPage from '../../../../pages/NotFoundPage';
import { RootStore } from '../../../../store/rootStore';
import PrivateRoute from './../../PrivateRoute';
import RestrictedRoute from './../../RestrictedRoute';
import classes from './LandingRoutes.module.scss';

/* 
Main Application routes.
Contains all the Private and Restricted routes
*/

const LandingRoutes = () => {
   // App state
   const isAuthenticatedKeycloak = useSelector(
      (state: RootStore) => state.auth.keycloak.isAuthenticated,
   );

   //VAS actions
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);
   const edActions = actionsUser?.filter((action) => action.startsWith('view.ed'));
   const actionRoutes = {
      'view.ed.transactions': Routes.TRANSACTIONS_ROUTE,
      'view.ed.subscriptions': Routes.SUBSCRIPTIONS_ROUTE,
      'view.ed.services': Routes.SERVICES_ROUTE,
      'view.ed.balance': Routes.BALANCE_ROUTE,
      'view.ed.revenue': Routes.REVENUE_ROUTE,
      'view.ed.sodexo.analytics': Routes.ANALYTICS,
      'view.ed.users': Routes.USERS_ROUTE,
   };

   const findDefaultRoute = () => {
      const defaultAction = Object.keys(actionRoutes).find((key) => edActions?.includes(key));
      return defaultAction ? actionRoutes[defaultAction] : Routes.FORBIDDEN_ROUTE;
   };

   return (
      <div className={classes.mainContainer}>
         <AnimatePresence exitBeforeEnter>
            {/* <Switch location={location} key={location.pathname}> */}
            <Switch>
               <Route path={Routes.FORBIDDEN_ROUTE} component={ForbiddenPage} />
               <Redirect exact from={Routes.HOME_ROUTE} to={findDefaultRoute()} />

               {PAGE_NAVIGATION_ROUTES.map((routes, i) =>
                  routes.restricted ? (
                     <RestrictedRoute
                        key={i}
                        isAuthenticated={isAuthenticatedKeycloak}
                        actions={actionsUser}
                        roles={routes.role ?? ''}
                        component={routes.component}
                        path={routes.path}
                     />
                  ) : (
                     <PrivateRoute
                        key={i}
                        isAuthenticated={isAuthenticatedKeycloak}
                        component={routes.component}
                        path={routes.path}
                     />
                  ),
               )}

               <Route exact path={Routes.ERROR_ROUTE} component={ErrorPage} />
               <Route component={NotFoundPage} />
            </Switch>
         </AnimatePresence>
      </div>
   );
};

export default LandingRoutes;
