import React from 'react';
import Loader from 'react-loader-spinner';
import { Redirect, Route, RouteProps } from 'react-router';
import { Routes } from '../../constants/routes/routes';

interface RestrictedRouteProps extends RouteProps {
   component: any;
   isAuthenticated: boolean | undefined | null;
   actions: string[];
   roles: string;
}
/* 
Restricted route Component
Load page if user has certain role.
*/

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
   component: Component,
   isAuthenticated,
   roles,
   actions,
   ...rest
}) => {
   return (
      <>
         <Route
            {...rest}
            render={(routeProps) =>
               //If user tries to login
               isAuthenticated === null ? (
                  <Loader type="Puff" color="#00BFFF" height={100} width={100} />
               ) : //If user is authenticated
               isAuthenticated && actions.includes(roles) ? (
                  <Component {...routeProps} />
               ) : (
                  <Redirect
                     to={{
                        pathname: Routes.FORBIDDEN_ROUTE,
                        state: {
                           from: routeProps.location,
                        },
                     }}
                  />
               )
            }
         />
      </>
   );
};

export default RestrictedRoute;
