import React from 'react';
import { ModalProp } from '../../../constants/types/modal/types';
import Modal from 'react-modal';
import ModalHeader from '../modalHeader/ModalHeader';
import classes from './ConfirmModal.module.scss';
import Button from '../../shared/button/Button';

interface Props {
   text: string;
   onConfirm: () => void;
   onCancel: () => void;
   headerText?: string;
}

type ConfirmProps = Props & ModalProp;

const ConfirmModal = ({
   isOpen,
   onRequestClose,
   text,
   onConfirm,
   onCancel,
   headerText = 'Confirmation',
}: ConfirmProps) => {
   return (
      <Modal
         isOpen={isOpen}
         onRequestClose={onRequestClose}
         closeTimeoutMS={100}
         style={{
            overlay: {
               backgroundColor: '#00000080',
               zIndex: 1000,
            },
         }}
         className={`modal`}
         contentLabel="Confirmation modal"
         shouldCloseOnEsc={false}
         shouldCloseOnOverlayClick={false}
      >
         <ModalHeader text={headerText} onRequestClose={onRequestClose} />
         <div className={classes.modalWrap}>
            {text}
            <div className={classes.buttonWrap}>
               <Button text={'Cancel'} type={'clear'} onClick={onCancel} />
               <Button text={'Yes'} type={'primary'} onClick={onConfirm} />
            </div>
         </div>
      </Modal>
   );
};

export default ConfirmModal;
