/* State, API and action types for Auth actions */

// 1.ACTION TYPES
//Actions constants
export enum AuthActionTypes {
   AUTH_START = '@@auth/AUTH_START',
   AUTH_SUCCESS = '@@auth/AUTH_SUCCESS',
   AUTH_ERROR = '@@auth/AUTH_ERROR',
}

//Actions constant types
type AuthStart = {
   type: AuthActionTypes.AUTH_START;
};
type AuthSuccess = {
   type: AuthActionTypes.AUTH_SUCCESS;
   payload: {
      keycloak: KeycloakType;
      user: UserType;
   };
};
type AuthError = {
   type: AuthActionTypes.AUTH_ERROR;
   payload: Error;
};

//Actions dispatch types
export type AuthDispatchTypes = AuthStart | AuthSuccess | AuthError;

// 2.API RESPONSE TYPES
//Keycloak API types
type KeycloakRoles = {
   roles: string[] | undefined;
};
type KeycloakUserName = {
   preferred_username: string;
   name: string;
};

//Define main Keycloak response type (for action payload)
export type KeycloakType = {
   authenticated?: boolean | undefined;
   clientId?: string | undefined;
   realm?: string;
   realmAccess?: KeycloakRoles;
   tokenParsed?: KeycloakUserName;
};

//User API types
//User types
type UserTypeAction = {
   code: string;
};
type UserType = {
   userTypes: string[];
   // actions: string[];
   actions: UserTypeAction[];
};

// 3.STATE TYPES
//State types for keycloak state object
type AuthStateKeycloak = {
   readonly isAuthenticated: boolean | undefined | null;
   readonly realm?: string;
   readonly clientId?: string;
   readonly roles?: string[];
   readonly username?: string;
   readonly name?: string;
};

//State types for user state object
type AuthStateUser = {
   readonly userTypes: string[];
   readonly actions?: string[];
};
//Main auth state
export interface AuthState {
   keycloak: AuthStateKeycloak;
   user?: AuthStateUser;
   error?: Error;
}
