import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { UsersActionTypes, UsersDispatchTypes, UsersState } from './usersTypes';

const usersState: UsersState = {
   users: {
      data: [],
      isLoading: false,
      pagination: {
         first: 0,
         max: 8,
         totalCount: 8,
      },
   },
   organizationalUnits: {
      data: [],
      isLoading: false,
   },
   userTransactions: {
      data: [],
      isLoading: false,
      bundleParts: null,
      isBundlePartsLoading: false,
      pagination: {
         first: 0,
         max: 8,
         totalCount: 8,
      },
   },
   userSubscriptions: {
      data: [],
      isLoading: false,
      pagination: {
         first: 0,
         max: 8,
         totalCount: 8,
      },
   },
};

const usersReducer: Reducer<UsersState> = produce(
   (draft: Draft<UsersState>, action: UsersDispatchTypes) => {
      switch (action.type) {
         case UsersActionTypes.GET_USERS_START:
            draft.users.isLoading = true;
            return;
         case UsersActionTypes.GET_USERS_SUCCESS:
            draft.users.isLoading = false;
            draft.users.data = action.payload;
            return;
         case UsersActionTypes.GET_USERS_ERROR:
            draft.users.isLoading = false;
            return;
         case UsersActionTypes.GET_USERS_TRANSACTIONS_START:
            draft.userTransactions.isLoading = true;
            return;
         case UsersActionTypes.GET_USERS_TRANSACTIONS_SUCCESS:
            draft.userTransactions.isLoading = false;
            draft.userTransactions.data = action.payload;
            return;
         case UsersActionTypes.GET_USERS_TRANSACTIONS_ERROR:
            draft.userTransactions.isLoading = false;
            return;
         case UsersActionTypes.GET_USERS_SUBSCRIPTIONS_START:
            draft.userSubscriptions.isLoading = true;
            return;
         case UsersActionTypes.GET_USERS_SUBSCRIPTIONS_SUCCESS:
            draft.userSubscriptions.isLoading = false;
            draft.userSubscriptions.data = action.payload;
            return;
         case UsersActionTypes.GET_USERS_SUBSCRIPTIONS_ERROR:
            draft.userSubscriptions.isLoading = false;
            return;

         case UsersActionTypes.GET_ORGANIZATIONAL_UNITS_START:
            draft.organizationalUnits.isLoading = true;
            return;
         case UsersActionTypes.GET_ORGANIZATIONAL_UNITS_SUCCESS:
            draft.organizationalUnits.isLoading = false;
            draft.organizationalUnits.data = action.payload.map((unit) => ({
               label: unit,
               value: unit,
            }));
            return;
         case UsersActionTypes.GET_ORGANIZATIONAL_UNITS_ERROR:
            draft.organizationalUnits.isLoading = false;
            return;
         case UsersActionTypes.GET_BUNDLE_PARTS_START:
            draft.userTransactions.isBundlePartsLoading = true;
            return;
         case UsersActionTypes.GET_BUNDLE_PARTS_SUCCESS:
            draft.userTransactions.isBundlePartsLoading = false;
            draft.userTransactions.bundleParts = {
               ...draft.userTransactions.bundleParts,

               [action.payload.transactionId]: action.payload.bundleParts,
            };
            return;
         case UsersActionTypes.GET_BUNDLE_PARTS_ERROR:
            draft.userTransactions.isBundlePartsLoading = false;
            return;
         case UsersActionTypes.USERS_PAGINATION_TOTAL_COUNT:
            draft.users.pagination.totalCount = action.payload;
            return;
         case UsersActionTypes.USERS_PAGINATION_UPDATE:
            draft.users.pagination.first = action.payload.first;
            draft.users.pagination.max = action.payload.max;
            return;
         case UsersActionTypes.USERS_TRANSACTIONS_PAGINATION_TOTAL_COUNT:
            draft.userTransactions.pagination.totalCount = action.payload;
            return;
         case UsersActionTypes.USERS_TRANSACTIONS_PAGINATION_UPDATE:
            draft.userTransactions.pagination.first = action.payload.first;
            draft.userTransactions.pagination.max = action.payload.max;
            return;
         case UsersActionTypes.USERS_SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT:
            draft.userSubscriptions.pagination.totalCount = action.payload;
            return;
         case UsersActionTypes.USERS_SUBSCRIPTIONS_PAGINATION_UPDATE:
            draft.userSubscriptions.pagination.first = action.payload.first;
            draft.userSubscriptions.pagination.max = action.payload.max;
            return;
      }
   },
   usersState,
);

export default usersReducer;
