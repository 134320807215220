import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootStore } from '../../store/rootStore';
import classes from './Header.module.scss';
import TopMenu from './topMenu/TopMenu';

const Header = () => {
   const location = useLocation();

   //App state
   const userName = useSelector((state: RootStore) => state.auth.keycloak.username);
   const fullName = useSelector((state: RootStore) => state.auth.keycloak.name);
   //const cardName = useSelector((state: RootStore) => state.auth.keycloak.name);

   let breadcrumb;

   switch (location.pathname) {
      case '/transactions':
         breadcrumb = 'Transactions';
         break;
      case '/subscriptions':
         breadcrumb = 'Subscriptions';
         break;
      case '/services':
         breadcrumb = 'Services';
         break;
      case '/service-details':
         breadcrumb = 'Service Details';
         break;
      case '/balance':
         breadcrumb = 'Balance';
         break;
      case '/revenue':
         breadcrumb = 'Revenue';
         break;
      case '/subscribers':
         breadcrumb = 'Subscribers';
         break;
      case '/analytics':
         breadcrumb = 'Analytics';
         break;
      case '/forbidden':
         breadcrumb = 'Forbidden - 403';
         break;
      case '/users':
         breadcrumb = 'Users Management';
         break;
      case '/user-details':
         breadcrumb = 'User Details';
         break;
      case '/error':
         breadcrumb = 'Error - 500';
         break;
      default:
         breadcrumb = 'Page not found - 404';
   }
   return (
      <div className={classes.header}>
         <div className={classes.topNavLabel}>
            <h1>{breadcrumb}</h1>
         </div>
         <TopMenu username={userName} fullName={fullName} />
      </div>
   );
};

export default Header;
