import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USERS_PAGE_HEADER } from '../../../data/tableHeaders';
import { RootStore } from '../../../store/rootStore';
import { checkValue } from '../../../utils/helpers';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import HeaderCell from '../../table/headerCell/HeaderCell';
import Table from '../../table/Table';
import TableCell from '../../table/tableCell/TableCell';
import TableData from '../../table/tableData/TableData';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import classes from './Users.module.scss';
import {
   GetAllUsers,
   GetOrganizationalUnits,
   UsersPaginationUpdate,
} from '../../../store/users/usersActions';
import { UserType } from '../../../store/users/usersTypes';
import { ReactComponent as EditIcon } from '../../../assets/images/svg/edit.svg';
import { Routes } from '../../../constants/routes/routes';
import history from '../../../utils/history';
import queryString from 'query-string';
import Pagination from '../../pagination/Pagination';
import { CSVLink } from 'react-csv';
import Button from '../../shared/button/Button';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';
import API from '../../../api/CentiliService';
import KeycloakService from '../../../auth/KeycloakService';
import { toast } from 'react-toastify';

interface Props {
   state: any;
   exportData: any;
   setExportData: (e: any) => void;
}

const UsersTable = ({ state, exportData, setExportData }: Props) => {
   const dispatch = useDispatch();

   const users = useSelector((store: RootStore) => store.users.users.data);
   const isLoading = useSelector((store: RootStore) => store.users.users.isLoading);
   const pagination = useSelector((store: RootStore) => store.users.users.pagination);
   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const [isExportDataLoading, setIsExportDataLoading] = useState(false);
   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });
   const parseInputStateHandler = () => {
      const params = {
         keycloakId: state.keycloakId.trim() || undefined,
         username: state.username.trim() || undefined,
         email: state.email.trim() || undefined,
         lastName: state.lastName.trim() || undefined,
         firstName: state.firstName.trim() || undefined,
         organizationalUnit: state.organizationalUnit.map((unit: any) => unit.value),
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   useEffect(() => {
      dispatch(GetAllUsers(page.first, page.max, parseInputStateHandler()));
      dispatch(GetOrganizationalUnits());
   }, []);

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   useEffect(() => {
      dispatch(GetAllUsers(pagination.first, selectedRows[0].value, parseInputStateHandler()));
   }, [page, selectedRows]);

   const formatUsersForCSV = (users: UserType[]) => {
      return users.map((user) => ({
         'User ID': user?.id,
         Username: user?.username,
         Email: user?.email,
         'First name': user?.firstName,
         'Last name': user?.lastName,
         'Organizational unit': user?.companyOrgUnit,
      }));
   };

   const getExportData = () => {
      const getData = async () => {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         setIsExportDataLoading(true);
         await API.users
            .getUsersForExport(
               0,
               pagination.totalCount < 10000 ? pagination.totalCount : 10000,
               parseInputStateHandler(),
               token || '',
            )
            .then((response) => {
               setExportData(formatUsersForCSV(response.data));
               setIsExportDataLoading(false);
               return csvRef?.current?.link.click();
            })
            .catch(() => {
               setIsExportDataLoading(false);
               toast.error('Error getting users.');
            });
      };

      getData();
   };
   return (
      <>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               {users.length === 0 ? (
                  <div className={classes.messageWrap}>
                     There are no results for this search.
                  </div>
               ) : (
                  <>
                     <Table>
                        <TableHeader>
                           <TableRow>
                              {USERS_PAGE_HEADER.map((header, i) => (
                                 <HeaderCell key={i}>{header.text}</HeaderCell>
                              ))}
                           </TableRow>
                        </TableHeader>
                        <TableData>
                           {users.map((user: UserType, i: number) => (
                              <TableRow key={i}>
                                 <TableCell>{user.id}</TableCell>
                                 <TableCell>{user.username}</TableCell>
                                 <TableCell>{checkValue(user.email)}</TableCell>
                                 <TableCell>{checkValue(user.firstName)}</TableCell>
                                 <TableCell>{checkValue(user.lastName)}</TableCell>
                                 <TableCell>{checkValue(user.companyOrgUnit)}</TableCell>
                                 <TableCell>
                                    <div className={classes.iconsContainer}>
                                       <EditIcon
                                          className={classes.editIcon}
                                          onClick={() =>
                                             history.push({
                                                pathname: Routes.USERS_DETAILS_ROUTE,
                                                state: { ...user, keycloakId: user?.id },
                                             })
                                          }
                                       />
                                    </div>
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableData>
                     </Table>
                     <Pagination
                        first={pagination.first}
                        max={pagination.max}
                        curMax={selectedRows[0].value}
                        isSearching={isLoading}
                        rowValue={selectedRows}
                        setPage={setPage}
                        setSelectedRows={setSelectedRows}
                        totalCount={pagination.totalCount}
                        totalPages={totalPageState}
                        dispatchAction={UsersPaginationUpdate}
                        isServerSide={true}
                        onRowChange={(rowsPerPage: number) => {
                           dispatch(
                              UsersPaginationUpdate({
                                 first: 0,
                                 max: rowsPerPage,
                              }),
                           );
                        }}
                     />
                     {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                        <>
                           {exportData.length === 0 ? (
                              <a download>
                                 <Button
                                    className="export-csv-btn"
                                    type={'template'}
                                    text={'Export as CSV'}
                                    onClick={getExportData}
                                    showLoader={isExportDataLoading}
                                 />
                              </a>
                           ) : (
                              <CSVLink
                                 filename={'users.csv'}
                                 ref={csvRef}
                                 data={exportData}
                                 onClick={() => {
                                    if (csvRef?.current) {
                                       csvRef.current.link.download = `users-${Date.now()}`;
                                    }
                                 }}
                              >
                                 <Button
                                    className="export-csv-btn"
                                    type={'blue'}
                                    text="Export as CSV"
                                    hideTooltip={true}
                                 />
                              </CSVLink>
                           )}
                        </>
                     )}
                  </>
               )}
            </>
         )}
      </>
   );
};

export default UsersTable;
