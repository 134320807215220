import { OptionType } from '../constants/types/inputs/multiselect/types';

export const TRANSACTIONS_STATUS_OPTIONS: OptionType[] = [
   { label: 'Completed', value: 'COMPLETED' },
   { label: 'Failed', value: 'FAILED' },
   { label: 'Canceled', value: 'CANCELED' },
];

export const TRANSACTIONS_TYPE_OPTIONS: OptionType[] = [
   { label: 'CHARGE', value: 'CHARGE' },
   { label: 'SUB_START', value: 'SUB_START' },
   { label: 'SUB_RENEW', value: 'SUB_RENEW' },
   { label: 'SUB_CANCEL', value: 'SUB_CANCEL' },
   { label: 'SUB_ADMIN_CANCEL', value: 'SUB_ADMIN_CANCEL' },
   { label: 'TOP_UP', value: 'WALLET_CHARGE' },
   { label: 'BUNDLE', value: 'BUNDLE_CHARGE' },
   { label: 'REFUND', value: 'REFUND' },
];

export const REVENUE_GROUP_BY_OPTIONS: OptionType[] = [
   { label: 'By day', value: 'DAY' },
   { label: 'By week', value: 'WEEK' },
   { label: 'By month', value: 'MONTH' },
   { label: 'By country', value: 'COUNTRY' },
];

export const PAYMENT_METHOD_OPTIONS: OptionType[] = [
   { label: 'Mobile', value: 'MOBILE' },
   { label: 'Wallet', value: 'WALLET' },
   { label: 'Credit card', value: 'CC' },
   { label: 'Scratch card', value: 'SCRATCH' },
];

export const SERVICE_STATUS_OPTIONS: OptionType[] = [
   { label: 'Activated', value: true },
   { label: 'Not activated', value: false },
];

export const SERVICE_TYPE_OPTIONS: OptionType[] = [
   { label: 'One-time', value: 'ONE_TIME' },
   { label: 'Subscription', value: 'SUBSCRIPTION' },
];

export const SUBSCRIBER_BLACKLIST_STATUS_OPTIONS: OptionType[] = [
   { label: 'All subscribers', value: 'ALL' },
   { label: 'Blacklisted subscribers', value: 'YES' },
];

export const PACKAGES_INTERVAL_OPTIONS = [
   { label: 'HOUR', value: 'HOUR' },
   { label: 'DAY', value: 'DAY' },
   { label: 'WEEK', value: 'WEEK' },
   { label: 'MONTH', value: 'MONTH' },
   { label: 'YEAR', value: 'YEAR' },
   { label: 'ANY', value: 'ANY' },
];

export const PAYMENT_METHOD_TYPE_OPTIONS = [
   { label: 'MOBILE', value: 'MOBILE' },
   { label: 'WALLET', value: 'WALLET' },
   { label: 'CC', value: 'CC' },
   { label: 'SCRATCH', value: 'SCRATCH' },
];
