import axios, { exportBaseUrl } from './axiosConfig';

export default {
   auth: {
      getUserRoles: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`external-dashboard-api/v1/user`, {
            headers: headers,
         });
      },
   },
   transactions: {
      getAllTransactions: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/transactions?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getAllTransactionsForExport: (
         first: number,
         max: number,
         filters: string,
         token: string,
      ) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `${exportBaseUrl}external-dashboard-api/v1/transactions?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getBundleParts: (transactionId: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/transactions/cc/${transactionId}/bundle-parts`,
            { headers: headers },
         );
      },
      getCountries: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get('/external-dashboard-api/v1/search-filters/countries', {
            headers: headers,
         });
      },
      getOperators: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get('/external-dashboard-api/v1/search-filters/operators', {
            headers: headers,
         });
      },
      getServices: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get('/external-dashboard-api/v1/search-filters/services', {
            headers: headers,
         });
      },

      getMerchants: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get('/external-dashboard-api/v1/search-filters/merchants', {
            headers: headers,
         });
      },
      resendContent: (transactionId: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post(
            `/external-dashboard-api/v1/transactions/${transactionId}/resend-content-sms`,
            null,
            {
               headers: headers,
            },
         );
      },
      refund: (transactionId: string, reason: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post(
            `/external-dashboard-api/v1/transactions/${transactionId}/refund`,
            { reason },
            {
               headers: headers,
            },
         );
      },
   },
   subscriptions: {
      getAllSubscriptions: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/subscriptions?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getAllSubscriptionsForExport: (
         first: number,
         max: number,
         filters: string,
         token: string,
      ) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `${exportBaseUrl}external-dashboard-api/v1/subscriptions?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      unsubscribe: (subscriptionId: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post(
            `/external-dashboard-api/v1/subscriptions/unsubscribe/${subscriptionId}`,
            null,
            {
               headers: headers,
            },
         );
      },
   },
   services: {
      getAllServices: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/services?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getAllServicesForExport: (
         first: number,
         max: number,
         filters: string,
         token: string,
      ) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `${exportBaseUrl}external-dashboard-api/v1/services?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getServicePackages: (serviceKey: string, serviceType: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/services/${serviceKey}/packages?serviceType=${serviceType}`,
            {
               headers: headers,
            },
         );
      },
      getExchangeRate: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get('/external-dashboard-api/v1/storefront/exchange-rate', { headers });
      },
      changeExchangeRate: (data: any, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post('/external-dashboard-api/v1/storefront/exchange-rate', data, {
            headers,
         });
      },
      getOperators(paymentMethod: string, countryCode: string, token: string) {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `external-dashboard-api/v1/operators?paymentMethods=${paymentMethod}&countryCodes=${countryCode}`,
            {
               headers: headers,
            },
         );
      },
      getServiceCountries(serviceKey: string, token: string) {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`external-dashboard-api/v1/services/${serviceKey}/countries`, {
            headers: headers,
         });
      },
      deleteServicePackage(serviceKey: string, packageId: string, token: string) {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.delete(
            `/external-dashboard-api/v1/services/${serviceKey}/package/${packageId}`,
            {
               headers: headers,
            },
         );
      },
      //* change any
      addEditServicePackage(data: any, token: string) {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post(`/external-dashboard-api/v1/services/package`, data, {
            headers: headers,
         });
      },
   },

   balance: {
      getUsersByRealm: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `external-dashboard-api/v1/users/details?${filters}&first=${first}&max=${max}`,
            {
               headers: headers,
            },
         );
      },
      getUsersByRealmForExport: (
         first: number,
         max: number,
         filters: string,
         token: string,
      ) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `${exportBaseUrl}external-dashboard-api/v1/users/details?${filters}&first=${first}&max=${max}`,
            {
               headers: headers,
            },
         );
      },
      getBalanceChartData: (filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`external-dashboard-api/v1/users/chart?${filters}`, {
            headers: headers,
         });
      },
      getUsersBalance: (keycloakId: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`external-dashboard-api/v1/users/sf-balance/${keycloakId}`, {
            headers: headers,
         });
      },
      getAllTenants: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`external-dashboard-api/v1/storefront/tenants`, {
            headers: headers,
         });
      },
   },
   revenue: {
      getRevenuesByTime: (groupBy: string, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/revenues?groupBy=${groupBy}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getRevenuesByCountry: (filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`/external-dashboard-api/v1/revenues?groupBy=COUNTRY&${filters}`, {
            headers: headers,
         });
      },
      getStorefronts: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get('/external-dashboard-api/v1/search-filters/storefronts', {
            headers: headers,
         });
      },
   },
   subscribers: {
      getSubscribers: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `/external-dashboard-api/v1/subscribers?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },
      getSubscribersForExport: (
         first: number,
         max: number,
         filters: string,
         token: string,
      ) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `${exportBaseUrl}external-dashboard-api/v1/subscribers?firstResult=${first}&maxResults=${max}&${filters}`,
            {
               headers: headers,
            },
         );
      },

      blacklist: (msisdns: string[], reason: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post(
            `/external-dashboard-api/v1/subscribers/blacklist`,
            {
               msisdns,
               reason,
            },
            {
               headers: headers,
            },
         );
      },

      unblacklist: (msisdns: string[], token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.post(
            `/external-dashboard-api/v1/subscribers/unblacklist`,
            {
               msisdns,
            },
            {
               headers: headers,
            },
         );
      },
   },
   users: {
      getUsers: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `external-dashboard-api/v1/users?first=${first}&max=${max}${
               filters && `&${filters}`
            }`,
            {
               headers: headers,
            },
         );
      },
      getUsersForExport: (first: number, max: number, filters: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(
            `${exportBaseUrl}external-dashboard-api/v1/users?first=${first}&max=${max}${
               filters && `&${filters}`
            }`,
            {
               headers: headers,
            },
         );
      },
      updateUser: (realm: string, keycloakId: string, data: any, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.put(
            `external-dashboard-api/v1/users?realm=${realm}&keycloakId=${keycloakId}`,
            data,
            { headers },
         );
      },
      deleteUser: (realm: string, keycloakId: string, token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.delete(
            `external-dashboard-api/v1/users?realm=${realm}&keycloakId=${keycloakId}`,
            { headers },
         );
      },
      getOrganizationalUnits: (token: string) => {
         const headers = { Authorization: `Bearer ${token}` };
         return axios.get(`external-dashboard-api/v1/users/organizational-unit/`, {
            headers: headers,
         });
      },
   },
};
