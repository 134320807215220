import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import classes from './Users.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import Input from '../../shared/input/Input';
import { UserType } from '../../../store/users/usersTypes';
import MultiSelect from 'react-multi-select-component';
import Button from '../../shared/button/Button';
import {
   DeleteUser,
   GetOrganizationalUnits,
   UpdateUser,
} from '../../../store/users/usersActions';
import { USERS_INPUTS } from '../../../data/userMgtInputs';
import { cancelDropdown } from '../../../utils/cancelDropdown';
import cx from 'classnames';
import { Routes } from '../../../constants/routes/routes';
import { getStorefronts } from '../../../store/revenue/revenueActions';
import DeleteModal from '../../modal/deleteModal/DeleteModal';
import UserTransactions from './UserTransactions';
import UserSubscriptions from './UserSubscriptions';
import { ReactComponent as GoBackIcon } from '../../../assets/images/svg/previous.svg';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';

const UserDetails = () => {
   const location = useLocation();
   const dispatch = useDispatch();
   const history = useHistory();
   const user: UserType = location.state as UserType;
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const organizationalUnit = useSelector(
      (store: RootStore) => store.users.organizationalUnits.data,
   );

   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
   const [isTransactionsTableOpen, setIsTransactionsTableOpen] = useState(false);
   const [isSubscriptionsTableOpen, setIsSubscriptionsTableOpen] = useState(true);
   const [orgUnit, setOrgUnit] = useState<any>([]);

   useEffect(() => {
      if (!user) {
         history.push(Routes.USERS_ROUTE);
         return;
      }

      dispatch(GetOrganizationalUnits());
      dispatch(getStorefronts());
   }, []);

   useEffect(() => {
      const orgUnit = user?.companyOrgUnit;
      setOrgUnit(orgUnit ? [{ label: orgUnit, value: orgUnit }] : []);
   }, [user]);

   const updateUserHandler = () => {
      dispatch(UpdateUser(user?.realm, user?.id, { organizationalUnit: orgUnit[0].value }));
   };

   const deleteUserHandler = () => {
      dispatch(DeleteUser(user?.realm, user?.id));
      setIsDeleteModalOpen(false);
   };

   return (
      <>
         <div className={classes.filterRowEdit}>
            <GoBackIcon />
            <a onClick={() => history.goBack()}>Back</a>
         </div>
         <div className={classes.header}>
            <h2>User Info</h2>
            {actionsUser.includes(UserRoleActions.USER_DELETE_ACTION) && (
               <Button
                  text="Delete user"
                  type="delete"
                  onClick={() => setIsDeleteModalOpen(true)}
               />
            )}
         </div>
         <div className={classes.container}>
            {USERS_INPUTS.map((input, i) => (
               <Input
                  key={i}
                  type={input.type}
                  name={input.name}
                  placeholder={input.placeholder}
                  value={user?.[input.name]}
                  className={classes.filterInputs}
                  label={input.label}
                  disabledLabel={false}
                  disabled
               />
            ))}
            <MultiSelect
               options={organizationalUnit}
               labelledBy="Select"
               value={orgUnit}
               className={cx(
                  classes.filterInputs,
                  `singleInput type orgUnit ${
                     !actionsUser.includes(UserRoleActions.USER_UPDATE_ACTION) && 'disabled'
                  }`,
               )}
               overrideStrings={{ selectSomeItems: 'Organizational unit' }}
               hasSelectAll={false}
               disableSearch={true}
               disabled={!actionsUser.includes(UserRoleActions.USER_UPDATE_ACTION)}
               onChange={(e: any) => {
                  if (e.length === 0) {
                     setOrgUnit([]);
                     return;
                  }
                  const newValue = e[e.length - 1] || e;
                  setOrgUnit([newValue]);
                  cancelDropdown('orgUnit');
               }}
            />
         </div>
         {actionsUser.includes(UserRoleActions.USER_UPDATE_ACTION) && (
            <div className={classes.buttonWrap}>
               <Button
                  text={'Cancel'}
                  type={'clear'}
                  onClick={() => {
                     const orgUnit = user?.companyOrgUnit;
                     setOrgUnit(orgUnit ? [{ label: orgUnit, value: orgUnit }] : []);
                     history.push(Routes.USERS_ROUTE);
                  }}
               />
               <Button
                  text={'Save'}
                  type={'primary'}
                  disabled={orgUnit.length === 0}
                  onClick={updateUserHandler}
               />
            </div>
         )}
         <div>
            <div className={classes.tablesHeader}>
               <div
                  className={cx({ [classes.active]: isSubscriptionsTableOpen })}
                  onClick={() => {
                     setIsTransactionsTableOpen(false);
                     setIsSubscriptionsTableOpen(true);
                  }}
               >
                  Subscriptions
               </div>
               <div
                  className={cx({ [classes.active]: isTransactionsTableOpen })}
                  onClick={() => {
                     setIsTransactionsTableOpen(true);
                     setIsSubscriptionsTableOpen(false);
                  }}
               >
                  Transactions
               </div>
            </div>
         </div>
         {isTransactionsTableOpen && <UserTransactions />}
         {isSubscriptionsTableOpen && <UserSubscriptions />}
         <DeleteModal
            isOpen={isDeleteModalOpen}
            deleteMessage={deleteUserHandler}
            onRequestClose={() => setIsDeleteModalOpen(false)}
            errorMessage={user?.username}
            suffix="user"
         />
      </>
   );
};

export default UserDetails;
