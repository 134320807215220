import { Dispatch } from 'redux';
import KeycloakService from '../../auth/KeycloakService';
import API from '../../api/CentiliService';
import { toast } from 'react-toastify';
import { UsersActionTypes, UsersDispatchTypes, PaginationType } from './usersTypes';
import history from '../../utils/history';
import { Routes } from '../../constants/routes/routes';

export const GetAllUsers =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.GET_USERS_START,
      });

      try {
         await KeycloakService.updateToken();

         const token = localStorage.getItem('accessToken');
         const response = await API.users.getUsers(first, max, filters, token || '');

         dispatch({
            type: UsersActionTypes.USERS_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: UsersActionTypes.GET_USERS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting all users. ${error}`);
         dispatch({
            type: UsersActionTypes.GET_USERS_ERROR,
         });
      }
   };

export const GetUsersSubscriptions =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.GET_USERS_SUBSCRIPTIONS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.subscriptions.getAllSubscriptions(
            first,
            max,
            filters,
            token || '',
         );

         dispatch({
            type: UsersActionTypes.USERS_SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: UsersActionTypes.GET_USERS_SUBSCRIPTIONS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting users subscriptions. ${error}`);
         dispatch({
            type: UsersActionTypes.GET_USERS_SUBSCRIPTIONS_ERROR,
         });
      }
   };

export const GetUsersTransactions =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.GET_USERS_TRANSACTIONS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.transactions.getAllTransactions(
            first,
            max,
            filters,
            token || '',
         );

         dispatch({
            type: UsersActionTypes.USERS_TRANSACTIONS_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: UsersActionTypes.GET_USERS_TRANSACTIONS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting users transactions. ${error}`);
         dispatch({
            type: UsersActionTypes.GET_USERS_TRANSACTIONS_ERROR,
         });
      }
   };

export const DeleteUser =
   (realm: string, keycloakId: string) => async (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.DELETE_USER_START,
      });

      try {
         await KeycloakService.updateToken();

         const token = localStorage.getItem('accessToken');
         await API.users.deleteUser(realm, keycloakId, token || '');
         dispatch({
            type: UsersActionTypes.DELETE_USER_SUCCESS,
         });
         dispatch(GetAllUsers(0, 8, '') as any);
         history.push(Routes.USERS_ROUTE);
         toast.dark('User successfully deleted!');
      } catch (error: any) {
         toast.error(`Error deleting user. ${error}`);
         dispatch({
            type: UsersActionTypes.DELETE_USER_ERROR,
         });
      }
   };

export const UpdateUser =
   (realm: string, keycloakId: string, data: any) =>
   async (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.UPDATE_USER_START,
      });

      try {
         await KeycloakService.updateToken();

         const token = localStorage.getItem('accessToken');
         await API.users.updateUser(realm, keycloakId, data, token || '');
         dispatch({
            type: UsersActionTypes.UPDATE_USER_SUCCESS,
         });
         dispatch(GetAllUsers(0, 8, '') as any);
         history.push(Routes.USERS_ROUTE);

         toast.dark('User successfully edited!');
      } catch (error: any) {
         toast.error(`Error editing user. ${error}`);
         dispatch({
            type: UsersActionTypes.UPDATE_USER_ERROR,
         });
      }
   };

export const GetOrganizationalUnits = () => async (dispatch: Dispatch<UsersDispatchTypes>) => {
   dispatch({
      type: UsersActionTypes.GET_ORGANIZATIONAL_UNITS_START,
   });

   try {
      await KeycloakService.updateToken();

      const token = localStorage.getItem('accessToken');
      const response = await API.users.getOrganizationalUnits(token || '');
      dispatch({
         type: UsersActionTypes.GET_ORGANIZATIONAL_UNITS_SUCCESS,
         payload: response.data,
      });
   } catch (error: any) {
      toast.error(`Error getting organizational units. ${error}`);
      dispatch({
         type: UsersActionTypes.GET_ORGANIZATIONAL_UNITS_ERROR,
      });
   }
};

export const GetBundleParts =
   (transactionId: string) => async (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.GET_BUNDLE_PARTS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.transactions.getBundleParts(transactionId, token || '');

         dispatch({
            type: UsersActionTypes.GET_BUNDLE_PARTS_SUCCESS,
            payload: { transactionId, bundleParts: response.data },
         });
      } catch (error: any) {
         toast.error(`Error getting bundle parts. ${error}`);
         dispatch({
            type: UsersActionTypes.GET_BUNDLE_PARTS_ERROR,
         });
      }
   };

export const UsersPaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.USERS_PAGINATION_UPDATE,
         payload: pagination,
      });
   };

export const UsersTransactionsPaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.USERS_TRANSACTIONS_PAGINATION_UPDATE,
         payload: pagination,
      });
   };

export const UsersSubscriptionsPaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<UsersDispatchTypes>) => {
      dispatch({
         type: UsersActionTypes.USERS_SUBSCRIPTIONS_PAGINATION_UPDATE,
         payload: pagination,
      });
   };
