import Tippy from '@tippyjs/react';
import { motion } from 'framer-motion';
import React, { ReactChild, ReactChildren } from 'react';
import 'tippy.js/dist/tippy.css';
import classes from './NotificationBadge.module.scss';

type ContentProp = {
   value: string;
   label: string;
};

interface NotificationBadgeProp {
   content: ContentProp[];
   children: ReactChild | ReactChildren | JSX.Element[];
}

/* Notification circle above input - displays number of selected elements */
const NotificationBadge = ({ content, children }: NotificationBadgeProp) => {
   return (
      <Tippy
         content={content.map((el: ContentProp) => el.label).join()}
         disabled={content.length === 0}
      >
         <span tabIndex={0}>
            <div style={{ position: 'relative' }}>
               {content.length !== 0 && (
                  <motion.span
                     initial={{ opacity: 0, scale: 0 }}
                     animate={{ opacity: 1, scale: 1 }}
                     transition={{ duration: 0.2 }}
                     className={classes.counter}
                  >
                     {content.length}
                  </motion.span>
               )}

               {children}
            </div>
         </span>
      </Tippy>
   );
};

export default NotificationBadge;
