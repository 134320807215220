import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import React from 'react';
import {
   GetAllSubscribers,
   PaginationUpdate,
} from '../../../store/subscribers/subscribersActions';
import classes from './Subscribers.module.scss';
import Input from '../../shared/input/Input';
import Button from '../../shared/button/Button';
import DateRangePicker from '../../shared/dateRangePicker/DateRangePickerComponent';
import MultiSelect from 'react-multi-select-component';
import cx from 'classnames';
import { SUBSCRIBER_BLACKLIST_STATUS_OPTIONS } from '../../../data/dropdownValues';
import { cancelDropdown } from '../../../utils/cancelDropdown';

interface Props {
   state: any;
   setState: (e: any) => void;
   setExportData: (e: any) => void;
   getFilterQueryString: () => string;
}

const SubscribersFilters = ({
   state,
   setState,
   setExportData,
   getFilterQueryString,
}: Props) => {
   const dispatch = useDispatch();
   const pagination = useSelector(
      (store: RootStore) => store.subscribers.subscribers.pagination,
   );

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setState({
         ...state,
         [name]: value,
      });
   };

   const clearState = () => {
      setState({
         identifier: '',
         dateFirstActivity: [],
         dateLastActivity: [],
         blacklistStatus: [SUBSCRIBER_BLACKLIST_STATUS_OPTIONS[0]],
         dateBlacklisted: [],
      });
   };

   const searchSubscribers = () => {
      dispatch(GetAllSubscribers(0, pagination.max, getFilterQueryString()));
      dispatch(PaginationUpdate({ first: 0, max: 8 }));
      setExportData([]);
   };

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         searchSubscribers();
      }
   };

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               <Input
                  type="text"
                  name="identifier"
                  placeholder={'Email / MSISDN'}
                  value={state.identifier}
                  onChange={textInputHandler}
                  className={classes.filterInputs}
                  onKeyUp={keyUpHandler}
               />
               <div className={classes.filtersDate}>
                  <label htmlFor="dateFirstActivity">First activity</label>
                  <DateRangePicker
                     name="dateFirstActivity"
                     value={state.dateFirstActivity}
                     setState={setState}
                  />
               </div>
               <div className={classes.filtersDate}>
                  <label htmlFor="dateLastActivity">Last activity</label>
                  <DateRangePicker
                     name="dateLastActivity"
                     value={state.dateLastActivity}
                     setState={setState}
                  />
               </div>
               <MultiSelect
                  overrideStrings={{ selectSomeItems: 'Blacklist status' }}
                  className={cx(classes.filtersInput, 'singleInput type blacklistStatus')}
                  options={SUBSCRIBER_BLACKLIST_STATUS_OPTIONS}
                  value={state.blacklistStatus}
                  labelledBy={'Select'}
                  onChange={(e: any) => {
                     if (e.length === 0) {
                        setState({
                           ...state,
                           blacklistStatus: [SUBSCRIBER_BLACKLIST_STATUS_OPTIONS[0]],
                        });
                        return;
                     }
                     const newValue = e[e.length - 1] || e;
                     setState({
                        ...state,
                        blacklistStatus: [newValue],
                     });
                     cancelDropdown('blacklistStatus');
                  }}
                  hasSelectAll={false}
                  disableSearch={true}
               />
               <div className={classes.filtersDate}>
                  <label htmlFor="dateBlacklisted">Date blacklisted</label>
                  <DateRangePicker
                     name="dateBlacklisted"
                     value={state.dateBlacklisted}
                     setState={setState}
                  />
               </div>
            </div>
            <div className={classes.filtersRight}>
               <Button text={'Apply'} type={'blue'} onClick={searchSubscribers} />
               <Button text={'Reset'} type={'clear'} onClick={clearState} />
            </div>
         </div>
      </>
   );
};

export default SubscribersFilters;
