import { OptionType } from '../../constants/types/inputs/multiselect/types';

export enum TransactionsActionTypes {
   GET_TRANSACTIONS_START = '@@transactions/GET_TRANSACTIONS_START',
   GET_TRANSACTIONS_SUCCESS = '@@transactions/GET_TRANSACTIONS_SUCCESS',
   GET_TRANSACTIONS_ERROR = '@@transactions/GET_TRANSACTIONS_ERROR',

   GET_BUNDLE_PARTS_START = '@@transactions/GET_BUNDLE_PARTS_START',
   GET_BUNDLE_PARTS_SUCCESS = '@@transactions/GET_BUNDLE_PARTS_SUCCESS',
   GET_BUNDLE_PARTS_ERROR = '@@transactions/GET_BUNDLE_PARTS_ERROR',

   GET_COUNTRIES_START = '@@transactions/GET_COUNTRIES_START',
   GET_COUNTRIES_SUCCESS = '@@transactions/GET_COUNTRIES_SUCCESS',
   GET_COUNTRIES_ERROR = '@@transactions/GET_COUNTRIES_ERROR',

   GET_OPERATORS_START = '@@transactions/GET_OPERATORS_START',
   GET_OPERATORS_SUCCESS = '@@transactions/GET_OPERATORS_SUCCESS',
   GET_OPERATORS_ERROR = '@@transactions/GET_OPERATORS_ERROR',

   GET_MERCHANTS_START = '@@transactions/GET_MERCHANTS_START',
   GET_MERCHANTS_SUCCESS = '@@transactions/GET_MERCHANTS_SUCCESS',
   GET_MERCHANTS_ERROR = '@@transactions/GET_MERCHANTS_ERROR',

   GET_SERVICES_START = '@@transactions/GET_SERVICES_START',
   GET_SERVICES_SUCCESS = '@@transactions/GET_SERVICES_SUCCESS',
   GET_SERVICES_ERROR = '@@transactions/GET_SERVICES_ERROR',

   RESEND_CONTENT_START = '@@transactions/RESEND_CONTENT_START',
   RESEND_CONTENT_SUCCESS = '@@transactions/RESEND_CONTENT_SUCCESS',
   RESEND_CONTENT_ERROR = '@@transactions/RESEND_CONTENT_ERROR',

   REFUND_START = '@@transactions/REFUND_START',
   REFUND_SUCCESS = '@@transactions/REFUND_SUCCESS',
   REFUND_ERROR = '@@transactions/REFUND_ERROR',

   TRANSACTIONS_PAGINATION_UPDATE = '@@transactions/TRANSACTIONS_PAGINATION_UPDATE',
   TRANSACTIONS_PAGINATION_TOTAL_COUNT = '@@transactions/TRANSACTIONS_PAGINATION_TOTAL_COUNT',
}

type GetAllTransactionsStart = {
   type: TransactionsActionTypes.GET_TRANSACTIONS_START;
};

type GetAllTransactionsSuccess = {
   type: TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS;
   payload: TransactionType[];
};

type GetAllTransactionsError = {
   type: TransactionsActionTypes.GET_TRANSACTIONS_ERROR;
};

type GetBundlePartsStart = {
   type: TransactionsActionTypes.GET_BUNDLE_PARTS_START;
};

type GetBundlePartsSuccess = {
   type: TransactionsActionTypes.GET_BUNDLE_PARTS_SUCCESS;
   payload: any;
};

type GetBundlePartsError = {
   type: TransactionsActionTypes.GET_BUNDLE_PARTS_ERROR;
};

type GetOperatorsStart = {
   type: TransactionsActionTypes.GET_OPERATORS_START;
};

type GetOperatorsSuccess = {
   type: TransactionsActionTypes.GET_OPERATORS_SUCCESS;
   payload: any[];
};

type GetOperatorsError = {
   type: TransactionsActionTypes.GET_OPERATORS_ERROR;
};

type GetCountriesStart = {
   type: TransactionsActionTypes.GET_COUNTRIES_START;
};

type GetCountriesSuccess = {
   type: TransactionsActionTypes.GET_COUNTRIES_SUCCESS;
   payload: any[];
};

type GetCountriesError = {
   type: TransactionsActionTypes.GET_COUNTRIES_ERROR;
};

type GetMerchantsStart = {
   type: TransactionsActionTypes.GET_MERCHANTS_START;
};

type GetMerchantsSuccess = {
   type: TransactionsActionTypes.GET_MERCHANTS_SUCCESS;
   payload: any[];
};

type GetMerchantsError = {
   type: TransactionsActionTypes.GET_MERCHANTS_ERROR;
};

type GetServicesStart = {
   type: TransactionsActionTypes.GET_SERVICES_START;
};

type GetServicesSuccess = {
   type: TransactionsActionTypes.GET_SERVICES_SUCCESS;
   payload: any[];
};

type GetServicesError = {
   type: TransactionsActionTypes.GET_SERVICES_ERROR;
};

type ResendContentStart = {
   type: TransactionsActionTypes.RESEND_CONTENT_START;
};

type ResendContentSuccess = {
   type: TransactionsActionTypes.RESEND_CONTENT_SUCCESS;
};

type ResendContentError = {
   type: TransactionsActionTypes.RESEND_CONTENT_ERROR;
};

type RefundStart = {
   type: TransactionsActionTypes.REFUND_START;
};

type RefundSuccess = {
   type: TransactionsActionTypes.REFUND_SUCCESS;
};

type RefundError = {
   type: TransactionsActionTypes.REFUND_ERROR;
};

type TransactionsPaginationUpdate = {
   type: TransactionsActionTypes.TRANSACTIONS_PAGINATION_UPDATE;
   payload: PaginationType;
};

type TransactionsPaginationTotalCount = {
   type: TransactionsActionTypes.TRANSACTIONS_PAGINATION_TOTAL_COUNT;
   payload: number;
};

export type TransactionsDispatchTypes =
   | GetAllTransactionsStart
   | GetAllTransactionsSuccess
   | GetAllTransactionsError
   | GetBundlePartsStart
   | GetBundlePartsSuccess
   | GetBundlePartsError
   | GetOperatorsStart
   | GetOperatorsSuccess
   | GetOperatorsError
   | GetCountriesStart
   | GetCountriesSuccess
   | GetCountriesError
   | GetMerchantsStart
   | GetMerchantsSuccess
   | GetMerchantsError
   | GetServicesStart
   | GetServicesSuccess
   | GetServicesError
   | ResendContentStart
   | ResendContentSuccess
   | ResendContentError
   | RefundStart
   | RefundSuccess
   | RefundError
   | TransactionsPaginationUpdate
   | TransactionsPaginationTotalCount;

export interface TransactionsState {
   transactions: {
      data: TransactionType[];
      isLoading: boolean;
      bundleParts: {
         [key: string]: TransactionType[];
      } | null;
      isBundlePartsLoading: boolean;
      pagination: TransactionsPagination;
   };
   filters: {
      countries: OptionType[];
      operators: OptionType[];
      merchants: OptionType[];
      services: OptionType[];
      isLoading: boolean;
   };
}

export interface TransactionType {
   notificationStatus: string;
   subscriber: string;
   companyName: string;
   timeChargedLocal: string;
   serviceName: string;
   packageQuantity: number;
   operatorCode: string;
   transactionId: number;
   sountryImage: string;
   accountId: number;
   countryCode: string;
   price: number;
   notificationStatusCode: string;
   action: string;
   actionCode: string;
   serviceKey: string;
   currencyCode: string;
   statusCode: string;
   status: string;
   clientId: string;
   errorTypeCode: string;
   errorType: string;
   packageUnitName: string;
   priceInEur: number;
   purchasePrice: string;
   purchaseCurrency: string;
}

export type PaginationType = {
   first: number;
   max: number;
};

export type TransactionsPagination = {
   totalCount: number;
   first: number;
   max: number;
};
