import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import classes from './Services.module.scss';
import { ReactComponent as GoBackIcon } from '../../../assets/images/svg/previous.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import TableCell from '../../table/tableCell/TableCell';
import Table from '../../table/Table';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import { PACKAGES_HEADER } from '../../../data/tableHeaders';
import HeaderCell from '../../table/headerCell/HeaderCell';
import TableData from '../../table/tableData/TableData';
import { checkValue, FormatDate } from '../../../utils/helpers';
import cx from 'classnames';
import EmptyStatePage from '../../shared/emptyStatePage/EmptyStatePage';
import EmptyStateIcon from '../../../assets/images/svg/empty-state.svg';
import {
   addServicePackage,
   deleteServicePackage,
   editServicePackage,
   getServicePackages,
} from '../../../store/services/servicesActions';
import { Routes } from '../../../constants/routes/routes';
import Pagination from '../../pagination/Pagination';
import { getCountries } from '../../../store/transactions/transactionsActions';
import Button from '../../shared/button/Button';
import AddNewPackageModal from '../../modal/storefrontAddNewPackageModal/AddNewPackageModal';
import DeleteModal from '../../modal/deleteModal/DeleteModal';
import { ReactComponent as IconDelete } from '../../../assets/images/svg/delete.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/svg/edit.svg';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';

const ServiceDetails = () => {
   const history = useHistory();
   const location = useLocation();
   const sendLocation: any = location.state;
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const dispatch = useDispatch();
   const service: any = location.state;
   const isLoading = useSelector((store: RootStore) => store.services.packages.isLoading);
   const packages = useSelector((store: RootStore) => store.services.packages.data);
   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);
   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(
      packages.length / selectedRows[0].value,
   );
   const [page, setPage] = useState({
      first: 0,
      max: selectedRows[0].value,
   });
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [addPackageModalOpen, setAddPackageModalOpen] = useState(false);
   const [selectedPackage, setSelectedPackage] = useState(null);
   const [servicePackage, setServicePackage] = useState({
      id: '',
      amount: '',
      unit: '',
      type: '',
      interval: '',
      price: '',
      currencyCode: '',
   });

   const closeDeleteModalHandler = () => {
      setDeleteModalOpen(false);
   };

   const closeAddPackageModalOpen = () => {
      setAddPackageModalOpen(false);
   };

   useEffect(() => {
      if (!service) {
         history.push(Routes.SERVICES_ROUTE);
         return;
      }

      dispatch(getServicePackages(service?.serviceKey, service?.type));
      dispatch(getCountries());
   }, []);

   useEffect(() => {
      setTotalPageState(Math.ceil(packages.length / selectedRows[0].value));
   }, [packages, selectedRows]);

   useEffect(() => {
      setPage({
         first: 0,
         max: selectedRows[0].value,
      });
   }, [selectedRows, isLoading, packages]);

   const serviceDetails = {
      'Service key': service?.serviceKey,
      Status: service?.active ? 'Activated' : 'Not activated',
      Merchant: service?.merchant,
      Type: service?.type,
      'Created at': FormatDate(service?.dateCreated),
   };

   return (
      <>
         <div className={classes.filterRowEdit}>
            <GoBackIcon />
            <a onClick={() => history.goBack()}>Back</a>
         </div>
         <div className={classes.titleContainer}>
            <h2>{service?.name}</h2>
         </div>
         {isLoading ? (
            <TableLoader />
         ) : (
            <>
               <div className={classes.details}>
                  <div className={classes.keys}>
                     {Object.keys(serviceDetails).map((key, i) => (
                        <div key={i}>{key}</div>
                     ))}
                  </div>
                  <div className={classes.values}>
                     {Object.entries(serviceDetails).map((value, i) => {
                        return (
                           <div
                              key={i}
                              className={cx({
                                 [classes.activated]:
                                    value[0] === 'Status' && value[1] === 'Activated',
                                 [classes.notActivated]:
                                    value[0] === 'Status' && value[1] === 'Not activated',
                              })}
                           >
                              {checkValue(value[1])}
                           </div>
                        );
                     })}
                  </div>
               </div>
               <div className={classes.filtersMenu}>
                  <div className={classes.filtersLeft}>
                     <h2 className={classes.title}>List of packages</h2>
                  </div>
                  <div className={classes.filtersRight}>
                     <>
                        {actionsUser.includes(UserRoleActions.EDIT_PACKAGES) && (
                           <Button
                              className={classes.filterButtons}
                              type={'blue'}
                              text={'Add Package'}
                              onClick={() => {
                                 setSelectedPackage(null);
                                 setAddPackageModalOpen(true);
                              }}
                           />
                        )}
                     </>
                  </div>
               </div>
               {packages?.length ? (
                  <>
                     <Table className={classes.serviceDetailsTable}>
                        <TableHeader>
                           <>
                              <TableRow>
                                 <>
                                    {service?.type === 'SUBSCRIPTION' ? (
                                       <>
                                          {PACKAGES_HEADER.slice(0, 1).map((header, i) => {
                                             return (
                                                <HeaderCell key={i}>{header.text}</HeaderCell>
                                             );
                                          })}
                                       </>
                                    ) : (
                                       <>
                                          {PACKAGES_HEADER.slice(1, 3).map((header, i) => {
                                             return (
                                                <HeaderCell key={i}>{header.text}</HeaderCell>
                                             );
                                          })}
                                       </>
                                    )}
                                    {PACKAGES_HEADER.slice(3, 11).map((header, i) => {
                                       return <HeaderCell key={i}>{header.text}</HeaderCell>;
                                    })}
                                    <HeaderCell> </HeaderCell>
                                 </>
                              </TableRow>
                           </>
                        </TableHeader>
                        <TableData>
                           <>
                              {packages
                                 ?.slice(page.first, page.max)
                                 .map((pack: any, i: number) => {
                                    return (
                                       <TableRow key={i}>
                                          {service?.type === 'SUBSCRIPTION' ? (
                                             <TableCell>{checkValue(pack.interval)}</TableCell>
                                          ) : (
                                             <>
                                                <TableCell>
                                                   {checkValue(pack.amount)}
                                                </TableCell>
                                                <TableCell>{checkValue(pack.unit)}</TableCell>
                                             </>
                                          )}
                                          <TableCell>
                                             {pack.price
                                                ? pack.price.toLocaleString('es-ES', {
                                                     minimumFractionDigits: 2,
                                                  })
                                                : '-'}
                                          </TableCell>
                                          <TableCell>
                                             {pack.oldPrice
                                                ? pack.oldPrice.toLocaleString('es-ES', {
                                                     minimumFractionDigits: 2,
                                                  })
                                                : '-'}
                                          </TableCell>
                                          <TableCell>
                                             {checkValue(pack.currencyCode)}
                                          </TableCell>
                                          <TableCell>
                                             <div>
                                                <img
                                                   src={`https://stage.centili.com/res/img/flags/24/${pack.countryCode}.png`}
                                                />
                                                <label className={classes.tableCellLabel}>
                                                   {
                                                      countries.find(
                                                         (country: any) =>
                                                            country.value === pack.countryCode,
                                                      )?.label
                                                   }
                                                </label>
                                             </div>
                                          </TableCell>
                                          <TableCell>
                                             {checkValue(pack.operatorCode)}
                                          </TableCell>
                                          <TableCell>
                                             {checkValue(pack.paymentMethod)}
                                          </TableCell>
                                          <TableCell>
                                             {checkValue(pack.merchantReference)}
                                          </TableCell>
                                          <TableCell>{checkValue(pack.reference)}</TableCell>
                                          <TableCell className={classes.iconsContainer}>
                                             <div>
                                                {actionsUser.includes(
                                                   UserRoleActions.EDIT_PACKAGES,
                                                ) && (
                                                   <>
                                                      <IconEdit
                                                         className={classes.categoryIcon}
                                                         onClick={() => {
                                                            setSelectedPackage(pack);
                                                            setAddPackageModalOpen(true);
                                                         }}
                                                      />
                                                      <IconDelete
                                                         className={cx(
                                                            classes.categoryIcon,
                                                            classes.customDeleteIcon,
                                                         )}
                                                         onClick={() => {
                                                            setServicePackage({
                                                               id: pack.id,
                                                               unit: pack.unit,
                                                               amount: pack.amount,
                                                               type: service?.serviceType,
                                                               interval: pack.interval,
                                                               price: pack.price,
                                                               currencyCode: pack.currencyCode,
                                                            });
                                                            setDeleteModalOpen(true);
                                                         }}
                                                      />
                                                   </>
                                                )}
                                             </div>
                                          </TableCell>
                                       </TableRow>
                                    );
                                 })}
                           </>
                        </TableData>
                     </Table>
                     <Pagination
                        isServerSide={false}
                        isSearching={isLoading}
                        first={page.first}
                        max={page.max}
                        totalCount={packages.length}
                        totalPages={totalPageState}
                        rowValue={selectedRows}
                        setSelectedRows={setSelectedRows}
                        curMax={selectedRows[0].value}
                        setPage={setPage}
                     />
                  </>
               ) : (
                  <EmptyStatePage
                     image={EmptyStateIcon}
                     headlineText={'Add your first package!'}
                     message={`You don't have any packages.`}
                  />
               )}
            </>
         )}
         {actionsUser.includes(UserRoleActions.EDIT_PACKAGES) && (
            <>
               <AddNewPackageModal
                  isOpen={addPackageModalOpen}
                  onRequestClose={closeAddPackageModalOpen}
                  data={selectedPackage}
                  service={service}
                  serviceType={service?.type || ''}
                  addPackage={addServicePackage}
                  editPackage={editServicePackage}
               />
               <DeleteModal
                  isOpen={deleteModalOpen}
                  deleteMessage={() => {
                     dispatch(
                        deleteServicePackage(
                           sendLocation?.serviceKey,
                           servicePackage.id,
                           sendLocation?.type,
                        ),
                     );
                     setDeleteModalOpen(false);
                  }}
                  errorMessage={
                     service?.type === 'ONE_TIME'
                        ? `${servicePackage.amount} ${servicePackage.unit} (${servicePackage.price} ${servicePackage.currencyCode})`
                        : `${servicePackage.interval} (${servicePackage.price} ${servicePackage.currencyCode})`
                  }
                  onRequestClose={closeDeleteModalHandler}
                  suffix={` package on service ${sendLocation?.name} `}
               />
            </>
         )}
      </>
   );
};

export default ServiceDetails;
