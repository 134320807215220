import { UserRoleActions } from './../constants/userActions/userRoleActions';
export const TRANSACTIONS_HEADERS = [
   {
      text: 'Transaction ID',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Service',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Package',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Subsciber',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Country',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Price',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Price in EUR',
      action: UserRoleActions.PRICE_EUR_VIEW_ACTION,
   },
   {
      text: 'NET price',
      action: UserRoleActions.NET_PRICE_VIEW_ACTION,
   },
   {
      text: 'Merchant',
      action: UserRoleActions.MERCHANT_VIEW_ACTION,
   },
   {
      text: 'Client id',
      action: UserRoleActions.CLIENT_ID_VIEW_ACTION,
   },
   {
      text: 'Status',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Notification',
      action: UserRoleActions.NOTIFICATION_VIEW_ACTION,
   },
   {
      text: 'Time',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Type',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
];

export const BUNDLE_TRANSACTIONS_HEADERS = [
   {
      text: 'Transaction ID',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Service',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Subsciber',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Country',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Price',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Merchant',
      action: UserRoleActions.MERCHANT_VIEW_ACTION,
   },
   {
      text: 'Client id',
      action: UserRoleActions.CLIENT_ID_VIEW_ACTION,
   },
   {
      text: 'Status',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
   {
      text: 'Notification',
      action: UserRoleActions.NOTIFICATION_VIEW_ACTION,
   },
   {
      text: 'Time',
      action: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
   },
];

export const SUBSCRIPTIONS_HEADERS = [
   {
      text: 'Subscriber',
   },
   {
      text: 'Subscription ID',
   },
   {
      text: 'Merchant',
   },
   {
      text: 'Client id',
   },
   {
      text: 'Service',
   },
   {
      text: 'Country',
   },
   {
      text: 'Interval',
   },
   {
      text: 'Price',
   },
   {
      text: 'Currency',
   },
   {
      text: 'Date Subscr.',
   },
   {
      text: 'Date Unsubscr.',
   },
];

export const SERVICES_HEADERS = [
   {
      text: 'Name',
   },
   {
      text: 'Service key',
   },
   {
      text: 'Type',
   },
   {
      text: 'Merchant',
   },
   {
      text: 'Status',
   },
];

export const PACKAGES_HEADER = [
   {
      text: 'Interval',
   },
   {
      text: 'Amount',
   },
   {
      text: 'Unit',
   },
   {
      text: 'Price',
   },
   {
      text: 'Old price',
   },
   {
      text: 'Currency',
   },
   {
      text: 'Country',
   },
   {
      text: 'Operator',
   },
   {
      text: 'Payment method',
   },
   {
      text: 'Merchant reference',
   },
   {
      text: 'Reference',
   },
];

export const USERS_HEADER = [
   {
      text: 'Username',
   },
   {
      text: 'First name',
   },
   {
      text: 'Last name',
   },
   {
      text: 'Client id',
   },
   {
      text: 'Company name',
   },
   {
      text: 'Realm',
   },
   {
      text: 'Used Points',
   },
   {
      text: 'Expired Points',
   },
   {
      text: 'Current Points',
   },
];

export const REVENUES_BY_TIME_HEADER = [
   {
      text: 'Period Start',
   },
   {
      text: 'Period End',
   },
   {
      text: 'Completed',
   },
   {
      text: 'Failed',
   },
   {
      text: 'Provider Share',
   },
   {
      text: 'Total Revenue',
   },
];

export const REVENUES_BY_COUNTRY_HEADERS = [
   {
      text: 'Country',
   },
   {
      text: 'Completed',
   },
   {
      text: 'Failed',
   },
   {
      text: 'Provider Share',
   },
   {
      text: 'Total revenue',
   },
];

export const SUBSCRIBERS_HEADERS = [
   {
      text: 'Subscriber',
   },
   {
      text: 'Operator',
   },
   {
      text: 'First activity',
   },
   {
      text: 'Last activity',
   },
   {
      text: 'Blacklisted',
   },
   {
      text: 'Blacklisted by',
   },
   {
      text: 'Blacklist reason',
   },
   {
      text: 'Blacklist origin',
   },
   {
      text: 'Date blacklisted',
   },
];

export const USERS_PAGE_HEADER = [
   {
      text: 'User ID',
   },
   {
      text: 'Username',
   },
   {
      text: 'Email',
   },
   {
      text: 'First Name',
   },
   {
      text: 'Last Name',
   },
   {
      text: 'Organizational Unit',
   },
];

export const USER_TRANSACTIONS_HEADERS = [
   {
      text: 'Transaction ID',
   },
   {
      text: 'Service',
   },
   {
      text: 'Package',
   },
   {
      text: 'Merchant',
   },
   {
      text: 'Price',
   },
   {
      text: 'Price in EUR',
   },
   {
      text: 'Status',
   },
   {
      text: 'Notification',
   },
   {
      text: 'Time',
   },
   {
      text: 'Type',
   },
];

export const USER_SUBSCRIPTIONS_HEADERS = [
   {
      text: 'Subscription ID',
   },
   {
      text: 'Merchant',
   },
   {
      text: 'Service',
   },
   {
      text: 'Price',
   },
   {
      text: 'Date Subscr.',
   },
   {
      text: 'Date Unsubscr.',
   },
];

export const USERS_BUNDLE_TRANSACTIONS_HEADERS = [
   {
      text: 'Transaction ID',
   },
   {
      text: 'Service',
   },
   {
      text: 'Merchant',
   },
   {
      text: 'Price',
   },
   {
      text: 'Status',
   },
   {
      text: 'Notification',
   },
   {
      text: 'Time',
   },
];
