import cx from 'classnames';
import React from 'react';
import avatar from '../../../../assets/images/icons/avatarNotFinal.png';
import { ReactComponent as ArrowDown } from '../../../../assets/images/svg/arrow-down.svg';
import classes from './Avatar.module.scss';

interface AvatarProp {
   arrowUp: boolean;
   onClick: () => void;
}

const Avatar = ({ arrowUp, onClick }: AvatarProp) => {
   return (
      <div
         onClick={onClick}
         className={cx(classes.avatar, {
            [classes.arrowUp]: arrowUp,
         })}
      >
         <img src={avatar} />
         <ArrowDown transform-origin="center" />
      </div>
   );
};

export default Avatar;
