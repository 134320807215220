import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetBalanceChartData } from '../../../store/balance/BalanceActions';
import { RootStore } from '../../../store/rootStore';
import classes from './Balance.module.scss';
import { Bar, Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import MultiSelect from 'react-multi-select-component';
import { cancelDropdown } from '../../../utils/cancelDropdown';
import cx from 'classnames';
import Button from '../../shared/button/Button';
import queryString from 'query-string';
import moment from 'moment';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import UsedBalanceTable from './UsedBalanceTable';
import ExpiredBalanceTable from './ExpiredBalanceTable';

export interface BalanceProps {
   state: any;
   setState: (e: any) => void;
   exportData: any;
   setExportData: (e: any) => void;
}

const BalanceChart = ({ state, setState }: BalanceProps) => {
   const dispatch = useDispatch();
   const tenants = useSelector((store: RootStore) => store.balance.tenants.data);
   const { isLoading, usedBalance, expiredBalance } = useSelector(
      (store: RootStore) => store.balance.users.chartData,
   );
   const isLoadingTenants = useSelector((store: RootStore) => store.balance.tenants.isLoading);
   const [barData, setBarData] = useState<any>();
   const [lineData, setLineData] = useState<any>();
   const [showExpired, setShowExpired] = useState(false);
   const [showUsed, setShowUsed] = useState(true);

   useEffect(() => {
      if (tenants.length !== 0) {
         setState({ ...state, realm: [tenants[0]] });
      }
   }, [tenants]);

   const getEndTime = (date: Date) => {
      const month = date.getMonth() + 1;
      date.setMonth(month);
      date.setDate(0);

      return date;
   };

   const switchCharts = () => {
      setShowExpired(!showExpired);
      setShowUsed(!showUsed);
   };

   const parseStateHandler = () => {
      const params = {
         realm: state.realm.map((code: any) => {
            if (code.value.startsWith('SF')) return code.value;

            return `SF_${code.value}`;
         }),
         startTime: moment(state.startTime).utc(true).format().split('T')[0],
         endTime: moment(getEndTime(state.endTime as Date))
            .utc(true)
            .format()
            .split('T')[0],
      };
      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   useEffect(() => {
      state.realm.length && dispatch(GetBalanceChartData(parseStateHandler()));
   }, [state.realm]);

   useEffect(() => {
      const labels = expiredBalance?.map((balancePerDay) => balancePerDay.monthName);
      setBarData({
         labels,
         datasets: [
            {
               label: 'Expired per month',
               data: expiredBalance.map((balancePerMonth) => balancePerMonth.expiredPerMonth),
               backgroundColor: '#0532F0',
               borderRadius: {
                  topLeft: 10,
                  topRight: 10,
               },
               barThickness: 50,
            },
         ],
      });
   }, [expiredBalance]);

   useEffect(() => {
      const labels = usedBalance?.map((balancePerDay) => balancePerDay.date);
      setLineData({
         labels,
         datasets: [
            {
               label: 'Used per month',
               data: usedBalance.map((balancePerDay) => balancePerDay.usedPerDay),
               backgroundColor: '#FF6700',
               borderColor: '#FF6700',
               lineTension: 0.3,
            },
         ],
      });
   }, [usedBalance]);

   const options = {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 3,
      plugins: {
         legend: {
            display: false,
         },
      },
      scales: {
         x: {
            grid: {
               display: false,
            },
            ticks: {
               font: {
                  color: '#25336B',
                  family: " 'Poppins', sans-serif ",
                  weight: 'bold',
               },
            },
         },
         y: {
            ticks: {
               font: {
                  family: " 'Poppins', sans-serif ",
                  weight: 'bold',
                  color: 'rgba(#25336B, 0.5)',
               },
            },
            y: {
               min: 0,
            },
         },
      },
   };

   const applyFilters = () => {
      state.realm.length && dispatch(GetBalanceChartData(parseStateHandler()));
   };

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               <MultiSelect
                  isLoading={isLoadingTenants}
                  disabled={false}
                  className={cx(
                     classes.filtersInput,
                     classes.multiSelect,
                     'singleInput multi-select realm',
                  )}
                  overrideStrings={{ selectSomeItems: 'Select realm' }}
                  hasSelectAll={false}
                  options={tenants}
                  value={state.realm}
                  onChange={(e: any) => {
                     if (e.length === 0) {
                        setState({
                           ...state,
                           realm: [],
                           username: '',
                        });
                        return;
                     }

                     const newValue = e[e.length - 1] || e;
                     setState({ ...state, realm: [newValue], username: '' });
                     cancelDropdown('realm');
                  }}
                  labelledBy={'Select'}
               />
               {state.realm.length !== 0 && (
                  <div className={classes.filtersDate}>
                     <DatePicker
                        className={classes.customDatepicker}
                        selected={state.startTime}
                        placeholderText={'Choose date'}
                        startDate={state.startTime}
                        endDate={state.endTime}
                        dateFormat="MM/yyyy"
                        maxDate={new Date(Date.now())}
                        popperClassName={classes.popperClass}
                        onChange={(dates: any) => {
                           setState({
                              ...state,
                              startTime: dates[0],
                              endTime: dates[1],
                           });
                        }}
                        showMonthYearPicker
                        selectsRange
                        onKeyDown={(e) => {
                           e.preventDefault();
                        }}
                     />
                  </div>
               )}

               {/* <Input
                  type="text"
                  value={state.company}
                  name="company"
                  placeholder="Company name"
                  onKeyUp={keyUpHandler}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                     setState({ ...state, company: e.target.value });
                  }}
               /> */}
            </div>
            {state.realm.length !== 0 && (
               <div className={classes.filtersRight}>
                  <Button text={'Apply'} type={'blue'} onClick={applyFilters} />
                  {/* <Button text={'Reset'} type={'clear'} onClick={clearState} /> */}
               </div>
            )}
         </div>
         {isLoadingTenants || isLoading ? (
            <TableLoader />
         ) : (
            <>
               <div className={classes.chartsContainer}>
                  <div className={classes.header}>
                     <div className={classes.left}>
                        <h2>{showExpired ? 'Expired Points' : 'Used Points'}</h2>
                     </div>
                     <div className={cx(classes.right, classes.buttonSwitch)}>
                        <button
                           className={cx(classes.leftButton, {
                              [classes.active]: showExpired,
                              [classes.disabled]: !showExpired,
                           })}
                           onClick={switchCharts}
                        >
                           Expired
                        </button>
                        <button
                           className={cx(classes.rightButton, {
                              [classes.active]: showUsed,
                              [classes.disabled]: !showUsed,
                           })}
                           onClick={switchCharts}
                        >
                           Used
                        </button>
                     </div>
                  </div>
                  {showExpired && barData && <Bar data={barData} options={options} />}
                  {showUsed && lineData && <Line data={lineData} options={options} />}
               </div>
               {showExpired && <ExpiredBalanceTable />}
               {showUsed && <UsedBalanceTable />}
            </>
         )}
      </>
   );
};

export default BalanceChart;
