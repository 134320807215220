import React from 'react';
import Modal from 'react-modal';
import { ModalProp } from '../../../constants/types/modal/types';
import { UserType } from '../../../store/balance/BalanceTypes';
import { checkState, checkValue } from '../../../utils/helpers';
import ModalHeader from '../../modal/modalHeader/ModalHeader';
import DetailsRow from '../../shared/detailsRow/DetailsRow';
import classes from './Balance.module.scss';

type UserDetailsProps = {
   user: UserType | null;
};

type Props = UserDetailsProps & ModalProp;

const UserDetailsModal = ({ user, isOpen, onRequestClose }: Props) => {
   const USER_DETAILS = [
      { keyValue: 'First name', value: checkValue(user?.firstName) },
      { keyValue: 'Last name', value: checkValue(user?.lastName) },
      { keyValue: 'Username', value: checkValue(user?.username) },
      { keyValue: 'Roles', value: checkValue(user?.roles) },
      { keyValue: 'Realm', value: checkValue(user?.realm) },
      { keyValue: 'Email', value: checkValue(user?.email) },
      { keyValue: 'Password update', value: checkState(user?.passwordUpdate) },
      { keyValue: 'Active', value: checkState(user?.active) },
      { keyValue: 'Verified email', value: checkState(user?.verifiedEmail) },
      { keyValue: 'Company name', value: checkValue(user?.companyName) },
      { keyValue: 'Organizational unit', value: checkValue(user?.companyOrgUnit) },
   ];

   return (
      <Modal
         closeTimeoutMS={100}
         className={`modal ${classes.userDetailsModal}`}
         style={{
            overlay: {
               backgroundColor: '#00000080',
               zIndex: 1000,
            },
         }}
         isOpen={isOpen}
         shouldCloseOnOverlayClick={false}
         onRequestClose={onRequestClose}
         contentLabel="User details"
      >
         <ModalHeader onRequestClose={onRequestClose} text={`User details`} />
         <div className={classes.modalWrap}>
            {USER_DETAILS?.map((details: any, i) => {
               return <DetailsRow keyValue={details.keyValue} value={details.value} key={i} />;
            })}
            <div className={classes.balanceInfo}>
               <DetailsRow
                  keyValue="Balance"
                  value={
                     user?.currentBalance ? user?.currentBalance + ' ' + user?.currency : '0'
                  }
               />
            </div>
         </div>
      </Modal>
   );
};

export default UserDetailsModal;
