import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { RevenueActionTypes, RevenueDispatchTypes, RevenueState } from './revenueTypes';

const revenueState: RevenueState = {
   byTime: {
      data: [],
      isLoading: false,
   },
   byCountry: {
      data: [],
      isLoading: false,
   },
   filters: {
      isLoading: false,
      storefronts: [],
   },
};

const revenueReducer: Reducer<RevenueState> = produce(
   (draft: Draft<RevenueState>, action: RevenueDispatchTypes) => {
      switch (action.type) {
         case RevenueActionTypes.GET_REVENUES_BY_TIME_START:
            draft.byTime.isLoading = true;
            return;
         case RevenueActionTypes.GET_REVENUES_BY_TIME_SUCCESS:
            draft.byTime.isLoading = false;
            draft.byTime.data = action.payload;
            return;
         case RevenueActionTypes.GET_REVENUES_BY_TIME_ERROR:
            draft.byTime.isLoading = false;
            return;
         case RevenueActionTypes.GET_REVENUES_BY_COUNTRY_START:
            draft.byCountry.isLoading = true;
            return;
         case RevenueActionTypes.GET_REVENUES_BY_COUNTRY_SUCCESS:
            draft.byCountry.isLoading = false;
            draft.byCountry.data = action.payload;
            return;
         case RevenueActionTypes.GET_REVENUES_BY_COUNTRY_ERROR:
            draft.byCountry.isLoading = false;
            return;
         case RevenueActionTypes.GET_STOREFRONTS_START:
            draft.filters.isLoading = true;
            return;
         case RevenueActionTypes.GET_STOREFRONTS_SUCCESS:
            draft.filters.isLoading = false;
            draft.filters.storefronts = action.payload.map((sf) => ({
               label: sf.storefront,
               value: sf.storefront,
            }));
            return;
         case RevenueActionTypes.GET_STOREFRONTS_ERROR:
            draft.filters.isLoading = false;
            return;
         default:
            return;
      }
   },
   revenueState,
);

export default revenueReducer;
