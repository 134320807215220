import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ServicesActionTypes, ServicesDispatchTypes } from './servicesTypes';
import { ServicesState } from './servicesTypes';

const servicesState: ServicesState = {
   services: {
      data: [],
      pagination: { first: 0, max: 8, totalCount: 0 },
      isLoading: false,
   },
   exchangeRate: {
      data: null,
      isLoading: false,
   },
   packages: {
      data: [],
      isLoading: false,
   },
   serviceCountries: {
      isLoading: false,
      data: [],
   },
   operators: {
      isLoading: false,
      data: [],
   },
};

const servicesReducer: Reducer<ServicesState> = produce(
   (draft: Draft<ServicesState>, action: ServicesDispatchTypes) => {
      switch (action.type) {
         case ServicesActionTypes.GET_SERVICES_START:
            draft.services.isLoading = true;
            return;
         case ServicesActionTypes.GET_SERVICES_SUCCESS:
            draft.services.isLoading = false;
            draft.services.data = action.payload;
            return;
         case ServicesActionTypes.GET_SERVICES_ERROR:
            draft.services.isLoading = false;
            return;
         case ServicesActionTypes.GET_SERVICE_PACKAGES_START:
            draft.packages.isLoading = true;
            return;
         case ServicesActionTypes.GET_SERVICE_PACKAGES_SUCCESS:
            draft.packages.isLoading = false;
            draft.packages.data = action.payload;
            return;
         case ServicesActionTypes.GET_SERVICE_PACKAGES_ERROR:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.GET_EXCHANGE_RATE_START:
            draft.exchangeRate.isLoading = true;
            return;
         case ServicesActionTypes.GET_EXCHANGE_RATE_SUCCESS:
            draft.exchangeRate.isLoading = false;
            draft.exchangeRate.data = action.payload;
            return;
         case ServicesActionTypes.GET_EXCHANGE_RATE_ERROR:
            draft.exchangeRate.isLoading = false;
            return;
         case ServicesActionTypes.CHANGE_EXCHANGE_RATE_START:
            draft.exchangeRate.isLoading = true;
            return;
         case ServicesActionTypes.CHANGE_EXCHANGE_RATE_SUCCESS:
            draft.exchangeRate.isLoading = false;
            return;
         case ServicesActionTypes.CHANGE_EXCHANGE_RATE_ERROR:
            draft.exchangeRate.isLoading = false;
            return;
         case ServicesActionTypes.ADD_PACKAGE_START:
            draft.packages.isLoading = true;
            return;
         case ServicesActionTypes.ADD_PACKAGE_SUCCESS:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.ADD_PACKAGE_ERROR:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.EDIT_PACKAGE_START:
            draft.packages.isLoading = true;
            return;
         case ServicesActionTypes.EDIT_PACKAGE_SUCCESS:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.EDIT_PACKAGE_ERROR:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.DELETE_PACKAGE_START:
            draft.packages.isLoading = true;
            return;
         case ServicesActionTypes.DELETE_PACKAGE_SUCCESS:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.DELETE_PACKAGE_ERROR:
            draft.packages.isLoading = false;
            return;
         case ServicesActionTypes.GET_SERVICE_COUNTRIES_START:
            draft.serviceCountries.isLoading = true;
            return;
         case ServicesActionTypes.GET_SERVICE_COUNTRIES_SUCCESS:
            draft.serviceCountries.isLoading = false;
            draft.serviceCountries.data = action.payload.map((country) => ({
               label: country.name,
               value: country.code,
            }));
            return;
         case ServicesActionTypes.GET_SERVICE_COUNTRIES_ERROR:
            draft.serviceCountries.isLoading = false;
            return;
         case ServicesActionTypes.GET_OPERATORS_START:
            draft.operators.isLoading = true;
            return;
         case ServicesActionTypes.GET_OPERATORS_SUCCESS:
            draft.operators.isLoading = false;
            draft.operators.data = action.payload.map((operator) => ({
               label: operator.name,
               value: operator.code,
            }));
            return;
         case ServicesActionTypes.GET_OPERATORS_ERROR:
            draft.operators.isLoading = false;
            return;
         case ServicesActionTypes.SERVICES_PAGINATION_UPDATE:
            draft.services.pagination.first = action.payload.first;
            draft.services.pagination.max = action.payload.max;
            return;
         case ServicesActionTypes.SERVICES_PAGINATION_TOTAL_COUNT:
            draft.services.pagination.totalCount = action.payload;
            return;
      }
   },
   servicesState,
);

export default servicesReducer;
