import React from 'react';
import ContentLoader from '../../../shared/contentLoader/ContentLoader';
import classes from './TableLoader.module.scss';

const TableLoader = () => {
   return (
      <div className={classes.tableLoader}>
         <ContentLoader style={{ width: '100%' }} />
      </div>
   );
};

export default TableLoader;
