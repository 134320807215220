import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { ModalProp } from '../../../constants/types/modal/types';
import Button from '../../shared/button/Button';
import ModalHeader from '../modalHeader/ModalHeader';
import classes from './DeleteModal.module.scss';

interface DeleteModalProp extends ModalProp {
   errorMessage: string;
   deleteMessage: () => void;
   suffix: string;
}
const DeleteModal = ({
   isOpen,
   onRequestClose,
   onAfterOpen,
   errorMessage,
   deleteMessage,
   suffix,
}: DeleteModalProp) => {
   const buttonRef = useRef<any>(null);

   useEffect(() => {
      if (buttonRef.current) {
         buttonRef.current.focus();
      }
   }, [buttonRef]);
   return (
      <Modal
         closeTimeoutMS={100}
         className={`modal ${classes.deleteModal}`}
         style={{
            overlay: {
               backgroundColor: '#00000080',
               zIndex: 1000,
            },
         }}
         isOpen={isOpen}
         shouldCloseOnOverlayClick={false}
         onRequestClose={onRequestClose}
         contentLabel="Add/Edit Modal"
         onAfterOpen={onAfterOpen}
      >
         <ModalHeader onRequestClose={onRequestClose} text={'Warning'} />

         <div className={classes.modalWrap}>
            <p>
               Are you sure you want to delete{' '}
               <span className={classes.errorMessage}>{`"${errorMessage}"`}</span> {suffix}?
            </p>
         </div>
         <div className={classes.buttonWrap}>
            <Button autoFocus text={'No'} type={'clear'} onClick={onRequestClose} />
            <Button text={'Yes'} type={'primary'} onClick={deleteMessage} />
         </div>
      </Modal>
   );
};

export default DeleteModal;
