export enum SubscribersActionTypes {
   GET_SUBSCRIBERS_START = '@@subscribers/GET_SUBSCRIBERS_START',
   GET_SUBSCRIBERS_SUCCESS = '@@subscribers/GET_SUBSCRIBERS_SUCCESS',
   GET_SUBSCRIBERS_ERROR = '@@subscribers/GET_SUBSCRIBERS_ERROR',

   BLACKLIST_SUBSCRIBERS_START = '@@subscribers/BLACKLIST_SUBSCRIBERS_START',
   BLACKLIST_SUBSCRIBERS_SUCCESS = '@@subscribers/BLACKLIST_SUBSCRIBERS_SUCCESS',
   BLACKLIST_SUBSCRIBERS_ERROR = '@@subscribers/BLACKLIST_SUBSCRIBERS_ERROR',

   UNBLACKLIST_SUBSCRIBERS_START = '@@subscribers/UNBLACKLIST_SUBSCRIBERS_START',
   UNBLACKLIST_SUBSCRIBERS_SUCCESS = '@@subscribers/UNBLACKLIST_SUBSCRIBERS_SUCCESS',
   UNBLACKLIST_SUBSCRIBERS_ERROR = '@@subscribers/UNBLACKLIST_SUBSCRIBERS_ERROR',

   SUBSCRIBERS_PAGINATION_UPDATE = '@@subscribers/SUBSCRIBERS_PAGINATION_UPDATE',
   SUBSCRIBERS_DISABLED_NEXT_PAGE = '@@subscribers/SUBSCRIBERS_DISABLED_NEXT_PAGE',
}

type GetAllSubscribersStart = {
   type: SubscribersActionTypes.GET_SUBSCRIBERS_START;
};

type GetAllSubscribersSuccess = {
   type: SubscribersActionTypes.GET_SUBSCRIBERS_SUCCESS;
   payload: SubscribersType[];
};

type GetAllSubscribersError = {
   type: SubscribersActionTypes.GET_SUBSCRIBERS_ERROR;
};

type BlacklistSubscribersStart = {
   type: SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_START;
};

type BlacklistSubscribersSuccess = {
   type: SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_SUCCESS;
};

type BlacklistSubscribersError = {
   type: SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_ERROR;
};

type UnblacklistSubscribersStart = {
   type: SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_START;
};

type UnblacklistSubscribersSuccess = {
   type: SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_SUCCESS;
};

type UnblacklistSubscribersError = {
   type: SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_ERROR;
};

type SubscribersPaginationUpdate = {
   type: SubscribersActionTypes.SUBSCRIBERS_PAGINATION_UPDATE;
   payload: PaginationType;
};

type SubscribersDisabledNextPage = {
   type: SubscribersActionTypes.SUBSCRIBERS_DISABLED_NEXT_PAGE;
   payload: boolean;
};

export type SubscribersDispatchTypes =
   | GetAllSubscribersStart
   | GetAllSubscribersSuccess
   | GetAllSubscribersError
   | SubscribersPaginationUpdate
   | SubscribersDisabledNextPage
   | BlacklistSubscribersStart
   | BlacklistSubscribersSuccess
   | BlacklistSubscribersError
   | UnblacklistSubscribersStart
   | UnblacklistSubscribersSuccess
   | UnblacklistSubscribersError;

export interface SubscribersState {
   subscribers: {
      data: SubscribersType[];
      isLoading: boolean;
      pagination: SubscribersPagination;
   };
}

export interface SubscribersType {
   msisdn: string;
   email: string;
   identifier: string;
   operator: string;
   firstActivity: string;
   lastActivity: string;
   blacklisted: boolean;
   blacklistedBy: string;
   blacklistReason: string;
   blacklistOrigin: string;
   blacklistTime: string;
}

export type PaginationType = {
   first: number;
   max: number;
};

export type SubscribersPagination = {
   isNextDisabled: boolean;
   first: number;
   max: number;
};
