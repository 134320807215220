import React, { useRef } from 'react';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   LegendItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import ContentLoader from '../../shared/contentLoader/ContentLoader';
import classes from './Revenue.module.scss';
import { renderToStaticMarkup } from 'react-dom/server';

ChartJS.register(
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
);

const RevenueChartByTime = () => {
   const data = useSelector((store: RootStore) => store.revenue.byTime);
   const options = {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 3.2,
      plugins: {
         legend: {
            display: false,
         },
         title: {
            display: false,
         },
      },
   };

   const htmlLegendRef: any = useRef(null);
   const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart: any) {
         const items = chart.options.plugins.legend.labels.generateLabels(chart);
         const legend = (
            <>
               {items.map((item: LegendItem) => (
                  <span key={item.datasetIndex}>
                     <div
                        className={classes.chartLegendCircle}
                        style={{ backgroundColor: item.fillStyle?.toString() }}
                     ></div>
                     {item.text}
                  </span>
               ))}
            </>
         );
         if (htmlLegendRef.current) {
            htmlLegendRef.current.innerHTML = renderToStaticMarkup(legend);
         }
      },
   };

   const labels = data.data.map((d) => d.periodStart);
   const totalRevenue = data.data.map((d) => d.totalRevenue);
   const providerRevenue = data.data.map((d) => d.providerRevenue);
   const currency = data.data[0]?.currency;

   const chartData = {
      labels,
      datasets: [
         {
            label: 'Total revenue',
            data: totalRevenue,
            lineTension: 0.6,
            borderColor: '#f60',
            backgroundColor: '#f60',
         },
         {
            label: 'Provider share',
            data: providerRevenue,
            lineTension: 0.6,
            borderColor: '#0532f0',
            backgroundColor: '#0532f0',
         },
      ],
   };

   return (
      <>
         <h2 className={classes.chartTitle}>Revenue in time {currency && `(${currency})`}</h2>
         {data.isLoading ? (
            <ContentLoader />
         ) : (
            !!data.data.length && (
               <div className={classes.chartWrapper}>
                  <Line options={options} data={chartData} plugins={[htmlLegendPlugin]} />
                  <div ref={htmlLegendRef} className={classes.chartLegendContainer}></div>
               </div>
            )
         )}
      </>
   );
};

export default RevenueChartByTime;
