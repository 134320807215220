import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import KeycloakService from '../../auth/KeycloakService';
import { BalanceActionTypes, BalanceDispatchTypes, PaginationType } from './BalanceTypes';
import API from '../../api/CentiliService';

export const GetUsersPerRealm =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<BalanceDispatchTypes>) => {
      dispatch({
         type: BalanceActionTypes.GET_USERS_PER_REALM_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.balance.getUsersByRealm(first, max, filters, token || '');

         dispatch({
            type: BalanceActionTypes.BALANCE_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: BalanceActionTypes.GET_USERS_PER_REALM_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting users. ${error}`);
         dispatch({
            type: BalanceActionTypes.GET_USERS_PER_REALM_ERROR,
         });
      }
   };

export const GetBalanceChartData =
   (filters: string) => async (dispatch: Dispatch<BalanceDispatchTypes>) => {
      dispatch({
         type: BalanceActionTypes.GET_BALANCE_CHART_DATA_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.balance.getBalanceChartData(filters, token || '');

         dispatch({
            type: BalanceActionTypes.GET_BALANCE_CHART_DATA_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting user's balance. ${error}`);
         dispatch({
            type: BalanceActionTypes.GET_BALANCE_CHART_DATA_ERROR,
         });
      }
   };

export const GetAllTenants = () => async (dispatch: Dispatch<BalanceDispatchTypes>) => {
   dispatch({
      type: BalanceActionTypes.GET_ALL_TENANTS_START,
   });

   try {
      await KeycloakService.updateToken();
      const token = localStorage.getItem('accessToken');

      const response = await API.balance.getAllTenants(token || '');

      dispatch({
         type: BalanceActionTypes.GET_ALL_TENANTS_SUCCESS,
         payload: response.data,
      });
   } catch (error: any) {
      toast.error(`Error getting tenants. ${error}`);
      dispatch({
         type: BalanceActionTypes.GET_ALL_TENANTS_ERROR,
      });
   }
};

export const PaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<BalanceDispatchTypes>) => {
      dispatch({
         type: BalanceActionTypes.BALANCE_PAGINATION_UPDATE,
         payload: pagination,
      });
   };
