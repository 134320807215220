import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import {
   SubscriptionsActionTypes,
   SubscriptionsDispatchTypes,
   SubscriptionsState,
} from './subscriptionsTypes';
const subscriptionsState: SubscriptionsState = {
   subscriptions: {
      data: [],
      isLoading: false,
      pagination: {
         first: 0,
         max: 8,
         totalCount: 8,
      },
   },
};

const subscriptionsReducer: Reducer<SubscriptionsState> = produce(
   (draft: Draft<SubscriptionsState>, action: SubscriptionsDispatchTypes) => {
      switch (action.type) {
         case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_START:
            draft.subscriptions.isLoading = true;
            return;
         case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS:
            draft.subscriptions.isLoading = false;
            draft.subscriptions.data = action.payload;
            return;
         case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_ERROR:
            draft.subscriptions.isLoading = false;
            return;
         case SubscriptionsActionTypes.SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT:
            draft.subscriptions.pagination.totalCount = action.payload;
            return;
         case SubscriptionsActionTypes.SUBSCRIPTIONS_PAGINATION_UPDATE:
            draft.subscriptions.pagination.first = action.payload.first;
            draft.subscriptions.pagination.max = action.payload.max;
            return;
      }
   },
   subscriptionsState,
);

export default subscriptionsReducer;
