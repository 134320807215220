export const SERVICES_ADD_PACKAGE_INPUTS = [
   {
      name: 'amount',
      label: 'Amount',
      placeholder: 'Amount',
      type: 'number',
      required: true,
   },
   {
      name: 'price',
      label: 'Price',
      placeholder: 'Price',
      type: 'number',
      required: true,
   },
   {
      name: 'oldPrice',
      label: 'Old price',
      placeholder: 'Old price',
      type: 'number',
      required: false,
   },
   {
      name: 'unit',
      label: 'Unit',
      placeholder: 'Unit',
      type: 'text',
      required: true,
   },
   {
      name: 'merchantReference',
      label: 'Merchant reference',
      placeholder: 'Merchant reference',
      type: 'text',
      required: false,
   },
];
export const USERS_INPUTS = [
   {
      name: 'keycloakId',
      label: 'User ID',
      type: 'text',
      placeholder: 'User ID',
   },
   {
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Username',
   },
   {
      name: 'email',
      label: 'Email',
      type: 'text',
      placeholder: 'Email',
   },
   {
      name: 'firstName',
      label: 'First name',
      type: 'text',
      placeholder: 'First name',
   },
   {
      name: 'lastName',
      label: 'Last name',
      type: 'text',
      placeholder: 'Last name',
   },
];

export const USER_SUBSCRIPTION_FILTER_INPUTS = [
   {
      name: 'subscriptionId',
      label: 'Subscription ID',
      type: 'text',
      placeholder: 'Subscription ID',
   },
];

export const USER_TRANSACTIONS_FILTER_INPUTS = [
   {
      name: 'transactionId',
      label: 'Transaction ID',
      type: 'text',
      placeholder: 'Transaction ID',
   },
];
