import React, { ReactChild, ReactChildren } from 'react';
interface TableCellProp {
   children: ReactChild | ReactChildren | undefined;
   className?: string;
   colspan?: number;
   onClick?: () => void;
}
const TableCell = ({ onClick, children, className, colspan }: TableCellProp) => {
   return (
      <td onClick={onClick} className={className} colSpan={colspan}>
         {children}
      </td>
   );
};

export default React.memo(TableCell);
