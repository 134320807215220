import React from 'react';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.css';
import classes from './DateRangePickerComponent.module.scss';
import cx from 'classnames';

interface DateRangeProps {
   name: string;
   value: DateRange | null;
   setState: (e: any) => void;
   className?: string;
   placeholder?: string;
   cleanable?: boolean;
   disabled?: boolean;
   format?: string;
}

const DateRangePickerComponent = ({
   name,
   value,
   setState,
   className,
   placeholder,
   cleanable = true,
   disabled = false,
   format = 'yyyy-MM-dd HH:mm',
}: DateRangeProps) => {
   return (
      <DateRangePicker
         className={cx(classes.datePicker, className)}
         placeholder={placeholder || 'Select date'}
         format={format}
         value={value}
         onChange={(dates: DateRange | null) => {
            setState((prevState: any) => ({
               ...prevState,
               [name]: dates,
            }));
         }}
         editable={false}
         disabled={disabled}
         cleanable={cleanable}
      />
   );
};

export default DateRangePickerComponent;
