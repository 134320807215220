import { Dispatch } from 'redux';
import KeycloakService from '../../auth/KeycloakService';
import API from '../../api/CentiliService';
import { toast } from 'react-toastify';
import {
   SubscribersActionTypes,
   SubscribersDispatchTypes,
   PaginationType,
} from './subscribersTypes';

export const GetAllSubscribers =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<SubscribersDispatchTypes>) => {
      dispatch({
         type: SubscribersActionTypes.GET_SUBSCRIBERS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.subscribers.getSubscribers(
            first,
            max,
            filters,
            token || '',
         );

         const next_response = await API.subscribers.getSubscribers(
            first + max,
            max,
            filters,
            token || '',
         );

         next_response.data.length === 0
            ? dispatch({
                 type: SubscribersActionTypes.SUBSCRIBERS_DISABLED_NEXT_PAGE,
                 payload: true,
              })
            : dispatch({
                 type: SubscribersActionTypes.SUBSCRIBERS_DISABLED_NEXT_PAGE,
                 payload: false,
              });

         dispatch({
            type: SubscribersActionTypes.GET_SUBSCRIBERS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting all subscribers. ${error}`);
         dispatch({
            type: SubscribersActionTypes.GET_SUBSCRIBERS_ERROR,
         });
      }
   };

export const BlacklistSubscriber =
   (
      msisdns: string[],
      reason: string,
      subscribersFirst: number,
      subscribersMax: number,
      subscriberFilters: string,
   ) =>
   async (dispatch: Dispatch<SubscribersDispatchTypes>) => {
      dispatch({
         type: SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         await API.subscribers.blacklist(msisdns, reason, token || '');
         dispatch({
            type: SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_SUCCESS,
         });

         dispatch(
            GetAllSubscribers(subscribersFirst, subscribersMax, subscriberFilters) as any,
         );
      } catch (error: any) {
         toast.error(`Error blacklisting users. ${error}`);
         dispatch({
            type: SubscribersActionTypes.BLACKLIST_SUBSCRIBERS_ERROR,
         });
         dispatch(
            GetAllSubscribers(subscribersFirst, subscribersMax, subscriberFilters) as any,
         );
      }
   };

export const UnblacklistSubscriber =
   (
      msisdns: string[],
      subscribersFirst: number,
      subscribersMax: number,
      subscriberFilters: string,
   ) =>
   async (dispatch: Dispatch<SubscribersDispatchTypes>) => {
      dispatch({
         type: SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         await API.subscribers.unblacklist(msisdns, token || '');
         dispatch({
            type: SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_SUCCESS,
         });

         dispatch(
            GetAllSubscribers(subscribersFirst, subscribersMax, subscriberFilters) as any,
         );
      } catch (error: any) {
         toast.error(`Error unblacklisting users. ${error}`);
         dispatch({
            type: SubscribersActionTypes.UNBLACKLIST_SUBSCRIBERS_ERROR,
         });
         dispatch(
            GetAllSubscribers(subscribersFirst, subscribersMax, subscriberFilters) as any,
         );
      }
   };

export const PaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<SubscribersDispatchTypes>) => {
      dispatch({
         type: SubscribersActionTypes.SUBSCRIBERS_PAGINATION_UPDATE,
         payload: pagination,
      });
   };
