import React from 'react';
import classes from './DetailsRow.module.scss';

interface DetailsRowProps {
   keyValue: string;
   value: any;
}

const DetailsRow = ({ keyValue, value }: DetailsRowProps) => {
   return (
      <div className={classes.rowContainer}>
         <div className={classes.key}>{keyValue}</div>
         <div className={classes.value}>{value}</div>
      </div>
   );
};

export default DetailsRow;
