import React, { useState } from 'react';
import UsersFilters from './UsersFilters';
import UsersTable from './UsersTable';

const Users = () => {
   const [state, setState] = useState({
      keycloakId: '',
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      organizationalUnit: [],
   });
   const [exportData, setExportData] = useState([]);

   return (
      <>
         <UsersFilters state={state} setState={setState} setExportData={setExportData} />
         <UsersTable state={state} exportData={exportData} setExportData={setExportData} />
      </>
   );
};

export default Users;
