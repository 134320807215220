import React, { useEffect, useRef, useState } from 'react';
import Button from '../../shared/button/Button';
import classes from './Users.module.scss';
import DateRangePicker from '../../shared/dateRangePicker/DateRangePickerComponent';
import {
   calculateDateNDaysAgo,
   checkValue,
   FormatDate,
   FormatTime,
} from '../../../utils/helpers';
import Input from '../../shared/input/Input';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import {
   USERS_BUNDLE_TRANSACTIONS_HEADERS,
   USER_TRANSACTIONS_HEADERS,
} from '../../../data/tableHeaders';
import Table from '../../table/Table';
import HeaderCell from '../../table/headerCell/HeaderCell';
import { USER_TRANSACTIONS_FILTER_INPUTS } from '../../../data/userMgtInputs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootStore } from '../../../store/rootStore';
import {
   GetBundleParts,
   GetUsersTransactions,
   UsersTransactionsPaginationUpdate,
} from '../../../store/users/usersActions';
import { UserType } from '../../../store/users/usersTypes';
import moment from 'moment';
import queryString from 'query-string';
import cx from 'classnames';
import MultiSelect from 'react-multi-select-component';
import NotificationBadge from '../../shared/notificationBadge/NotificationBadge';
import { getMerchants, getServices } from '../../../store/transactions/transactionsActions';
import TableData from '../../table/tableData/TableData';
import TableCell from '../../table/tableCell/TableCell';
import Pagination from '../../pagination/Pagination';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import {
   TRANSACTIONS_STATUS_OPTIONS,
   TRANSACTIONS_TYPE_OPTIONS,
} from '../../../data/dropdownValues';
import { TransactionType } from '../../../store/transactions/transactionsTypes';
import { CSVLink } from 'react-csv';
import Loader from 'react-loader-spinner';
import { ReactComponent as ArrowIcon } from '../../../assets/images/svg/icon-arrow.svg';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';

const UserTransactions = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const user: UserType = location.state as UserType;
   const storefronts = useSelector((state: RootStore) => state.revenue.filters.storefronts);
   const services = useSelector((store: RootStore) => store.transactions.filters.services);
   const merchants = useSelector((store: RootStore) => store.transactions.filters.merchants);
   const {
      data: transactions,
      isLoading,
      pagination,
   } = useSelector((store: RootStore) => store.users.userTransactions);
   const { bundleParts, isBundlePartsLoading } = useSelector(
      (store: RootStore) => store.users.userTransactions,
   );
   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const [showPriceInEur, setShowPriceInEur] = useState(false);
   const [state, setState] = useState<any>({
      transactionId: '',
      timeChargedLocal: [
         new Date(new Date(calculateDateNDaysAgo(0)).setHours(0, 0, 0, 0)),
         new Date(calculateDateNDaysAgo(0)),
      ],
      service: [],
      merchant: [],
      status: [],
      type: [],
   });
   const [showBundleTransactions, setShowBundleTransactions] = useState<any>({});
   const [loadingBundleTransactionId, setLoadingBundleTransactionId] = useState('');

   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);
   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });

   const parseInputStateHandler = () => {
      const params = {
         storefrontCodes: storefronts[0]?.value,
         emails: user?.email,
         statuses: state.status.map((status: any) => status.value),
         transactionTypes: state.type.map((type: any) => type.value),
         transactionIds: state.transactionId.trim() || undefined,
         serviceKeys: state.service.map((service: any) => service.value),
         accountIds: state.merchant.map((merchant: any) => merchant.value),
         timeChargedLocalFrom: state.timeChargedLocal
            ? moment(state.timeChargedLocal[0]).utc(true).format()
            : undefined,
         timeChargedLocalTo: state.timeChargedLocal
            ? moment(state.timeChargedLocal[1]).utc(true).format()
            : undefined,
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   useEffect(() => {
      dispatch(getMerchants());
      dispatch(getServices());
   }, []);

   useEffect(() => {
      if (!isBundlePartsLoading) {
         setLoadingBundleTransactionId('');
      }
   }, [isBundlePartsLoading]);

   useEffect(() => {
      dispatch(GetUsersTransactions(page.first, page.max, parseInputStateHandler()));
   }, [storefronts]);

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   useEffect(() => {
      dispatch(
         GetUsersTransactions(
            pagination.first,
            selectedRows[0].value,
            parseInputStateHandler(),
         ),
      );
   }, [page, selectedRows]);

   useEffect(() => {
      const bundleTransactions = transactions
         ?.filter((transaction) => transaction.actionCode === 'BUNDLE')
         ?.map((transaction) => transaction.transactionId)
         .reduce((prev, curr) => ({ ...prev, [curr]: false }), {});
      setShowBundleTransactions(bundleTransactions);

      if (pagination.first === 0) {
         setShowPriceInEur(
            transactions?.map((transaction) => transaction.priceInEur).some((value) => value),
         );
      }
   }, [transactions]);

   const clearState = () => {
      setState({
         transactionId: '',
         timeChargedLocal: [
            new Date(new Date(calculateDateNDaysAgo(0)).setHours(0, 0, 0, 0)),
            new Date(calculateDateNDaysAgo(0)),
         ],
         service: [],
         merchant: [],
         status: [],
         type: [],
      });
   };

   const searchTransactions = () => {
      dispatch(GetUsersTransactions(page.first, page.max, parseInputStateHandler()));
   };

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         searchTransactions();
      }
   };

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setState({
         ...state,
         [name]: value,
      });
   };

   const errorStatusMsg = (errorStatus: string) => {
      const capitalizedMsg =
         errorStatus.charAt(0).toUpperCase() + errorStatus.toLowerCase().slice(1);
      return <span className={cx(classes.failedMsg)}> {capitalizedMsg} </span>;
   };
   const successStatusMsg = (successStatus: string) => {
      const capitalizedMsg =
         successStatus.charAt(0).toUpperCase() + successStatus.toLowerCase().slice(1);
      return <span className={cx(classes.successMsg)}> {capitalizedMsg} </span>;
   };

   const checkStatus = (status: string, statusMsg: string) => {
      switch (status) {
         case 'COMPLETED':
            return successStatusMsg(statusMsg);
         case 'FAILED':
            return errorStatusMsg(statusMsg);
         case 'CANCELED':
            return <span>Canceled</span>;
      }
   };

   const notificationStatuses = {
      DELIVERED: 'Delivered',
      FAILED: 'Failed',
      REJECTED: 'Rejected',
      EXCLUDED: 'Excluded',
      PENDING: 'Pending',
   };

   const formatTransactionsForCSV = (transactions: TransactionType[]) => {
      return transactions.map((transaction) => ({
         'Transaction ID': transaction.transactionId,
         Service: transaction.serviceName,
         Package: `${
            transaction?.actionCode !== 'BUNDLE' && transaction.packageQuantity
               ? transaction.packageQuantity
               : ''
         } ${transaction.packageUnitName}`,
         Merchant: transaction.companyName,
         Price: `${transaction.price} ${transaction.currencyCode}`,
         Status: transaction.statusCode,
         Notification: transaction.notificationStatusCode,
         Time: `${FormatDate(transaction.timeChargedLocal)} ${FormatTime(
            transaction.timeChargedLocal,
         )}`,
         Type: transaction.action,
      }));
   };

   const formattedTransactionsData = formatTransactionsForCSV(transactions);

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               {USER_TRANSACTIONS_FILTER_INPUTS.map((input, i) => (
                  <Input
                     key={i}
                     type={input.type}
                     name={input.name}
                     placeholder={input.placeholder}
                     value={state[input.name]}
                     onChange={textInputHandler}
                     onKeyUp={keyUpHandler}
                  />
               ))}
               <NotificationBadge content={state.service}>
                  <MultiSelect
                     options={services}
                     labelledBy="Select"
                     value={state.service}
                     overrideStrings={{ selectSomeItems: 'Service' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           service: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <NotificationBadge content={state.merchant}>
                  <MultiSelect
                     options={merchants}
                     labelledBy="Select"
                     value={state.merchant}
                     overrideStrings={{ selectSomeItems: 'Merchant' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           merchant: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <NotificationBadge content={state.status}>
                  <MultiSelect
                     options={TRANSACTIONS_STATUS_OPTIONS}
                     labelledBy="Select"
                     value={state.status}
                     overrideStrings={{ selectSomeItems: 'Status' }}
                     hasSelectAll={false}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           status: e,
                        });
                     }}
                  />
               </NotificationBadge>
               <div className={classes.filtersDate}>
                  <label>Date: </label>
                  <DateRangePicker
                     name="timeChargedLocal"
                     value={state.timeChargedLocal}
                     setState={setState}
                  />
               </div>
               <NotificationBadge content={state.type}>
                  <MultiSelect
                     options={TRANSACTIONS_TYPE_OPTIONS}
                     labelledBy="Select"
                     value={state.type}
                     overrideStrings={{ selectSomeItems: 'Type' }}
                     hasSelectAll={false}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           type: e,
                        });
                     }}
                  />
               </NotificationBadge>
            </div>
            <div className={classes.filtersRight}>
               <Button text={'Apply'} type={'blue'} onClick={searchTransactions} />
               <Button text={'Reset'} type={'clear'} onClick={clearState} />
            </div>
         </div>
         <div>
            {isLoading ? (
               <TableLoader />
            ) : (
               <>
                  {transactions?.length !== 0 ? (
                     <>
                        <Table className={classes.transactionsTable}>
                           <TableHeader>
                              <TableRow>
                                 <>
                                    <HeaderCell> </HeaderCell>
                                    {USER_TRANSACTIONS_HEADERS.slice(0, 5).map((header, i) => (
                                       <HeaderCell key={i}>{header.text}</HeaderCell>
                                    ))}
                                    {showPriceInEur && (
                                       <>
                                          {USER_TRANSACTIONS_HEADERS.slice(5, 6).map(
                                             (header, i) => (
                                                <HeaderCell key={i}>{header.text}</HeaderCell>
                                             ),
                                          )}
                                       </>
                                    )}
                                    {USER_TRANSACTIONS_HEADERS.slice(6).map((header, i) => (
                                       <HeaderCell key={i}>{header.text}</HeaderCell>
                                    ))}
                                 </>
                              </TableRow>
                           </TableHeader>
                           <TableData>
                              {transactions?.map((transaction: any, i: number) => (
                                 <>
                                    <TableRow key={i}>
                                       <TableCell
                                          className={
                                             showBundleTransactions[
                                                transaction.transactionId
                                             ] && classes.active
                                          }
                                       >
                                          {transaction.actionCode === 'BUNDLE' ? (
                                             <>
                                                {isBundlePartsLoading &&
                                                loadingBundleTransactionId ===
                                                   transaction?.transactionId ? (
                                                   <Loader
                                                      visible={true}
                                                      type="Oval"
                                                      color="blue"
                                                      height={16}
                                                      width={16}
                                                   />
                                                ) : (
                                                   <ArrowIcon
                                                      className={cx(classes.arrowIcon)}
                                                      onClick={() => {
                                                         setShowBundleTransactions({
                                                            ...showBundleTransactions,
                                                            [transaction.transactionId]:
                                                               !showBundleTransactions[
                                                                  transaction.transactionId
                                                               ],
                                                         });
                                                         if (
                                                            !bundleParts?.[
                                                               transaction.transactionId
                                                            ]
                                                         ) {
                                                            dispatch(
                                                               GetBundleParts(
                                                                  transaction.transactionId,
                                                               ),
                                                            );
                                                            setLoadingBundleTransactionId(
                                                               transaction?.transactionId,
                                                            );
                                                         }
                                                      }}
                                                   />
                                                )}
                                             </>
                                          ) : (
                                             <div></div>
                                          )}
                                       </TableCell>
                                       <TableCell>{transaction.transactionId}</TableCell>
                                       <TableCell>{transaction.serviceName}</TableCell>
                                       <TableCell>
                                          <>
                                             {transaction?.actionCode !== 'BUNDLE' &&
                                                transaction?.packageQuantity}{' '}
                                             {transaction?.packageUnitName}
                                          </>
                                       </TableCell>
                                       <TableCell>{transaction.companyName}</TableCell>
                                       <TableCell>
                                          <>
                                             {transaction.price} {transaction.currencyCode}
                                          </>
                                       </TableCell>
                                       <>
                                          {showPriceInEur && (
                                             <TableCell>
                                                {checkValue(transaction?.priceInEur)}
                                             </TableCell>
                                          )}
                                       </>
                                       <TableCell
                                          className={cx(classes.status, {
                                             [classes.canceled]:
                                                transaction.statusCode === 'CANCELED',
                                          })}
                                       >
                                          {checkStatus(
                                             transaction?.statusCode,
                                             transaction?.statusCode === 'COMPLETED'
                                                ? transaction?.statusCode
                                                : transaction?.errorTypeCode ??
                                                     transaction?.statusCode,
                                          )}
                                       </TableCell>
                                       <TableCell
                                          className={cx(classes.status, {
                                             [classes.failed]:
                                                transaction.notificationStatusCode ===
                                                   'FAILED' ||
                                                transaction.notificationStatusCode ===
                                                   'REJECTED',
                                             [classes.delivered]:
                                                transaction.notificationStatusCode ===
                                                'DELIVERED',
                                             [classes.excluded]:
                                                transaction.notificationStatusCode ===
                                                'EXCLUDED',
                                             [classes.pending]:
                                                transaction.notificationStatusCode ===
                                                'PENDING',
                                          })}
                                       >
                                          <span>
                                             {
                                                notificationStatuses[
                                                   transaction.notificationStatusCode
                                                ]
                                             }
                                          </span>
                                       </TableCell>
                                       <TableCell>
                                          <div>
                                             <div>
                                                {FormatDate(transaction.timeChargedLocal)}
                                             </div>
                                             <div className={classes.lowOpacityTimeDate}>
                                                {FormatTime(transaction.timeChargedLocal)}
                                             </div>
                                          </div>
                                       </TableCell>
                                       <TableCell>{transaction.action}</TableCell>
                                    </TableRow>
                                    {showBundleTransactions?.[transaction?.transactionId] &&
                                       loadingBundleTransactionId !==
                                          transaction?.transactionId && (
                                          <>
                                             <TableRow
                                                className={cx(
                                                   classes.bundleTransactionTable,
                                                   classes.bundleHeader,
                                                )}
                                             >
                                                <>
                                                   <TableCell> </TableCell>
                                                   {USERS_BUNDLE_TRANSACTIONS_HEADERS.map(
                                                      (header, j) => (
                                                         <TableCell key={j}>
                                                            {header.text}
                                                         </TableCell>
                                                      ),
                                                   )}
                                                   <TableCell colspan={3}> </TableCell>
                                                </>
                                             </TableRow>
                                             {bundleParts?.[transaction.transactionId]?.map(
                                                (bundlePart: any, j: number) => (
                                                   <TableRow
                                                      key={j}
                                                      className={
                                                         classes.bundleTransactionTable
                                                      }
                                                   >
                                                      <TableCell> </TableCell>
                                                      <TableCell>
                                                         {bundlePart.transactionId}
                                                      </TableCell>
                                                      <TableCell>
                                                         {bundlePart.serviceName}
                                                      </TableCell>
                                                      <TableCell>
                                                         {bundlePart.companyName}
                                                      </TableCell>
                                                      <TableCell>
                                                         <>
                                                            {bundlePart.price}{' '}
                                                            {bundlePart.currencyCode}
                                                         </>
                                                      </TableCell>
                                                      <TableCell
                                                         className={cx(classes.status, {
                                                            [classes.canceled]:
                                                               bundlePart.statusCode ===
                                                               'CANCELED',
                                                         })}
                                                      >
                                                         {checkStatus(
                                                            bundlePart.statusCode,
                                                            bundlePart?.statusCode ===
                                                               'COMPLETED'
                                                               ? bundlePart?.statusCode
                                                               : bundlePart?.errorTypeCode ??
                                                                    bundlePart?.statusCode,
                                                         )}
                                                      </TableCell>
                                                      <TableCell
                                                         className={cx(classes.status, {
                                                            [classes.failed]:
                                                               bundlePart.notificationStatusCode ===
                                                                  'FAILED' ||
                                                               bundlePart.notificationStatusCode ===
                                                                  'REJECTED',
                                                            [classes.delivered]:
                                                               bundlePart.notificationStatusCode ===
                                                               'DELIVERED',
                                                            [classes.excluded]:
                                                               bundlePart.notificationStatusCode ===
                                                               'EXCLUDED',
                                                            [classes.pending]:
                                                               bundlePart.notificationStatusCode ===
                                                               'PENDING',
                                                         })}
                                                      >
                                                         <span>
                                                            {
                                                               notificationStatuses[
                                                                  bundlePart
                                                                     .notificationStatusCode
                                                               ]
                                                            }
                                                         </span>
                                                      </TableCell>
                                                      <TableCell colspan={3}>
                                                         <div>
                                                            <div>
                                                               {FormatDate(
                                                                  bundlePart.timeChargedLocal,
                                                               )}
                                                            </div>
                                                            <div
                                                               className={
                                                                  classes.lowOpacityTimeDate
                                                               }
                                                            >
                                                               {FormatTime(
                                                                  bundlePart.timeChargedLocal,
                                                               )}
                                                            </div>
                                                         </div>
                                                      </TableCell>
                                                   </TableRow>
                                                ),
                                             )}
                                          </>
                                       )}
                                 </>
                              ))}
                           </TableData>
                        </Table>
                        <Pagination
                           first={pagination.first}
                           max={pagination.max}
                           curMax={selectedRows[0].value}
                           isSearching={isLoading}
                           rowValue={selectedRows}
                           setPage={setPage}
                           setSelectedRows={setSelectedRows}
                           totalCount={pagination.totalCount}
                           totalPages={totalPageState}
                           dispatchAction={UsersTransactionsPaginationUpdate}
                           isServerSide={true}
                           onRowChange={(rowsPerPage: number) => {
                              dispatch(
                                 UsersTransactionsPaginationUpdate({
                                    first: 0,
                                    max: rowsPerPage,
                                 }),
                              );
                           }}
                        />
                        {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                           <CSVLink
                              filename={'users-transactions.csv'}
                              ref={csvRef}
                              data={formattedTransactionsData}
                              onClick={() => {
                                 if (csvRef?.current) {
                                    csvRef.current.link.download = `users-transactions-${Date.now()}`;
                                 }
                              }}
                           >
                              <Button
                                 className="export-csv-btn"
                                 type={'blue'}
                                 text="Export as CSV"
                                 hideTooltip={true}
                              />
                           </CSVLink>
                        )}
                     </>
                  ) : (
                     <div className={classes.messageWrap}>
                        There are no results for this search.
                     </div>
                  )}
               </>
            )}
         </div>
      </>
   );
};

export default UserTransactions;
