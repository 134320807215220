import { motion } from 'framer-motion';
import React from 'react';
import Loader from 'react-loader-spinner';
import classes from './Preloader.module.scss';

/* Page loader */

const Preloader = () => {
   return (
      <motion.div
         key="modal"
         animate={{ opacity: 1 }}
         exit={{ x: 500, opacity: 0 }}
         transition={{ duration: 6 }}
         className={classes.preloaderContainer}
      >
         <Loader type="ThreeDots" color="orange" height={200} width={200} />
      </motion.div>
   );
};

export default Preloader;
