import { SubscriptionsType } from '../subscriptions/subscriptionsTypes';
import { TransactionType } from '../transactions/transactionsTypes';

export enum UsersActionTypes {
   GET_USERS_START = '@@users/GET_USERS_START',
   GET_USERS_SUCCESS = '@@users/GET_USERS_SUCCESS',
   GET_USERS_ERROR = '@@users/GET_USERS_ERROR',

   GET_USERS_TRANSACTIONS_START = '@@users/GET_USERS_TRANSACTIONS_START',
   GET_USERS_TRANSACTIONS_SUCCESS = '@@users/GET_USERS_TRANSACTIONS_SUCCESS',
   GET_USERS_TRANSACTIONS_ERROR = '@@users/GET_USERS_TRANSACTIONS_ERROR',

   GET_USERS_SUBSCRIPTIONS_START = '@@users/GET_USERS_SUBSCRIPTIONS_START',
   GET_USERS_SUBSCRIPTIONS_SUCCESS = '@@users/GET_USERS_SUBSCRIPTIONS_SUCCESS',
   GET_USERS_SUBSCRIPTIONS_ERROR = '@@users/GET_USERS_SUBSCRIPTIONS_ERROR',

   DELETE_USER_START = '@@users/DELETE_USER_START',
   DELETE_USER_SUCCESS = '@@users/DELETE_USER_SUCCESS',
   DELETE_USER_ERROR = '@@users/DELETE_USER_ERROR',

   UPDATE_USER_START = '@@users/UPDATE_USER_START',
   UPDATE_USER_SUCCESS = '@@users/UPDATE_USER_SUCCESS',
   UPDATE_USER_ERROR = '@@users/UPDATE_USER_ERROR',

   GET_ORGANIZATIONAL_UNITS_START = '@@users/GET_ORGANIZATIONAL_UNITS_START',
   GET_ORGANIZATIONAL_UNITS_SUCCESS = '@@users/GET_ORGANIZATIONAL_UNITS_SUCCESS',
   GET_ORGANIZATIONAL_UNITS_ERROR = '@@users/GET_ORGANIZATIONAL_UNITS_ERROR',

   GET_BUNDLE_PARTS_START = '@@transactions/GET_BUNDLE_PARTS_START',
   GET_BUNDLE_PARTS_SUCCESS = '@@transactions/GET_BUNDLE_PARTS_SUCCESS',
   GET_BUNDLE_PARTS_ERROR = '@@transactions/GET_BUNDLE_PARTS_ERROR',

   USERS_PAGINATION_UPDATE = '@@transactions/USERS_PAGINATION_UPDATE',
   USERS_PAGINATION_TOTAL_COUNT = '@@transactions/USERS_PAGINATION_TOTAL_COUNT',
   USERS_TRANSACTIONS_PAGINATION_UPDATE = '@@transactions/USERS_TRANSACTIONS_PAGINATION_UPDATE',
   USERS_TRANSACTIONS_PAGINATION_TOTAL_COUNT = '@@transactions/USERS_TRANSACTIONS_PAGINATION_TOTAL_COUNT',
   USERS_SUBSCRIPTIONS_PAGINATION_UPDATE = '@@transactions/USERS_SUBSCRIPTIONS_PAGINATION_UPDATE',
   USERS_SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT = '@@transactions/USERS_SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT',
}

type GetAllUsersStart = {
   type: UsersActionTypes.GET_USERS_START;
};

type GetAllUsersSuccess = {
   type: UsersActionTypes.GET_USERS_SUCCESS;
   payload: UserType[];
};

type GetAllUsersError = {
   type: UsersActionTypes.GET_USERS_ERROR;
};

type GetUsersTransactionsStart = {
   type: UsersActionTypes.GET_USERS_TRANSACTIONS_START;
};

type GetUsersTransactionsSuccess = {
   type: UsersActionTypes.GET_USERS_TRANSACTIONS_SUCCESS;
   payload: TransactionType[];
};

type GetUsersTransactionsError = {
   type: UsersActionTypes.GET_USERS_TRANSACTIONS_ERROR;
};

type GetUsersSubscriptionsStart = {
   type: UsersActionTypes.GET_USERS_SUBSCRIPTIONS_START;
};

type GetUsersSubscriptionsSuccess = {
   type: UsersActionTypes.GET_USERS_SUBSCRIPTIONS_SUCCESS;
   payload: SubscriptionsType[];
};

type GetUsersSubscriptionsError = {
   type: UsersActionTypes.GET_USERS_SUBSCRIPTIONS_ERROR;
};

type DeleteUserStart = {
   type: UsersActionTypes.DELETE_USER_START;
};

type DeleteUserSuccess = {
   type: UsersActionTypes.DELETE_USER_SUCCESS;
};

type DeleteUserError = {
   type: UsersActionTypes.DELETE_USER_ERROR;
};

type UpdateUserStart = {
   type: UsersActionTypes.UPDATE_USER_START;
};

type UpdateUserSuccess = {
   type: UsersActionTypes.UPDATE_USER_SUCCESS;
};

type UpdateUserError = {
   type: UsersActionTypes.UPDATE_USER_ERROR;
};

type GetOrganizationalUnitsStart = {
   type: UsersActionTypes.GET_ORGANIZATIONAL_UNITS_START;
};

type GetOrganizationalUnitsSuccess = {
   type: UsersActionTypes.GET_ORGANIZATIONAL_UNITS_SUCCESS;
   payload: any[];
};

type GetOrganizationalUnitsError = {
   type: UsersActionTypes.GET_ORGANIZATIONAL_UNITS_ERROR;
};

type GetBundlePartsStart = {
   type: UsersActionTypes.GET_BUNDLE_PARTS_START;
};

type GetBundlePartsSuccess = {
   type: UsersActionTypes.GET_BUNDLE_PARTS_SUCCESS;
   payload: any;
};

type GetBundlePartsError = {
   type: UsersActionTypes.GET_BUNDLE_PARTS_ERROR;
};

type UsersTransactionsPaginationUpdate = {
   type: UsersActionTypes.USERS_TRANSACTIONS_PAGINATION_UPDATE;
   payload: PaginationType;
};

type UsersTransactionsPaginationTotalCount = {
   type: UsersActionTypes.USERS_TRANSACTIONS_PAGINATION_TOTAL_COUNT;
   payload: number;
};

type UsersSubscriptionsPaginationUpdate = {
   type: UsersActionTypes.USERS_SUBSCRIPTIONS_PAGINATION_UPDATE;
   payload: PaginationType;
};

type UsersSubscriptionsPaginationTotalCount = {
   type: UsersActionTypes.USERS_SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT;
   payload: number;
};

type UsersPaginationUpdate = {
   type: UsersActionTypes.USERS_PAGINATION_UPDATE;
   payload: PaginationType;
};

type UsersPaginationTotalCount = {
   type: UsersActionTypes.USERS_PAGINATION_TOTAL_COUNT;
   payload: number;
};

export type UsersDispatchTypes =
   | GetAllUsersStart
   | GetAllUsersSuccess
   | GetAllUsersError
   | GetUsersTransactionsStart
   | GetUsersTransactionsSuccess
   | GetUsersTransactionsError
   | GetUsersSubscriptionsStart
   | GetUsersSubscriptionsSuccess
   | GetUsersSubscriptionsError
   | DeleteUserStart
   | DeleteUserSuccess
   | DeleteUserError
   | UpdateUserStart
   | UpdateUserSuccess
   | UpdateUserError
   | GetOrganizationalUnitsStart
   | GetOrganizationalUnitsSuccess
   | GetOrganizationalUnitsError
   | GetBundlePartsStart
   | GetBundlePartsSuccess
   | GetBundlePartsError
   | UsersPaginationUpdate
   | UsersPaginationTotalCount
   | UsersTransactionsPaginationUpdate
   | UsersTransactionsPaginationTotalCount
   | UsersSubscriptionsPaginationUpdate
   | UsersSubscriptionsPaginationTotalCount;

export interface UsersState {
   users: {
      data: UserType[];
      isLoading: boolean;
      pagination: UsersPagination;
   };
   organizationalUnits: {
      data: any[];
      isLoading: boolean;
   };
   userTransactions: {
      data: TransactionType[];
      isLoading: boolean;
      bundleParts: {
         [key: string]: TransactionType[];
      } | null;
      isBundlePartsLoading: boolean;
      pagination: UsersPagination;
   };
   userSubscriptions: {
      data: SubscriptionsType[];
      isLoading: boolean;
      pagination: UsersPagination;
   };
}

export interface UserType {
   id: string;
   email: string;
   username: string;
   firstName: string | null;
   lastName: string | null;
   companyName: string | null;
   companyOrgUnit: string | null;
   realm: string;
   active: boolean;
   verifiedEmail: boolean;
   passwordUpdate: string | null;
   roles: string[];
   balance: number | null;
   currentBalance: number | null;
   usedBalance: number | null;
   expiredBalance: number | null;
   currency: string | null;
   reference: string | null;
}

export type UsersPagination = {
   totalCount: number;
   first: number;
   max: number;
};

export type PaginationType = {
   first: number;
   max: number;
};
