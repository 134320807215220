import React, { useState } from 'react';
import { calculateDateNDaysAgo } from '../../../utils/helpers';
import SubscriptionsFilters from './SubscriptionsFilters';
import SubscriptionsTable from './SubscriptionsTable';

const Subscriptions = () => {
   const [state, setState] = useState<any>({
      subscriptionId: '',
      subscriber: '',
      country: [],
      dateSubscribedLocal: [
         new Date(new Date(calculateDateNDaysAgo(0)).setHours(0, 0, 0, 0)),
         new Date(calculateDateNDaysAgo(0)),
      ],
      dateUnsubscribedLocal: null,
      price: [],
      service: [],
      merchant: [],
      clientId: '',
   });
   const [exportData, setExportData] = useState([]);

   return (
      <>
         <SubscriptionsFilters
            state={state}
            setState={setState}
            setExportData={setExportData}
         />
         <SubscriptionsTable
            state={state}
            exportData={exportData}
            setExportData={setExportData}
         />
      </>
   );
};

export default Subscriptions;
