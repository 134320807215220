import Keycloak from 'keycloak-js';
import { authenticateAction } from '../store/auth/authActions';
import store from '../store/rootStore';
import { ALL_ROUTES } from '../constants/routes/routes';

// const keycloak = Keycloak(
//    window.location.href.includes('https://admin.cent-inet.com') ||
//       window.location.href.includes('http://admin.cent-inet.com') ||
//       window.location.href.includes('http://10.76.160') ||
//       window.location.href.includes('http://127.0.0.1')
//       ? `/keycloak-production.json`
//       : `/keycloak.json`,
// );

const keycloak = Keycloak(
   ALL_ROUTES.some((route) => window.location.href.includes(route))
      ? `/keycloak-production.json`
      : `/keycloak.json`,
);

/*
   Initialize Application
   If Keycloak auth passes, render App, using callback func.
*/
const initKeycloak = (onAuthenticatedCallback: () => void) => {
   keycloak
      .init({
         onLoad: 'login-required',
         checkLoginIframe: false,
      })
      .then((authenticated) => {
         if (authenticated) {
            onAuthenticatedCallback();
            store.dispatch(authenticateAction(keycloak) as any);
            localStorage.setItem('accessToken', keycloak.token || '');
         } else {
            console.warn('not authenticated!!!');
         }
      });
};

const updateToken = async () => {
   await keycloak
      .updateToken(15)
      .then((refreshed) => {
         if (refreshed) {
            localStorage.removeItem('accessToken');
            localStorage.setItem('accessToken', keycloak.token || '');
         }
      })
      .catch(() => console.log('Failed to refresh the token'));
};

//Keycloak login function
const loginKeycloak = keycloak.login;

//Keycloak logout function
const logoutKeycloak = () => {
   localStorage.removeItem('logged');
   localStorage.removeItem('accessToken');
   return keycloak.logout();
};

export default {
   initKeycloak,
   loginKeycloak,
   logoutKeycloak,
   updateToken,
};
