import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { AuthActionTypes, AuthDispatchTypes, AuthState } from './authTypes';

const authState: AuthState = {
   keycloak: {
      isAuthenticated: false,
      roles: [],
   },
   user: {
      userTypes: [],
      actions: [],
   },
};

const authReducer: Reducer<AuthState> = produce(
   (draft: Draft<AuthState>, action: AuthDispatchTypes) => {
      switch (action.type) {
         case AuthActionTypes.AUTH_START:
            return;

         case AuthActionTypes.AUTH_SUCCESS: {
            const { authenticated, clientId, realm, realmAccess, tokenParsed } =
               action.payload.keycloak;

            const { userTypes, actions } = action.payload.user;

            draft.keycloak.isAuthenticated = authenticated;
            draft.keycloak.realm = realm;
            draft.keycloak.clientId = clientId;
            draft.keycloak.roles = realmAccess?.roles;
            draft.keycloak.username = tokenParsed?.preferred_username;
            draft.keycloak.name = tokenParsed?.name;
            //User state
            draft.user!.userTypes = userTypes;
            draft.user!.actions = actions.map((el) => el.code);
            return;
         }

         case AuthActionTypes.AUTH_ERROR: {
            draft.keycloak.isAuthenticated = true;
            draft.error = action.payload;
            return;
         }

         default:
            return;
      }
   },
   authState,
);

export default authReducer;
