import React, { ReactChild, ReactChildren } from 'react';
interface TableDataProp {
   children: ReactChild | ReactChildren | JSX.Element[];
   className?: string;
}

const TableData = ({ children }: TableDataProp) => {
   return <tbody>{children}</tbody>;
};

export default React.memo(TableData);
