import React, { useEffect, useRef, useState } from 'react';
import classes from './Balance.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import { UserType } from '../../../store/balance/BalanceTypes';
import TableLoader from '../../shared/pageContent/tableLoader/TableLoader';
import Button from '../../shared/button/Button';
import Table from '../../table/Table';
import TableHeader from '../../table/tableHeader/TableHeader';
import TableRow from '../../table/tableRow/TableRow';
import HeaderCell from '../../table/headerCell/HeaderCell';
import TableData from '../../table/tableData/TableData';
import TableCell from '../../table/tableCell/TableCell';
import { checkValue } from '../../../utils/helpers';
import { ReactComponent as IconEye } from '../../../assets/images/svg/icon-eye.svg';
import { GetUsersPerRealm, PaginationUpdate } from '../../../store/balance/BalanceActions';
import Input from '../../shared/input/Input';
import { USERS_HEADER } from '../../../data/tableHeaders';
import Pagination from '../../pagination/Pagination';
import UserDetailsModal from './UserDetailsModal';
import queryString from 'query-string';
import { BalanceProps } from './BalanceChart';
import { CSVLink } from 'react-csv';
import { UserRoleActions } from '../../../constants/userActions/userRoleActions';
import API from '../../../api/CentiliService';
import KeycloakService from '../../../auth/KeycloakService';
import { toast } from 'react-toastify';

const BalanceUsers = ({ state, setState, exportData, setExportData }: BalanceProps) => {
   const dispatch = useDispatch();

   const users = useSelector(
      (store: RootStore) => store.balance.users.data[0]?.storefrontUsers,
   );
   const totalCurrentBalance = useSelector(
      (store: RootStore) => store.balance.users.data[0]?.totalCurrentBalance,
   );
   const totalExpiredBalance = useSelector(
      (store: RootStore) => store.balance.users.data[0]?.totalExpiredBalance,
   );
   const totalUsedBalance = useSelector(
      (store: RootStore) => store.balance.users.data[0]?.totalUsedBalance,
   );
   const isLoadingTenants = useSelector((store: RootStore) => store.balance.tenants.isLoading);
   const { isLoading, pagination } = useSelector((store: RootStore) => store.balance.users);
   const actionsUser = useSelector((state: RootStore) => state.auth.user?.actions as string[]);

   const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

   const [isExportDataLoading, setIsExportDataLoading] = useState(false);

   const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
   const [detailsModalOpen, setDetailsModalOpen] = useState(false);
   const [selectedRows, setSelectedRows] = useState<any>([{ label: '8', value: 8 }]);
   const [totalPageState, setTotalPageState] = useState(0);

   const [page, setPage] = useState({
      first: pagination.first,
      max: pagination.max,
   });

   useEffect(() => {
      setTotalPageState(Math.ceil(pagination.totalCount / selectedRows[0].value));
   }, [pagination.totalCount, selectedRows]);

   const parseStateHandler = () => {
      const params = {
         realm: state.realm.map((code: any) => {
            if (code.value.startsWith('SF')) return code.value;

            return `SF_${code.value}`;
         }),
         username: state.username.trim() || undefined,
         reference: state.reference.trim() || undefined,
         companyName: state.companyName.trim() || undefined,
      };
      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   const searchUsers = () => {
      state.realm.length &&
         dispatch(GetUsersPerRealm(0, selectedRows[0].value, parseStateHandler()));

      setExportData([]);
   };

   useEffect(() => {
      state.realm.length && searchUsers();
   }, [state.realm, selectedRows]);

   useEffect(() => {
      state.realm.length &&
         dispatch(
            GetUsersPerRealm(pagination.first, selectedRows[0].value, parseStateHandler()),
         );
   }, [page]);

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         dispatch(PaginationUpdate({ first: 0, max: selectedRows[0].value }));
         dispatch(GetUsersPerRealm(0, selectedRows[0].value, parseStateHandler()));
      }
   };

   const clearState = () => {
      setState({
         ...state,
         username: '',
         reference: '',
         companyName: '',
      });
   };

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;

      setState({ ...state, [name]: value });
   };

   const formatUsersForCSV = (users: UserType[]) => {
      let formattedData = users.map((user) => ({
         Username: user.username,
         'First Name': user.firstName,
         'Last Name': user.lastName,
         'Client Id': user.reference,
         'Company Name': user.companyName,
         Realm: user.realm,
         'Used Points': user.usedBalance.toString(),
         'Expired Points': user.expiredBalance.toString(),
         'Current Points': user.currentBalance.toString(),
      }));

      formattedData = [
         ...formattedData,
         {
            Username: '',
            'First Name': '',
            'Last Name': '',
            'Client Id': '',
            'Company Name': '',
            Realm: '',
            'Used Points': '',
            'Expired Points': '',
            'Current Points': '',
         },
         {
            Username: '',
            'First Name': '',
            'Last Name': '',
            'Client Id': '',
            'Company Name': '',
            Realm: '',
            'Used Points': totalUsedBalance?.toString(),
            'Expired Points': totalExpiredBalance?.toString(),
            'Current Points': totalCurrentBalance?.toString(),
         },
      ];

      return formattedData;
   };

   const getExportData = () => {
      const getData = async () => {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         setIsExportDataLoading(true);
         await API.balance
            .getUsersByRealmForExport(
               0,
               pagination.totalCount < 10000 ? pagination.totalCount : 10000,
               parseStateHandler(),
               token || '',
            )
            .then((response) => {
               setExportData(formatUsersForCSV(response.data[0]?.storefrontUsers || []));
               setIsExportDataLoading(false);
               return csvRef?.current?.link.click();
            })
            .catch(() => {
               setIsExportDataLoading(false);
               toast.error('Error getting users.');
            });
      };

      getData();
   };
   return (
      <>
         {state.realm.length !== 0 && (
            <div className={classes.filters}>
               <div className={classes.filtersLeft}>
                  {/* <MultiSelect
                  isLoading={isLoadingTenants}
                  disabled={false}
                  className={cx(
                     classes.filtersInput,
                     classes.multiSelect,
                     'singleInput multi-select realm',
                  )}
                  overrideStrings={{ selectSomeItems: 'Select realm' }}
                  hasSelectAll={false}
                  options={tenants}
                  value={state.realm}
                  onChange={(e: any) => {
                     if (e.length === 0) {
                        setState({
                           ...state,
                           realm: [],
                           username: '',
                        });
                        return;
                     }

                     const newValue = e[e.length - 1] || e;
                     setState({ ...state, realm: [newValue], username: '' });
                     cancelDropdown('realm');
                  }}
                  labelledBy={'Select'}
               /> */}
                  <Input
                     type="text"
                     value={state.username}
                     name="username"
                     placeholder="Enter username"
                     onKeyUp={keyUpHandler}
                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setState({ ...state, username: e.target.value });
                     }}
                  />
                  <Input
                     type="text"
                     value={state.reference}
                     name="reference"
                     placeholder="Enter client id"
                     onKeyUp={keyUpHandler}
                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setState({ ...state, reference: e.target.value });
                     }}
                  />
                  <Input
                     type="text"
                     value={state.companyName}
                     name="companyName"
                     className={classes.filtersInput}
                     placeholder="Company name"
                     onKeyUp={keyUpHandler}
                     onChange={textInputHandler}
                  />
               </div>
               <div className={classes.filtersRight}>
                  <Button text={'Apply'} type={'blue'} onClick={searchUsers} />
                  <Button text={'Reset'} type={'clear'} onClick={clearState} />
               </div>
            </div>
         )}
         <div className={classes.usersTable}>
            {isLoading || isLoadingTenants ? (
               <TableLoader />
            ) : (
               <>
                  {state.realm?.length !== 0 && (
                     <>
                        {users?.length === 0 ? (
                           <div className={classes.messageWrap}>
                              There are no found users for this tenant.
                           </div>
                        ) : (
                           <>
                              <Table>
                                 <TableHeader>
                                    <TableRow>
                                       <>
                                          {USERS_HEADER.map((header, i) => (
                                             <HeaderCell key={i}>{header.text}</HeaderCell>
                                          ))}
                                          <HeaderCell> </HeaderCell>
                                       </>
                                    </TableRow>
                                 </TableHeader>
                                 <TableData>
                                    <>
                                       {users?.map((user: UserType, i: number) => (
                                          <TableRow key={i}>
                                             <TableCell>{user?.username}</TableCell>
                                             <TableCell>{user?.firstName}</TableCell>
                                             <TableCell>{user?.lastName}</TableCell>
                                             <TableCell>
                                                {checkValue(user?.reference)}
                                             </TableCell>
                                             <TableCell>
                                                {checkValue(user?.companyName)}
                                             </TableCell>
                                             <TableCell>{user?.realm}</TableCell>
                                             <TableCell>{user?.usedBalance}</TableCell>
                                             <TableCell>{user?.expiredBalance}</TableCell>
                                             <TableCell>{user?.currentBalance}</TableCell>
                                             <TableCell>
                                                <div className={classes.iconsContainer}>
                                                   <IconEye
                                                      className={classes.eyeIcon}
                                                      onClick={() => {
                                                         setSelectedUser(user);
                                                         setDetailsModalOpen(true);
                                                      }}
                                                   />
                                                </div>
                                             </TableCell>
                                          </TableRow>
                                       ))}
                                       <TableRow className={classes.totals}>
                                          <td colSpan={6}>
                                             <div>
                                                <div>Total Users</div>
                                                <div>{pagination.totalCount}</div>
                                             </div>
                                          </td>
                                          <td>
                                             <div>
                                                <div>Total Used Points</div>
                                                <div>{totalUsedBalance}</div>
                                             </div>
                                          </td>
                                          <td>
                                             <div>
                                                <div>Total Expired Points</div>
                                                <div>{totalExpiredBalance}</div>
                                             </div>
                                          </td>
                                          <td>
                                             <div>
                                                <div>Total Points</div>
                                                <div>{totalCurrentBalance}</div>
                                             </div>
                                          </td>
                                          <td className={classes.iconsContainer}> </td>
                                       </TableRow>
                                    </>
                                 </TableData>
                              </Table>
                              <Pagination
                                 first={pagination.first}
                                 max={pagination.max}
                                 curMax={selectedRows[0].value}
                                 isSearching={isLoading}
                                 rowValue={selectedRows}
                                 setPage={setPage}
                                 setSelectedRows={setSelectedRows}
                                 totalCount={pagination.totalCount}
                                 totalPages={totalPageState}
                                 dispatchAction={PaginationUpdate}
                                 isServerSide={true}
                                 onRowChange={(rowsPerPage: number) => {
                                    dispatch(PaginationUpdate({ first: 0, max: rowsPerPage }));
                                 }}
                              />
                              {actionsUser.includes(UserRoleActions.EXPORT_VIEW_ACTION) && (
                                 <>
                                    {exportData.length === 0 ? (
                                       <a download>
                                          <Button
                                             className="export-csv-btn"
                                             type={'template'}
                                             text={'Export as CSV'}
                                             onClick={getExportData}
                                             showLoader={isExportDataLoading}
                                          />
                                       </a>
                                    ) : (
                                       <CSVLink
                                          filename={'balance.csv'}
                                          data={exportData}
                                          ref={csvRef}
                                          onClick={() => {
                                             if (csvRef?.current) {
                                                csvRef.current.link.download = `balance-${Date.now()}`;
                                             }
                                          }}
                                       >
                                          <Button
                                             className="export-csv-btn"
                                             type={'blue'}
                                             text="Export as CSV"
                                             hideTooltip={true}
                                          />
                                       </CSVLink>
                                    )}
                                 </>
                              )}
                           </>
                        )}
                     </>
                  )}
               </>
            )}
            <UserDetailsModal
               user={selectedUser}
               isOpen={detailsModalOpen}
               onRequestClose={() => setDetailsModalOpen(false)}
            />
         </div>
      </>
   );
};

export default BalanceUsers;
