import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import classes from './InputLabel.module.scss';

interface InputLabel {
   inputState: string | number;
   label: string;
}

/* Input Label - Displays label above input when typing */
const InputLabel = ({ inputState, label }: InputLabel) => {
   return (
      <>
         <AnimatePresence>
            {String(inputState).length >= 1 && (
               <motion.span
                  initial={{ opacity: 0, y: 2 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 2 }}
                  transition={{ duration: 0.2 }}
                  className={classes.inputLabel}
               >
                  {label}
               </motion.span>
            )}
         </AnimatePresence>
      </>
   );
};

export default InputLabel;
