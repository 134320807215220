import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { USERS_INPUTS } from '../../../data/userMgtInputs';
import Button from '../../shared/button/Button';
import Input from '../../shared/input/Input';
import classes from './Users.module.scss';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import { GetAllUsers } from '../../../store/users/usersActions';
import NotificationBadge from '../../shared/notificationBadge/NotificationBadge';

type Props = {
   state: any;
   setState: (e: any) => void;
   setExportData: (e: any) => void;
};

const UsersFilters = ({ state, setState, setExportData }: Props) => {
   const dispatch = useDispatch();
   const organizationalUnits = useSelector(
      (store: RootStore) => store.users.organizationalUnits.data,
   );
   const pagination = useSelector((store: RootStore) => store.users.users.pagination);

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setState({
         ...state,
         [name]: value,
      });
   };

   const clearState = () => {
      setState({
         keycloakId: '',
         username: '',
         email: '',
         firstName: '',
         lastName: '',
         organizationalUnit: [],
      });
   };

   const parseInputStateHandler = () => {
      const params = {
         keycloakId: state.keycloakId.trim() || undefined,
         username: state.username.trim() || undefined,
         email: state.email.trim() || undefined,
         lastName: state.lastName.trim() || undefined,
         firstName: state.firstName.trim() || undefined,
         organizationalUnit: state.organizationalUnit.map((unit: any) => unit.value),
      };

      const queryParams = queryString.stringify(params);
      return queryParams;
   };

   const searchUsers = () => {
      dispatch(GetAllUsers(0, pagination.max, parseInputStateHandler()));
      setExportData([]);
   };

   const keyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
         searchUsers();
      }
   };

   return (
      <>
         <div className={classes.filters}>
            <div className={classes.filtersLeft}>
               {USERS_INPUTS.slice(0, 5).map((input, i) => (
                  <Input
                     key={i}
                     type={input.type}
                     name={input.name}
                     placeholder={input.placeholder}
                     value={state[input.name]}
                     onChange={textInputHandler}
                     onKeyUp={keyUpHandler}
                  />
               ))}
               <NotificationBadge content={state.organizationalUnit}>
                  <MultiSelect
                     options={organizationalUnits}
                     labelledBy="Select"
                     value={state.organizationalUnit}
                     hasSelectAll={false}
                     overrideStrings={{ selectSomeItems: 'Organizational unit' }}
                     onChange={(e: any) => {
                        setState({
                           ...state,
                           organizationalUnit: e,
                        });
                     }}
                  />
               </NotificationBadge>
            </div>
            <div className={classes.filtersRight}>
               <Button text={'Apply'} type={'blue'} onClick={searchUsers} />
               <Button text={'Reset'} type={'clear'} onClick={clearState} />
            </div>
         </div>
      </>
   );
};

export default UsersFilters;
