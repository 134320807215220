import React, { useRef } from 'react';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   LegendItem,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store/rootStore';
import classes from './Revenue.module.scss';
import { renderToStaticMarkup } from 'react-dom/server';
import ContentLoader from '../../shared/contentLoader/ContentLoader';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RevenueChartByCountry = () => {
   const { data, isLoading } = useSelector((store: RootStore) => store.revenue.byCountry);
   const revenues = data;
   const countries = useSelector((store: RootStore) => store.transactions.filters.countries);
   const currency = data[0]?.currency;

   const findCountryName = (countryCode: string) => {
      return countries.find((country) => country.value === countryCode)?.label;
   };

   const labels = revenues.map(
      (revenue: any) => findCountryName(revenue.countryCode) || revenue.countryCode,
   );

   const options = {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 3.2,
      plugins: {
         legend: {
            display: false,
         },
      },
   };

   const htmlLegendRef: any = useRef(null);
   const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart: any) {
         const items = chart.options.plugins.legend.labels.generateLabels(chart);
         const legend = (
            <>
               {items.map((item: LegendItem) => (
                  <span key={item.datasetIndex}>
                     <div
                        className={classes.chartLegendCircle}
                        style={{ backgroundColor: item.fillStyle?.toString() }}
                     ></div>
                     {item.text}
                  </span>
               ))}
            </>
         );
         if (htmlLegendRef.current) {
            htmlLegendRef.current.innerHTML = renderToStaticMarkup(legend);
         }
      },
   };

   const chartData = {
      labels,
      datasets: [
         {
            label: 'Provider Share',
            data: revenues.map((revenue: any) => revenue.providerRevenue),
            fill: true,
            backgroundColor: '#0532F0',
         },
         {
            label: 'Total Revenue',
            data: revenues.map((revenue: any) => revenue.totalRevenue),
            backgroundColor: '#F60',
         },
      ],
   };

   return (
      <>
         <h2 className={classes.chartTitle}>
            Revenue by country {currency && `(${currency})`}
         </h2>
         {isLoading ? (
            <ContentLoader />
         ) : (
            !!revenues.length && (
               <div className={classes.chartWrapper}>
                  <Bar options={options} data={chartData} plugins={[htmlLegendPlugin]}></Bar>
                  <div ref={htmlLegendRef} className={classes.chartLegendContainer}></div>
               </div>
            )
         )}
      </>
   );
};

export default RevenueChartByCountry;
