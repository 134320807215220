import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ButtonLink.module.scss';

interface ButtonLinkProp {
   to: string;
   text: string;
}

const ButtonLink = ({ to, text }: ButtonLinkProp) => {
   return (
      <Link className={classes.buttonLink} to={to}>
         {text}
      </Link>
   );
};

export default ButtonLink;
