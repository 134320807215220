import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import MultiSelect from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProp } from '../../../constants/types/modal/types';
import { RootStore } from '../../../store/rootStore';
import { cancelDropdown } from '../../../utils/cancelDropdown';
import Button from '../../shared/button/Button';
import Input from '../../shared/input/Input';
import ModalHeader from '../modalHeader/ModalHeader';
import classes from './AddNewPackageModal.module.scss';
import cx from 'classnames';
import { checkString } from '../../../utils/helpers';
import { motion } from 'framer-motion';
import { storefrontAddPackageInputVerification } from '../../../utils/inputsVerification';
import { ServicePackageType, ServiceType } from '../../../store/services/servicesTypes';
import { getOperators, getServiceCountries } from '../../../store/services/servicesActions';
import {
   PACKAGES_INTERVAL_OPTIONS,
   PAYMENT_METHOD_TYPE_OPTIONS,
} from '../../../data/dropdownValues';
import { SERVICES_ADD_PACKAGE_INPUTS } from '../../../data/userMgtInputs';
import { Dispatch } from 'redux';

interface Props {
   data: ServicePackageType | null;
   service: ServiceType;
   serviceType: string;
   addPackage: (body: any, serviceType: string) => (dispatch: Dispatch<any>) => void;
   editPackage: (body: any, serviceType: string) => (dispatch: Dispatch<any>) => void;
}

type PackageModalProp = ModalProp & Props;

const AddNewPackageModal = ({
   isOpen,
   onRequestClose,
   service,
   serviceType,
   addPackage,
   editPackage,
   data,
}: PackageModalProp) => {
   const editData = data;

   const dispatch = useDispatch();
   const { data: countries, isLoading: isLoadingCountries } = useSelector(
      (store: RootStore) => store.services.serviceCountries,
   );
   const { data: operators, isLoading: isLoadingOperators } = useSelector(
      (store: RootStore) => store.services.operators,
   );

   const [requireOperator, setRequireOperator] = useState(false);
   const [disableOperator, setDisableOperator] = useState(true);

   const [state, setState] = useState<any>({
      amount: '',
      price: '',
      paymentMethod: [],
      oldPrice: '',
      unit: '',
      country: [],
      operator: [],
      merchantReference: '',
      interval: [],
   });

   const [errors, setErrors] = useState<any>({
      amount: '',
      price: '',
      paymentMethod: '',
      oldPrice: '',
      unit: '',
      country: '',
      operator: '',
      merchantReference: '',
      interval: '',
   });

   useEffect(() => {
      service && dispatch(getServiceCountries(service?.serviceKey));
   }, [service]);

   useEffect(() => {
      if (isOpen) {
         setErrors({
            amount: '',
            price: '',
            paymentMethod: '',
            oldPrice: '',
            unit: '',
            country: '',
            operator: '',
            merchantReference: '',
            interval: '',
         });

         if (editData) {
            setState({
               ...editData,
               oldPrice: editData.oldPrice || '',
               merchantReference: editData.merchantReference || '',
               paymentMethod: [
                  {
                     label: editData.paymentMethod,
                     value: editData.paymentMethod,
                  },
               ],
               country: [
                  {
                     label: editData.countryName,
                     value: editData.countryCode,
                  },
               ],
               interval: editData.interval
                  ? [
                       {
                          label: editData.interval,
                          value: editData.interval,
                       },
                    ]
                  : [],
               operator: editData.operatorCode
                  ? [
                       {
                          label: editData.operatorName,
                          value: editData.operatorCode,
                       },
                    ]
                  : [],
            });

            if (editData.paymentMethod === 'WALLET') {
               setDisableOperator(false);
               setRequireOperator(true);
               dispatch(getOperators('WALLET', editData.countryCode));
            } else if (editData.paymentMethod === 'MOBILE') {
               setDisableOperator(true);
               setRequireOperator(false);
            } else {
               setDisableOperator(false);
               setRequireOperator(false);
               dispatch(getOperators(editData.paymentMethod, editData.countryCode));
            }
         } else {
            setState({
               amount: '',
               price: '',
               paymentMethod: [],
               oldPrice: '',
               unit: '',
               country: [],
               operator: [],
               merchantReference: '',
               interval: [],
            });

            setDisableOperator(true);
            setRequireOperator(false);
         }
      }
   }, [isOpen, editData]);

   useEffect(() => {
      state.paymentMethod[0] &&
         state.country[0] &&
         dispatch(getOperators(state.paymentMethod[0].value, state.country[0].value));
   }, [state.paymentMethod, state.country]);

   const addNewPackageHandler = () => {
      const errorVerification =
         service && storefrontAddPackageInputVerification(serviceType, state);
      setErrors(errorVerification);
      if (Object.keys(errorVerification).length !== 0) return;

      const body = {
         id: editData ? editData.id : null,
         serviceKey: service?.serviceKey,
         price: state.price,
         countryCode: state.country[0].value,
         countryName: state.country[0].label,
         paymentMethod: state.paymentMethod[0].value,
         oldPrice: state.oldPrice || null,
         merchantReference: checkString(state.merchantReference),
         operatorCode: state.operator[0] ? state.operator[0].value : null,
         operatorName: state.operator[0] ? state.operator[0].label : null,
         unit: serviceType === 'ONE_TIME' ? state.unit : null,
         amount: serviceType === 'ONE_TIME' ? state.amount : null,
         interval: serviceType === 'SUBSCRIPTION' ? state.interval[0].value : null,
      };

      !editData
         ? dispatch(addPackage(body, serviceType))
         : dispatch(editPackage(body, serviceType));

      onRequestClose();
   };

   const textInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length > 0) {
         setErrors({ ...errors, [e.target.name]: '' });
      }

      setState({
         ...state,
         [e.target.name]: e.target.value,
      });
   };

   return (
      <Modal
         isOpen={isOpen}
         onRequestClose={onRequestClose}
         closeTimeoutMS={100}
         style={{
            overlay: {
               background: '#00000080',
               zIndex: 1000,
            },
            content: {
               overflow: 'visible',
            },
         }}
         className={`modal`}
         contentLabel={editData ? 'Edit package' : 'Add new package'}
      >
         <ModalHeader
            text={editData ? 'Edit package' : 'Add new package'}
            onRequestClose={onRequestClose}
         />
         <div className={classes.modalWrap}>
            <p className={classes.mandatoryFieldsLabel}>
               Fields marked with an asterisk (<span className={classes.mandatoryStar}>*</span>
               ) are required.
            </p>
            <div className={classes.contentWrapper}>
               <div className={classes.leftContainer}>
                  {serviceType === 'ONE_TIME' && (
                     <>
                        {SERVICES_ADD_PACKAGE_INPUTS.slice(0, 1).map((inputs, i) => (
                           <div className={classes.inputWrap} key={i}>
                              {inputs.required && (
                                 <span className={classes.mandatoryStar}>* </span>
                              )}
                              <label>{inputs.label}</label>
                              {errors[inputs.name] && (
                                 <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                    className={classes.errorText}
                                 >
                                    {errors[inputs.name]}
                                 </motion.span>
                              )}
                              <Input
                                 name={inputs.name}
                                 value={state[inputs.name]}
                                 type={inputs.type}
                                 placeholder={inputs.placeholder}
                                 onChange={textInputHandler}
                                 min={'0'}
                              />
                           </div>
                        ))}
                     </>
                  )}
                  {SERVICES_ADD_PACKAGE_INPUTS.slice(1, 2).map((inputs, i) => (
                     <div className={classes.inputWrap} key={i}>
                        {inputs.required && <span className={classes.mandatoryStar}>* </span>}
                        <label>{inputs.label}</label>
                        {errors[inputs.name] && (
                           <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              className={classes.errorText}
                           >
                              {errors[inputs.name]}
                           </motion.span>
                        )}
                        <Input
                           name={inputs.name}
                           value={state[inputs.name]}
                           type={inputs.type}
                           placeholder={inputs.placeholder}
                           onChange={textInputHandler}
                           min={'0'}
                        />
                     </div>
                  ))}
                  <div className={classes.inputWrap}>
                     <span className={classes.mandatoryStar}>* </span>
                     <label>Payment method</label>
                     {errors.paymentMethod && (
                        <motion.span
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           transition={{ duration: 0.3 }}
                           className={classes.errorText}
                        >
                           {errors.paymentMethod}
                        </motion.span>
                     )}
                     <MultiSelect
                        options={PAYMENT_METHOD_TYPE_OPTIONS}
                        value={state.paymentMethod}
                        labelledBy={'Select'}
                        hasSelectAll={false}
                        disableSearch={true}
                        className={'singleInput type closePaymentMethod singleInputStorefront'}
                        onChange={(e: any) => {
                           if (e.length === 0) {
                              setState({
                                 ...state,
                                 paymentMethod: [],
                              });
                              setDisableOperator(true);
                              setRequireOperator(false);
                              setErrors({
                                 ...errors,
                                 operator: '',
                              });
                              return;
                           }

                           const newValue = e[e.length - 1] || e;

                           if (newValue.value === 'WALLET') {
                              state.country[0] && setDisableOperator(false);
                              setRequireOperator(true);
                           } else if (newValue.value === 'MOBILE') {
                              setDisableOperator(true);
                              setRequireOperator(false);
                              setState({
                                 ...state,
                                 operator: [],
                              });
                              setErrors({
                                 ...errors,
                                 operator: '',
                              });
                           } else {
                              state.country[0] && setDisableOperator(false);
                              setRequireOperator(false);
                              setErrors({
                                 ...errors,
                                 operator: '',
                              });
                           }

                           setState({
                              ...state,
                              paymentMethod: [newValue],
                           });

                           cancelDropdown('closePaymentMethod');
                        }}
                     />
                  </div>
                  {SERVICES_ADD_PACKAGE_INPUTS.slice(2, 3).map((inputs, i) => (
                     <div className={classes.inputWrap} key={i}>
                        {inputs.required && <span className={classes.mandatoryStar}>* </span>}
                        <label>{inputs.label}</label>
                        {errors[inputs.name] && (
                           <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              className={classes.errorText}
                           >
                              {errors[inputs.name]}
                           </motion.span>
                        )}
                        <Input
                           name={inputs.name}
                           value={state[inputs.name]}
                           type={inputs.type}
                           onChange={textInputHandler}
                           placeholder={inputs.placeholder}
                           min={'0'}
                        />
                     </div>
                  ))}
                  {serviceType === 'SUBSCRIPTION' && (
                     <div className={classes.inputWrap}>
                        <span className={classes.mandatoryStar}>* </span>
                        <label>Interval</label>
                        {errors.interval && (
                           <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              className={classes.errorText}
                           >
                              {errors.interval}
                           </motion.span>
                        )}
                        <MultiSelect
                           options={PACKAGES_INTERVAL_OPTIONS}
                           value={state.interval}
                           labelledBy={'Select'}
                           hasSelectAll={false}
                           disableSearch={true}
                           className={'singleInput type closeInterval singleInputStorefront'}
                           onChange={(e: any) => {
                              if (e.length === 0) {
                                 setState({
                                    ...state,
                                    interval: [],
                                 });
                                 return;
                              }

                              const newValue = e[e.length - 1] || e;

                              setState({
                                 ...state,
                                 interval: [newValue],
                              });

                              cancelDropdown('closeInterval');
                           }}
                        />
                     </div>
                  )}
               </div>
               <div className={classes.rightContainer}>
                  {serviceType === 'ONE_TIME' && (
                     <>
                        {SERVICES_ADD_PACKAGE_INPUTS.slice(3, 4).map((inputs, i) => (
                           <div className={classes.inputWrap} key={i}>
                              {inputs.required && (
                                 <span className={classes.mandatoryStar}>* </span>
                              )}
                              <label>{inputs.label}</label>
                              {errors[inputs.name] && (
                                 <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                    className={classes.errorText}
                                 >
                                    {errors[inputs.name]}
                                 </motion.span>
                              )}
                              <Input
                                 name={inputs.name}
                                 value={state[inputs.name]}
                                 type={inputs.type}
                                 onChange={textInputHandler}
                                 placeholder={inputs.placeholder}
                              />
                           </div>
                        ))}
                     </>
                  )}
                  <div className={classes.inputWrap}>
                     <span className={classes.mandatoryStar}>* </span>
                     <label>Country</label>
                     {errors.country && (
                        <motion.span
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           transition={{ duration: 0.3 }}
                           className={classes.errorText}
                        >
                           {errors.country}
                        </motion.span>
                     )}
                     <MultiSelect
                        options={countries}
                        value={state.country}
                        isLoading={isLoadingCountries}
                        labelledBy={'Select'}
                        hasSelectAll={false}
                        className={'singleInput type closeCountries singleInputStorefront'}
                        onChange={(e: any) => {
                           if (e.length === 0) {
                              setState({
                                 ...state,
                                 country: [],
                              });
                              setDisableOperator(true);
                              setRequireOperator(false);
                              return;
                           }

                           const newValue = e[e.length - 1] || e;

                           state.paymentMethod[0] &&
                              state.paymentMethod[0].value !== 'MOBILE' &&
                              setDisableOperator(false);

                           setState({
                              ...state,
                              country: [newValue],
                           });

                           cancelDropdown('closeCountries');
                        }}
                     />
                  </div>
                  <div className={classes.inputWrap}>
                     {requireOperator && <span className={classes.mandatoryStar}>* </span>}
                     <label>Operator name</label>
                     {errors.operator && (
                        <motion.span
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           transition={{ duration: 0.3 }}
                           className={classes.errorText}
                        >
                           {errors.operator}
                        </motion.span>
                     )}
                     <MultiSelect
                        options={operators}
                        value={state.operator}
                        isLoading={isLoadingOperators}
                        labelledBy={'Select'}
                        hasSelectAll={false}
                        className={cx('singleInput type closeOperator singleInputStorefront', {
                           disabled: disableOperator,
                        })}
                        onChange={(e: any) => {
                           if (e.length === 0) {
                              setState({
                                 ...state,
                                 operator: [],
                              });
                              return;
                           }

                           const newValue = e[e.length - 1] || e;

                           setState({
                              ...state,
                              operator: [newValue],
                           });

                           cancelDropdown('closeOperator');
                        }}
                        disabled={disableOperator}
                     />
                  </div>
                  {SERVICES_ADD_PACKAGE_INPUTS.slice(4, 5).map((inputs, i) => (
                     <div className={classes.inputWrap} key={i}>
                        {inputs.required && <span className={classes.mandatoryStar}>* </span>}
                        <label>{inputs.label}</label>
                        {errors[inputs.name] && (
                           <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              className={classes.errorText}
                           >
                              {errors[inputs.name]}
                           </motion.span>
                        )}
                        <Input
                           name={inputs.name}
                           value={state[inputs.name]}
                           type={inputs.type}
                           onChange={textInputHandler}
                           placeholder={inputs.placeholder}
                        />
                     </div>
                  ))}
               </div>
            </div>
            <div className={classes.buttonWrap}>
               <Button text={'Cancel'} onClick={onRequestClose} type={'clear'} />
               <Button text={'Save'} onClick={addNewPackageHandler} type={'primary'} />
            </div>
         </div>
      </Modal>
   );
};

export default AddNewPackageModal;
