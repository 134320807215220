import React, { useEffect, useState } from 'react';
import { ModalProp } from '../../../constants/types/modal/types';
import Modal from 'react-modal';
import ModalHeader from '../modalHeader/ModalHeader';
import classes from './TextboxModal.module.scss';
import Button from '../../shared/button/Button';
import Textarea from '../../shared/textarea/Textarea';

interface Props {
   text: string;
   onConfirm: (s: string) => void;
   onCancel: () => void;
   headerText: string;
   confirmText?: string;
   required?: boolean;
}

type TextboxProps = Props & ModalProp;

const TextboxModal = ({
   isOpen,
   onRequestClose,
   text,
   onConfirm,
   onCancel,
   headerText,
   confirmText = 'Confirm',
   required = false,
}: TextboxProps) => {
   const [inputText, setInputText] = useState<string>('');
   const [isConfirmDisabled, setIsConfirmDisabled] = useState(required);

   const textInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setInputText(value);

      if (required) {
         if (value.trim()) {
            setIsConfirmDisabled(false);
         } else {
            setIsConfirmDisabled(true);
         }
      }
   };

   useEffect(() => {
      if (!isOpen) {
         setInputText('');
         required && setIsConfirmDisabled(true);
      }
   }, [isOpen]);

   return (
      <Modal
         isOpen={isOpen}
         onRequestClose={onRequestClose}
         closeTimeoutMS={100}
         style={{
            overlay: {
               backgroundColor: '#00000080',
               zIndex: 1000,
            },
         }}
         className={`modal`}
         contentLabel="Input modal"
         shouldCloseOnEsc={false}
         shouldCloseOnOverlayClick={false}
      >
         <ModalHeader text={headerText} onRequestClose={onRequestClose} />
         <div className={classes.modalWrap}>
            {text}
            <div className={classes.textAreaWrap}>
               <Textarea value={inputText} onChange={textInputHandler} />
            </div>
            <div className={classes.buttonWrap}>
               <Button text={'Cancel'} type={'clear'} onClick={onCancel} />
               <Button
                  text={confirmText}
                  type={'primary'}
                  onClick={() => {
                     onConfirm(inputText.trim());
                     setInputText('');
                  }}
                  disabled={isConfirmDisabled}
               />
            </div>
         </div>
      </Modal>
   );
};

export default TextboxModal;
