import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import KeyCloakService from '../../../auth/KeycloakService';
import Avatar from './avatar/Avatar';
import classes from './TopMenu.module.scss';

/* Avatar Top Menu with dropdown options. */
const TopMenu = ({
   username,
   fullName,
}: {
   username: string | undefined;
   fullName: string | undefined;
}) => {
   const [arrowUp, setArrowUp] = useState(false);
   return (
      <>
         <div className={cx(classes.wrap, { [classes.active]: arrowUp })}>
            <OutsideClickHandler
               onOutsideClick={() => {
                  setArrowUp(false);
               }}
            >
               <div className={classes.topMenu}>
                  <div className={classes.topMenuText}>
                     <h3>{username}</h3>
                     <h5>{fullName}</h5>
                  </div>
                  <Avatar onClick={() => setArrowUp(!arrowUp)} arrowUp={arrowUp} />
                  {arrowUp && (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2 }}
                        className={classes.dropdown}
                     >
                        <a href={'https://www.centili.com/'}>Help</a>
                        <a onClick={() => KeyCloakService.logoutKeycloak()}>Logout</a>
                     </motion.div>
                  )}
               </div>
            </OutsideClickHandler>
         </div>
      </>
   );
};

export default TopMenu;
