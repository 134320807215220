import { Dispatch } from 'redux';
import KeycloakService from '../../auth/KeycloakService';
import API from '../../api/CentiliService';
import {
   PaginationType,
   SubscriptionsActionTypes,
   SubscriptionsDispatchTypes,
} from './subscriptionsTypes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GetAllSubscriptions =
   (first: number, max: number, filters: string) =>
   async (dispatch: Dispatch<SubscriptionsDispatchTypes>) => {
      dispatch({
         type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         const response = await API.subscriptions.getAllSubscriptions(
            first,
            max,
            filters,
            token || '',
         );
         dispatch({
            type: SubscriptionsActionTypes.SUBSCRIPTIONS_PAGINATION_TOTAL_COUNT,
            payload: parseInt(response.headers['x-total-count']),
         });

         dispatch({
            type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS,
            payload: response.data,
         });
      } catch (error: any) {
         toast.error(`Error getting all subscriptions. ${error}`);
         dispatch({
            type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_ERROR,
         });
      }
   };

export const Unsubscribe =
   (subscriptionId: string) => async (dispatch: Dispatch<SubscriptionsDispatchTypes>) => {
      dispatch({
         type: SubscriptionsActionTypes.UNSUBSCRIBE_START,
      });

      try {
         await KeycloakService.updateToken();
         const token = localStorage.getItem('accessToken');

         await API.subscriptions.unsubscribe(subscriptionId, token || '');

         dispatch({
            type: SubscriptionsActionTypes.UNSUBSCRIBE_SUCCESS,
         });

         toast.dark(`Unsubscription pending for subscription ${subscriptionId}`);
      } catch (error: any) {
         toast.error(`Error unsubscribing. ${error}`);
         dispatch({
            type: SubscriptionsActionTypes.UNSUBSCRIBE_ERROR,
         });
      }
   };

export const PaginationUpdate =
   (pagination: PaginationType) => (dispatch: Dispatch<SubscriptionsDispatchTypes>) => {
      dispatch({
         type: SubscriptionsActionTypes.SUBSCRIPTIONS_PAGINATION_UPDATE,
         payload: pagination,
      });
   };
