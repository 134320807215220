interface InputState {
   message_key_id?: { key: string } | Record<string, unknown> | string;
   language?: { label: string; longCode: string; value: string }[] | [] | string;
   message?: string;
   name: string;
   id?: string;
   context?: {
      context: string;
   };
}

interface StorefrontCategoryInputState {
   order: string;
   description: string;
   categoryKey: { label: string; value: string }[] | [] | string;
}

interface StorefrontPaymentsInputState {
   name: string;
   order: number;
   countryCode: { label: string; value: string }[] | [] | string;
   code: number;
   type: { label: string; value: string }[] | [];
   emailSepParams: string[];
   operatorCode: { label: string; value: string }[] | [];
   providerCode: { label: string; value: string }[] | [];
}

export interface StorefrontPriceRangeInputState {
   min: number;
   max: number;
   step: number;
}

export interface StorefrontSinglePriceInputState {
   prices: number;
}
interface StorefrontPaymentsParametersState {
   columnType: { label: string; value: string }[] | [] | string;
}

interface StorefrontServiceState {
   serviceCategoryOrder: string;
}

interface StorferontAddPackageInputState {
   amount: number;
   unit: string;
   price: number;
   country: { label: string; value: string }[] | [];
   operator: { label: string; value: string }[] | [];
   paymentMethod: { label: string; value: string }[] | [];
   interval: { label: string; value: string }[] | [];
}

interface StorefrontAddServiceWidgetInputState {
   country: { label: string; value: string }[] | [];
   labelComponent: { label: string; value: string }[] | [];
   paymentWidgetTemplate: { label: string; value: string }[] | [];
   css: string;
   font: string;
}

interface StorefrontAddMerchantIntegrationParamsInputs {
   name: string;
   position: string;
   paramType: { label: string; value: string }[] | [];
   enumValues: string[] | [];
}

interface StorefrontAddEditTenantInputState {
   code: string;
   domain: string;
   name: string;
   defaultLanguage: { label: string; longCode: string; value: string }[] | [] | string;
   defaultCountry: { label: string; longCode: string; value: string }[] | [] | string;
   languageCodes: any;
   activationDate: any;
   smtpHost: string;
   smtpPort: number;
   smtpSender: string;
   css: string;
   categoryServicesRowSize: number;
   categoryNameType: { label: string; longCode: string; value: string }[] | [] | string;
   useActiveServicesComponent:
      | { label: string; longCode: string; value: string }[]
      | []
      | string;
   activeServicesHeader: { label: string; longCode: string; value: string }[] | [] | string;
   useLabelComponent: { label: string; longCode: string; value: string }[] | [] | string;
   font: string;
   paginationLayout: { label: string; longCode: string; value: string }[] | [] | string;
   paymentSignKey: string;
}

/*Verification function 
Takes inputs values as parameter and return error object if inputs don't match 
 */

export const inputsVerification = (inputState: InputState) => {
   const { message_key_id, language, message, name, id, context } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('message_key_id' in inputState) {
      if (Object.keys(message_key_id!).length === 0) {
         errors.message_key_id = 'Please enter key';
      }
   }
   if ('language' in inputState) {
      if (language!.length === 0) {
         errors.language = 'Please enter language';
      }
   }
   if ('message' in inputState) {
      if (message!.length === 0) {
         errors.message = 'Please enter message';
      }
   }

   if ('name' in inputState) {
      if (name!.length === 0) {
         errors.name = 'Please enter name';
      }
   }

   if ('id' in inputState) {
      if (id!.length === 0) {
         errors.id = 'Please enter code';
      }

      if (id!.length !== 2) {
         errors.id = 'Code must be 2 letters long';
      }
   }

   if ('context' in inputState) {
      if (context!.context.length === 0) {
         errors.context = 'Please enter context';
      }
   }

   return errors;
};

export const storefrontCategoriesInputsVerification = (
   inputState: StorefrontCategoryInputState,
) => {
   const { order, description, categoryKey } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('order' in inputState) {
      if (order!.length === 0) {
         errors.order = 'Please enter order';
      }
   }
   if ('description' in inputState) {
      if (description!.length === 0) {
         errors.description = 'Please enter description';
      }
   }
   if ('categoryKey' in inputState) {
      if (categoryKey!.length === 0) {
         errors.categoryKey = 'Please select Category Key';
      }
   }

   return errors;
};

export const storefrontMerchantIntegrationParamsInputsVerification = (
   inputState: StorefrontAddMerchantIntegrationParamsInputs,
) => {
   const { name, position, enumValues, paramType } = inputState;
   const errors: any = {};

   if ('position' in inputState) {
      if (position!.length === 0) {
         errors.position = 'Please enter position';
      }
   }
   if ('name' in inputState) {
      if (name!.trim().length === 0) {
         errors.name = 'Please enter name';
      }
   }
   if ('paramType' in inputState) {
      if (paramType!.length === 0) {
         errors.paramType = 'Please select parameter type';
      } else {
         if (paramType[0].value === 'Enum') {
            if ('enumValues' in inputState) {
               if (enumValues!.length === 0) {
                  errors.enumValues = 'Please enter enum values';
               }
            }
         }
      }
   }

   return errors;
};

export const storefrontPaymentsInputsVerification = (
   inputState: StorefrontPaymentsInputState,
) => {
   const { name, order, countryCode, code, type, emailSepParams, operatorCode, providerCode } =
      inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('name' in inputState) {
      if (name!.length === 0) {
         errors.name = 'Please enter name';
      }
   }
   if ('order' in inputState) {
      if (!order) {
         errors.order = 'Please enter order';
      }
   }
   if ('countryCode' in inputState) {
      if (countryCode!.length === 0) {
         errors.countryCode = 'Please select country';
      }
   }
   if ('code' in inputState) {
      if (!code) {
         errors.code = 'Please enter code';
      }
   }
   if ('type' in inputState) {
      if (type!.length === 0) {
         errors.type = 'Please select type';
      }
   }
   if ('emailSepParams' in inputState) {
      if (emailSepParams!.length === 0) {
         errors.emailSepParams = 'Please add email sep params';
      }
   }
   if (type[0].value === 'WALLET') {
      if ('operatorCode' in inputState) {
         if (operatorCode!.length === 0) {
            errors.operatorCode = 'Please select operator';
         }
      }
   }
   if (type[0].value === 'CC') {
      if ('providerCode' in inputState) {
         if (providerCode!.length === 0) {
            errors.providerCode = 'Please select provider';
         }
      }
   }

   return errors;
};

export const storefrontPriceRangeInputsVerification = (
   inputState: StorefrontPriceRangeInputState,
) => {
   const { min, max, step } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('min' in inputState) {
      if (!min) {
         errors.min = 'Please enter start price';
      }
   }
   if ('max' in inputState) {
      if (!max) {
         errors.max = 'Please enter end price';
      }
   }
   if ('step' in inputState) {
      if (!step) {
         errors.step = 'Please add value to offset';
      }
   }

   return errors;
};

export const storefrontAddServiceWidgetInputsVerification = (
   inputState: StorefrontAddServiceWidgetInputState,
) => {
   const { country, labelComponent, css, font, paymentWidgetTemplate } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('country' in inputState) {
      if (country!.length === 0) {
         errors.country = 'Please select country';
      }
   }
   if ('labelComponent' in inputState) {
      if (labelComponent!.length === 0) {
         errors.labelComponent = 'Please select label component';
      }
   }
   if ('paymentWidgetTemplate' in inputState) {
      if (paymentWidgetTemplate!.length === 0) {
         errors.paymentWidgetTemplate = 'Please select payment widget template';
      }
   }
   if ('css' in inputState) {
      if (css!.trim().length === 0) {
         errors.css = 'Please add css';
      }
   }
   if ('font' in inputState) {
      if (font!.trim().length === 0) {
         errors.font = 'Please add font';
      }
   }

   return errors;
};

export const storefrontAddSinglePriceInputVerification = (
   inputState: StorefrontSinglePriceInputState,
) => {
   const { prices } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('prices' in inputState) {
      if (!prices) {
         errors.prices = 'Please enter price';
      }
   }

   return errors;
};

export const storefrontPaymentsAddParameterInputVerification = (
   inputState: StorefrontPaymentsParametersState,
) => {
   const { columnType } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('columnType' in inputState) {
      if (columnType!.length === 0) {
         errors.columnType = 'Please select column type';
      }
   }

   return errors;
};

export const storefrontServiceEditInputVerification = (inputState: StorefrontServiceState) => {
   const { serviceCategoryOrder } = inputState;

   const errors: any = {};

   if ('serviceCategoryOrder' in inputState) {
      if (serviceCategoryOrder === null || serviceCategoryOrder!.length === 0) {
         errors.serviceCategoryOrder = 'Please enter service category order';
      }
   }

   return errors;
};

export const storefrontAddPackageInputVerification = (
   serviceType: string,
   inputState: StorferontAddPackageInputState,
) => {
   const { amount, unit, price, country, paymentMethod, operator, interval } = inputState;

   const errors: any = {};

   if (serviceType === 'ONE_TIME') {
      if ('amount' in inputState) {
         if (!amount) {
            errors.amount = 'Please enter amount';
         }
      }
      if ('unit' in inputState) {
         if (unit!.length === 0) {
            errors.unit = 'Please enter unit';
         }
      }
   }
   if (serviceType === 'SUBSCRIPTION') {
      if ('interval' in inputState) {
         if (interval!.length === 0) {
            errors.interval = 'Please select interval';
         }
      }
   }
   if ('price' in inputState) {
      if (!price) {
         errors.price = 'Please enter price';
      }
   }
   if ('country' in inputState) {
      if (country!.length === 0) {
         errors.country = 'Please select country';
      }
   }
   if ('paymentMethod' in inputState) {
      if (paymentMethod!.length === 0) {
         errors.paymentMethod = 'Please select payment method';
      } else {
         if (paymentMethod[0].value === 'WALLET') {
            if ('operator' in inputState) {
               if (operator!.length === 0) {
                  errors.operator = 'Please select operator';
               }
            }
         }
      }
   }

   return errors;
};

export const storefrontAddEditTenantInputsVerification = (
   inputState: StorefrontAddEditTenantInputState,
) => {
   const {
      code,
      domain,
      name,
      defaultLanguage,
      defaultCountry,
      activationDate,
      smtpHost,
      smtpPort,
      smtpSender,
      css,
      categoryServicesRowSize,
      categoryNameType,
      useActiveServicesComponent,
      activeServicesHeader,
      useLabelComponent,
      font,
      paymentSignKey,
   } = inputState;
   const errors: any = {};

   // Check if property exist on object itself
   if ('code' in inputState) {
      if (code!.length === 0) {
         errors.code = 'Please enter code';
      }
   }
   if ('domain' in inputState) {
      if (domain!.length === 0) {
         errors.domain = 'Please enter domain';
      }
   }
   if ('name' in inputState) {
      if (name!.length === 0) {
         errors.name = 'Please enter name';
      }
   }
   if ('defaultLanguage' in inputState) {
      if (defaultLanguage!.length === 0) {
         errors.defaultLanguage = 'Please select default language';
      }
   }
   if ('defaultCountry' in inputState) {
      if (defaultCountry!.length === 0) {
         errors.defaultCountry = 'Please select default Country';
      }
   }
   if ('activationDate' in inputState) {
      if (activationDate === null || activationDate!.length === 0) {
         errors.activationDate = 'Please set activation date';
      }
   }
   if ('paymentSignKey' in inputState) {
      if (paymentSignKey!.length === 0) {
         errors.paymentSignKey = 'Please set payment sign key';
      }
   }
   if ('smtpHost' in inputState) {
      if (smtpHost === null || smtpHost!.length === 0) {
         errors.smtpHost = 'Please set SMTP host';
      }
   }
   if ('smtpPort' in inputState) {
      if (!smtpPort) {
         errors.smtpPort = 'Please set SMTP port';
      }
   }
   if ('smtpSender' in inputState) {
      if (smtpSender === null || smtpSender!.length === 0) {
         errors.smtpSender = 'Please set SMTP sender';
      }
   }
   if ('css' in inputState) {
      if (css!.length === 0) {
         errors.css = 'Please set CSS url';
      }
   }
   if ('categoryServicesRowSize' in inputState) {
      if (!categoryServicesRowSize) {
         errors.categoryServicesRowSize = 'Please set category services row size';
      }
   }
   if ('categoryNameType' in inputState) {
      if (categoryNameType!.length === 0) {
         errors.categoryNameType = 'Please set category name type';
      }
   }
   if ('useActiveServicesComponent' in inputState) {
      if (useActiveServicesComponent!.length === 0) {
         errors.useActiveServicesComponent = 'Please set active services component';
      }
   }
   if ('activeServicesHeader' in inputState) {
      if (activeServicesHeader!.length === 0) {
         errors.activeServicesHeader = 'Please set active services header';
      }
   }
   if ('useLabelComponent' in inputState) {
      if (useLabelComponent!.length === 0) {
         errors.useLabelComponent = 'Please set label component';
      }
   }
   if ('font' in inputState) {
      if (font!.length === 0) {
         errors.font = 'Please set font url';
      }
   }

   return errors;
};
