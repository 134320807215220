import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetAllTenants } from '../../../store/balance/BalanceActions';
import 'react-datepicker/dist/react-datepicker.css';
import BalanceChart from './BalanceChart';
import BalanceUsers from './BalanceUsers';

const Balance = () => {
   const dispatch = useDispatch();

   const getDefaultDateRange = () => {
      const today = new Date();

      const firstDay = new Date(today.getFullYear(), today.getMonth() - 2);
      const lastDay = new Date(today.getFullYear(), today.getMonth(), 1);

      return [firstDay, lastDay];
   };

   const [state, setState] = useState<any>({
      realm: [],
      startTime: getDefaultDateRange()[0],
      endTime: getDefaultDateRange()[1],
      username: '',
      companyName: '',
      reference: '',
   });

   const [exportData, setExportData] = useState([]);

   useEffect(() => {
      dispatch(GetAllTenants());
   }, []);

   return (
      <>
         <BalanceChart
            state={state}
            setState={setState}
            exportData={exportData}
            setExportData={setExportData}
         />
         <BalanceUsers
            state={state}
            setState={setState}
            exportData={exportData}
            setExportData={setExportData}
         />
      </>
   );
};

export default Balance;
