import React from 'react';
import { useSelector } from 'react-redux';
import ErrorImg from '../assets/images/error-img.png';
import Keycloak from '../auth/KeycloakService';
import Button from '../components/shared/button/Button';
import ButtonLink from '../components/shared/button/buttonLink/ButtonLink';
import { Routes } from '../constants/routes/routes';
import { RootStore } from '../store/rootStore';

const ErrorPage = () => {
   // App state
   const userTypes = useSelector((state: RootStore) => state.auth.user?.userTypes);
   const actions = useSelector((state: RootStore) => state.auth.user?.actions);
   return (
      <div className="error-state-container">
         <div className="error-state-image">
            <img alt={'Error image'} src={ErrorImg} />
         </div>
         <div className="error-state-content">
            <h2>An error occured while reading services from server..</h2>
            <h4>
               Refresh the page after a few minutes and try again. If error persists, contact
               the administrator.
            </h4>
            <div className="error-state-buttons-wrap">
               {userTypes?.length !== 0 || actions?.length !== 0 ? (
                  <ButtonLink text={'Back to home'} to={Routes.TRANSACTIONS_ROUTE} />
               ) : (
                  <Button
                     type={'blue'}
                     text={'Try again'}
                     onClick={() => Keycloak.logoutKeycloak()}
                  />
               )}
            </div>
         </div>
      </div>
   );
};

export default ErrorPage;
