import { OptionType } from '../../constants/types/inputs/multiselect/types';

export enum ServicesActionTypes {
   GET_SERVICES_START = '@@services/GET_SERVICES_START',
   GET_SERVICES_SUCCESS = '@@services/GET_SERVICES_SUCCESS',
   GET_SERVICES_ERROR = '@@services/GET_SERVICES_ERROR',

   GET_SERVICE_PACKAGES_START = '@@services/GET_SERVICE_PACKAGES_START',
   GET_SERVICE_PACKAGES_SUCCESS = '@@services/GET_SERVICE_PACKAGES_SUCCESS',
   GET_SERVICE_PACKAGES_ERROR = '@@services/GET_SERVICE_PACKAGES_ERROR',

   GET_EXCHANGE_RATE_START = '@@services/GET_EXCHANGE_RATE_START',
   GET_EXCHANGE_RATE_SUCCESS = '@@services/GET_EXCHANGE_RATE_SUCCESS',
   GET_EXCHANGE_RATE_ERROR = '@@services/GET_EXCHANGE_RATE_ERROR',

   CHANGE_EXCHANGE_RATE_START = '@@services/CHANGE_EXCHANGE_RATE_START',
   CHANGE_EXCHANGE_RATE_SUCCESS = '@@services/CHANGE_EXCHANGE_RATE_SUCCESS',
   CHANGE_EXCHANGE_RATE_ERROR = '@@services/CHANGE_EXCHANGE_RATE_ERROR',

   SERVICES_PAGINATION_UPDATE = '@@services/SERVICES_PAGINATION_UPDATE',
   SERVICES_PAGINATION_TOTAL_COUNT = '@@services/SERVICES_PAGINATION_TOTAL_COUNT',

   GET_SERVICE_COUNTRIES_START = '@@services/GET_SERVICE_COUNTRIES_START',
   GET_SERVICE_COUNTRIES_SUCCESS = '@@services/GET_SERVICE_COUNTRIES_SUCCESS',
   GET_SERVICE_COUNTRIES_ERROR = '@@services/GET_SERVICE_COUNTRIES_ERROR',

   GET_OPERATORS_START = '@@services/GET_OPERATORS_START',
   GET_OPERATORS_SUCCESS = '@@services/GET_OPERATORS_SUCCESS',
   GET_OPERATORS_ERROR = '@@services/GET_OPERATORS_ERROR',

   ADD_PACKAGE_START = '@@services/ADD_PACKAGE_START',
   ADD_PACKAGE_SUCCESS = '@@services/ADD_PACKAGE_SUCCESS',
   ADD_PACKAGE_ERROR = '@@services/ADD_PACKAGE_ERROR',

   EDIT_PACKAGE_START = '@@services/EDIT_PACKAGE_START',
   EDIT_PACKAGE_SUCCESS = '@@services/EDIT_PACKAGE_SUCCESS',
   EDIT_PACKAGE_ERROR = '@@services/EDIT_PACKAGE_ERROR',

   DELETE_PACKAGE_START = '@@services/DELETE_PACKAGE_START',
   DELETE_PACKAGE_SUCCESS = '@@services/DELETE_PACKAGE_SUCCESS',
   DELETE_PACKAGE_ERROR = '@@services/DELETE_PACKAGE_ERROR',
}

type GetServicesStart = {
   type: ServicesActionTypes.GET_SERVICES_START;
};

type GetServicesSuccess = {
   type: ServicesActionTypes.GET_SERVICES_SUCCESS;
   payload: ServiceType[];
};

type GetServicesError = {
   type: ServicesActionTypes.GET_SERVICES_ERROR;
};

type GetServicePackagesStart = {
   type: ServicesActionTypes.GET_SERVICE_PACKAGES_START;
};

type GetServicePackagesSuccess = {
   type: ServicesActionTypes.GET_SERVICE_PACKAGES_SUCCESS;
   payload: ServicePackageType[];
};

type GetServicePackagesError = {
   type: ServicesActionTypes.GET_SERVICE_PACKAGES_ERROR;
};

type ServicesPaginationUpdate = {
   type: ServicesActionTypes.SERVICES_PAGINATION_UPDATE;
   payload: PaginationType;
};

type ServicesPaginationTotalCount = {
   type: ServicesActionTypes.SERVICES_PAGINATION_TOTAL_COUNT;
   payload: number;
};

type GetExchangeRateStart = {
   type: ServicesActionTypes.GET_EXCHANGE_RATE_START;
};

type GetExchangeRateSuccess = {
   type: ServicesActionTypes.GET_EXCHANGE_RATE_SUCCESS;
   payload: any;
};

type GetExchangeRateError = {
   type: ServicesActionTypes.GET_EXCHANGE_RATE_ERROR;
};

type ChangeExchangeRateStart = {
   type: ServicesActionTypes.CHANGE_EXCHANGE_RATE_START;
};

type ChangeExchangeRateSuccess = {
   type: ServicesActionTypes.CHANGE_EXCHANGE_RATE_SUCCESS;
};

type ChangeExchangeRateError = {
   type: ServicesActionTypes.CHANGE_EXCHANGE_RATE_ERROR;
};

type GetServiceCountriesStart = {
   type: ServicesActionTypes.GET_SERVICE_COUNTRIES_START;
};

type GetServiceCountriesError = {
   type: ServicesActionTypes.GET_SERVICE_COUNTRIES_ERROR;
};

type GetServiceCountriesSuccess = {
   type: ServicesActionTypes.GET_SERVICE_COUNTRIES_SUCCESS;
   payload: ServiceCountryResponse[];
};

type GetOperatorsStart = {
   type: ServicesActionTypes.GET_OPERATORS_START;
};

type GetOperatorsError = {
   type: ServicesActionTypes.GET_OPERATORS_ERROR;
};

type GetOperatorsSuccess = {
   type: ServicesActionTypes.GET_OPERATORS_SUCCESS;
   payload: OperatorsResponse[];
};

type AddServicePackageStart = {
   type: ServicesActionTypes.ADD_PACKAGE_START;
};

type AddServicePackageError = {
   type: ServicesActionTypes.ADD_PACKAGE_ERROR;
};

type AddServicePackageSuccess = {
   type: ServicesActionTypes.ADD_PACKAGE_SUCCESS;
};

type EditServicePackageStart = {
   type: ServicesActionTypes.EDIT_PACKAGE_START;
};

type EditServicePackageError = {
   type: ServicesActionTypes.EDIT_PACKAGE_ERROR;
};

type EditServicePackageSuccess = {
   type: ServicesActionTypes.EDIT_PACKAGE_SUCCESS;
};

type DeleteServicePackageStart = {
   type: ServicesActionTypes.DELETE_PACKAGE_START;
};

type DeleteServicePackageError = {
   type: ServicesActionTypes.DELETE_PACKAGE_ERROR;
};

type DeleteServicePackageSuccess = {
   type: ServicesActionTypes.DELETE_PACKAGE_SUCCESS;
};

export type ServicesDispatchTypes =
   | GetServicesStart
   | GetServicesSuccess
   | GetServicesError
   | GetServicePackagesStart
   | GetServicePackagesSuccess
   | GetServicePackagesError
   | ServicesPaginationUpdate
   | ServicesPaginationTotalCount
   | GetExchangeRateStart
   | GetExchangeRateSuccess
   | GetExchangeRateError
   | ChangeExchangeRateStart
   | ChangeExchangeRateSuccess
   | ChangeExchangeRateError
   | GetServiceCountriesStart
   | GetServiceCountriesError
   | GetServiceCountriesSuccess
   | GetOperatorsStart
   | GetOperatorsError
   | GetOperatorsSuccess
   | AddServicePackageStart
   | AddServicePackageError
   | AddServicePackageSuccess
   | EditServicePackageStart
   | EditServicePackageError
   | EditServicePackageSuccess
   | DeleteServicePackageStart
   | DeleteServicePackageSuccess
   | DeleteServicePackageError;

export interface ServicesState {
   services: {
      data: ServiceType[];
      pagination: ServicePagination;
      isLoading: boolean;
   };
   packages: {
      data: ServicePackageType[];
      isLoading: boolean;
   };
   exchangeRate: {
      data: ExchangeRate | null;
      isLoading: boolean;
   };
   operators: {
      isLoading: boolean;
      data: OptionType[];
   };
   serviceCountries: {
      isLoading: boolean;
      data: OptionType[];
   };
}

export interface ExchangeRate {
   currency: string;
   storefrontCode: string;
   rate: number;
}

export interface OperatorsResponse {
   code: string;
   name: string;
   operatorType: string;
   country: ServiceCountryResponse;
}
export interface ServicePackageType {
   id: number;
   operatorCode: string;
   operatorName: string;
   countryCode: string;
   countryName: string;
   unit: string;
   amount: number;
   price: number;
   currencyCode: string;
   paymentMethod: string;
   merchantReference: string;
   reference: string;
   oldPrice: number;
   interval: string;
   startTime: number;
   serviceKey: string;
}

export interface Operator {
   label: string;
   value: string;
   type: string;
}

export interface ServiceCountryResponse {
   name: string;
   code: string;
   languageCode: string | null;
}
export interface ServiceType {
   name: string;
   active: boolean;
   contentType: string;
   dateCreated: string;
   merchantId: number;
   merchant: string;
   ownedBy: string;
   serviceKey: string;
   type: string;
}

export interface ServicePagination {
   first: number;
   max: number;
   totalCount: number;
}

export interface PaginationType {
   first: number;
   max: number;
}
