import { UserRoleActions } from '../userActions/userRoleActions';
import Subscriptions from '../../components/pages/subscriptions/Subscriptions';
import Transactions from '../../components/pages/transactions/Transactions';
import Services from '../../components/pages/services/Services';
import ServiceDetails from '../../components/pages/services/ServiceDetails';
import Balance from '../../components/pages/balance/Balance';
import Revenue from '../../components/pages/revenue/Revenue';
import Subscribers from '../../components/pages/subscribers/Subscribers';
import Analytics from '../../components/pages/analytics/Analytics';
import Users from '../../components/pages/users/Users';
import UserDetails from '../../components/pages/users/UserDetails';

export enum Routes {
   HOME_ROUTE = '/',
   TRANSACTIONS_ROUTE = '/transactions',
   SUBSCRIPTIONS_ROUTE = '/subscriptions',
   SERVICES_ROUTE = '/services',
   SERVICE_DETAILS_ROUTE = '/service-details',
   BALANCE_ROUTE = '/balance',
   REVENUE_ROUTE = '/revenue',
   SUBSCRIBERS_ROUTE = '/subscribers',
   USERS_ROUTE = '/users',
   USERS_DETAILS_ROUTE = '/user-details',
   ANALYTICS = '/analytics',
   FORBIDDEN_ROUTE = '/forbidden',
   ERROR_ROUTE = '/error',
}

export const ALL_ROUTES = ['https://partner-dashboard.centili.com', 'http://localhost:8283'];

export const PAGE_NAVIGATION_ROUTES = [
   {
      component: Transactions,
      path: Routes.TRANSACTIONS_ROUTE,
      role: UserRoleActions.TRANSACTIONS_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Subscriptions,
      path: Routes.SUBSCRIPTIONS_ROUTE,
      role: UserRoleActions.SUBSCRIPTIONS_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Services,
      path: Routes.SERVICES_ROUTE,
      role: UserRoleActions.SERVICES_VIEW_ACTION,
      restricted: true,
   },
   {
      component: ServiceDetails,
      path: Routes.SERVICE_DETAILS_ROUTE,
      role: UserRoleActions.SERVICES_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Balance,
      path: Routes.BALANCE_ROUTE,
      role: UserRoleActions.BALANCE_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Revenue,
      path: Routes.REVENUE_ROUTE,
      role: UserRoleActions.REVENUE_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Analytics,
      path: Routes.ANALYTICS,
      role: UserRoleActions.SODEXO_ANALYTICS_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Subscribers,
      path: Routes.SUBSCRIBERS_ROUTE,
      role: UserRoleActions.SUBSCRIBERS_VIEW_ACTION,
      restricted: true,
   },
   {
      component: Users,
      path: Routes.USERS_ROUTE,
      role: UserRoleActions.USERS_VIEW_ACTION,
      restricted: true,
   },
   {
      component: UserDetails,
      path: Routes.USERS_DETAILS_ROUTE,
      role: UserRoleActions.USERS_VIEW_ACTION,
      restricted: true,
   },
];
